import React, { useEffect, createContext, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  auth,
  db,
  db2,
  firepadRef,
  registerUserFb,
} from "./firebase/firebase.utils";
import { AnimatePresence } from "framer-motion";
import { setCurrentUser, signOut, setUsers } from "./redux/user/user.actions";
import { setLoading } from "./redux/history/history.actions";
import { logOut } from "./redux/org/org.actions";
import "survey-creator/survey-creator.css";
import Org from "./pages/org/org.component.class";
import Home from "./pages/home/home.component.class";
import DockJob from "./pages/dock-job/dock-job.component";
import Job from "./pages/job/job.component";
import SigninPage from "./pages/sing-in/sign-in.component.class";
import ProtectedRoute from "./components/protectedRoute/protectedRoute.component";
import EnterJob from "./pages/enter-job/enter-job.component";
import LoadingPage from "./components/retro/loading-page/loading-page.component";
import MobileMenu from "./components/mobile-menu/mobile-menu.component";
import "./app.styles.scss";
import ArchivedJobs from "./pages/archived-jobs/archived-jobs";
import Message from "./pages/message/message.componet.class";
import Videocall from "./pages/videocall/videocall.componet.class";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import { Box, CircularProgress, Container } from "@mui/material";
import Surveys from "./pages/survery-list/survery-list.component.class";
import ScrollToTop from "./utils/ScrollToTop";
import Videochat from "./pages/videochat/videochat.component";
function App() {
  const user = useSelector((state) => state.user);
  const isLoading = useSelector((state) => state.history.isLoading);
  const dispatch = useDispatch();
  const history = useHistory();
  const signInUrl = history.location.pathname.split("/")[1];
  const { currentUser } = user;
  const theme = useTheme();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      console.log("auth change App", user);
      if (user) {
        const { uid } = user;
        const userRef = await db.collection("users").doc(uid).get();
        const userData = userRef.data();
        if (userData) {
          console.log("got user in db, only dispatch");
          dispatch(setCurrentUser(userData));
          if (signInUrl === "signin") {
            history.push("/");
          }
        }
        if (!userData) {
          console.log("no user in db");
          if (user.displayName === null) {
            return;
          } else {
            console.log("no user, creating");
            await registerUserFb(user, user.displayName);
            const newUserRef = await db.collection("users").doc(uid).get();
            const newUserData = newUserRef.data();
            dispatch(setCurrentUser(newUserData));
            if (signInUrl === "signin") {
              history.push("/");
            }
          }
        }
        dispatch(setLoading(false));
      } else {
        console.log("no user");
        history.push("/signin");
        dispatch(signOut());
        dispatch(logOut());
        dispatch(setLoading(false));
      }
    });
  }, []);

  useEffect(() => {
    db.collection("users").onSnapshot((usersData) => {
      console.log("db users changed, dispatching setUsers");
      let users = [];
      usersData.forEach((userData) => {
        users.push(userData.data());
      });
      dispatch(setUsers(users));
    });
  }, [dispatch]);

  if (isLoading)
    return (
      <Box
        style={{
          display: "flex",
          height: "100vh",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <ScrollToTop>
      {currentUser && (
        <>
          <ThemeProvider theme={theme}>
            <AnimatePresence>{isLoading && <LoadingPage />}</AnimatePresence>
            <Org>
              <MobileMenu />
              <ProtectedRoute
                exact
                path="/"
                component={Home}
                isAuth={currentUser}
              />
              <ProtectedRoute
                exact
                path="/s/:id"
                component={DockJob}
                isAuth={currentUser}
              />
              <ProtectedRoute
                exact
                path="/s/:id/videochat/public/:id"
                component={Videochat}
                isAuth={currentUser}
              />
              <ProtectedRoute
                exact
                path="/s/:id/videochat/private/:id"
                component={Videochat}
                isAuth={currentUser}
              />
              <ProtectedRoute
                path="/s/:id/e/:id"
                component={EnterJob}
                isAuth={currentUser}
              />
              <ProtectedRoute
                path="/s/:id/archived-job"
                component={ArchivedJobs}
                isAuth={currentUser}
              />
              {/* <ProtectedRoute
                exact
                path="/s/:id/videochat/private/:id"
                component={Message}
                isAuth={currentUser}
              /> */}
              <ProtectedRoute
                exact
                path="/s/:id/videocall"
                component={Videocall}
                isAuth={currentUser}
              />
              <ProtectedRoute
                path="/surveys"
                component={Surveys}
                isAuth={currentUser}
              />
            </Org>
          </ThemeProvider>
        </>
      )}
      {!currentUser && <Route path="/signin" component={SigninPage} />}
    </ScrollToTop>
  );
}

export default App;

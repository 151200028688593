import { useSelector } from "react-redux";
import { db } from "../firebase/firebase.utils";

const useCurrentSubAdmins = () => {
  const orgData = useSelector((state) => state.org.orgData);
  const activeOrgId = useSelector((state) => state.history.orgId);
  if (!orgData || !orgData[0]?.members) return;
  const data = orgData.filter((data) => data.orgId === activeOrgId);

  const subAdmins = data[0]?.subAdmins || [];

  return subAdmins;
};

export default useCurrentSubAdmins;

import React, {useEffect, useState} from "react";
import {useActiveOrgData} from "../../hooks/useActiveOrgData.hook";
import * as SurveyJSCreator from "survey-creator";
import DockHeader from "../../components/dock-header/dock-header.component";
import RecentJobs from "../../components/recent-jobs/recent-jobs.component";
import Members from "../../components/members/members.component";
import "./dock-job.styles.scss";
import SurveysList from "../../components/surveys-list/surveys-list";
import {auth, db,db2,firepadRef, setOpenFb} from "../../firebase/firebase.utils";
import {useDispatch, useSelector} from "react-redux";
import useCurrentSubAdmins from "../../hooks/useCurrentSubAdmins";
import TrainingsList from "../../components/trainings-list/trainings-list";
import orgActionTypes from "../../redux/org/org.types";
import Loading from "../../components/retro/loading/loading.component";
import {useHistory} from "react-router";
import {Box, Chip, Grid, Typography,Divider,Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Job from '../job/job.component'
import {Link} from 'react-router-dom';
import {setOpen} from "../../redux/user/user.actions";
import {setActiveModules, setJobData, setModules} from "../../redux/org/org.actions";
import {setJobId, setOrgId} from "../../redux/history/history.actions";
import CreateJob from "../../components/create-job/create-job.component";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import MainScreen from "../../components/MainScreen/MainScreen.component";
import {
  setMainStream,
  addParticipant,
  setUser,
  updateUser,
  removeParticipant,
  updateParticipant,
} from "./../../redux/store/actioncreator";
import Message from "../message/message.componet.class";
SurveyJSCreator.StylesManager.applyTheme("modern");

const DockJob = () => {
 
  const streams = useSelector((state) => state.store.mainStream);
  const user = useSelector((state) => state.store.currentUser);  
  const activeOrgData = useActiveOrgData();
  const currentUserUid = useSelector((state) => state.user.currentUser.uid);
  const currentUser = useSelector((state) => state.user.currentUser);
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const history = useHistory();
  const orgData = useSelector((state) => state.org.orgData);
  const currentOrgSubAdmins = useCurrentSubAdmins();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch()
  const [createJob, setCreateJob] = useState(false);
  const activeOrgId = history.location.pathname.split("/")[2];
  // const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();

 
  // function handleWindowSizeChange() {
  //   setWidth(window.innerWidth);
  // }

  // useEffect(() => {
  //   window.addEventListener('resize', handleWindowSizeChange);
  //   return () => {
  //     window.removeEventListener('resize', handleWindowSizeChange);
  //   }
  // }, []);

  // const isMobile = width <= 700;

  let isAdmin =
    orgData.find((s) => s.orgId === orgId)?.admin ===
    auth.currentUser.uid ||
    (currentOrgSubAdmins &&
      currentOrgSubAdmins.includes(auth.currentUser.uid));

  useEffect(() => {
    dispatch({
      type: orgActionTypes.SET_STATE,
      payload: {
        currentOrganization: orgData.find(item => item.orgId === orgId) || {}
      }
    })
  }, [orgData, orgId]);

  useEffect(() => {
    if (orgData && orgData.disabled) {
      history.push("/")
    }
  }, [orgData])

  useEffect(() => {
    if (activeOrgData && activeOrgData.disabled) {
      history.push("/")
    }
  }, [activeOrgData])


  useEffect(() => {
    if (!orgId) return;

    async function fetchData() {
      const docRef = await db.collection("org").doc(orgId).get();
      const orgData = docRef.data();
      if (!orgData) return;
      if (!orgData.members.includes(currentUserUid)) {
        alert("u have no acces");
        history.push("/");
      }
    }

    fetchData();
  }, [orgId, jobId]);

  useEffect(() => {
    db.collection("org")
      .doc(activeOrgId)
      .collection("jobs")
      /* .orderBy("created", "asc") */
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        dispatch(setJobData(docs));
      });
    if (activeOrgId) {
      dispatch(setOrgId(activeOrgId));
    }
  }, [activeOrgId, dispatch]);


 
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{pb: 0 ,color:theme.palette.text.primary,pt:7, pl:3, backgroundColor: theme.palette.background.light}} >
      <Grid container>
          <Grid item xs={12} md={12} sx={{ color: theme.palette.text.primary }}>
          <DockHeader setLoading={setLoading} activeOrgData={activeOrgData}/>
        </Grid>
      </Grid>
      <Grid spacing={4} container sx={{mb: 5}}>
        <Grid item xs={12} md={2}>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography
              component="span"
              sx={{
                fontSize: '1.5rem',
                // marginLeft: isMobile ? 1 : 3,
                // mr: isMobile ? 1 : 5
              }} 
              style={{
                marginLeft: 0
              }}
              >Members</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Members showActions={false} setLoading={setLoading} activeOrgData={activeOrgData}/>
          </Box>
        </Grid>
        </Grid>
        <Divider sx={{ mb: 6 }} />
       
        
        
        {/* <Grid item xs={12} md={12}  sx={{ display: 'flex',justifyContent: 'space-between',flexDirection: 'row', backgroundColor: theme.palette.background.light}}>
        
          <Grid container xs={2} md={2} spacing={2} p={3} sx={{backgroundColor: theme.palette.background.light, height: "70vh"}} >
            
            
            <Box sx={{ color: theme.palette.text.primary, display:"flex",justifyContent:"space-between",width:"100%"}}>
              
            <Typography variant="h5" sx={{color: theme.palette.text.primary}}>Jobs</Typography>
              <Box>
              <Chip
                sx={{marginRight: 1, borderColor: theme.palette.text.primary, color:theme.palette.text.primary, backgroundColor: theme.palette.action.active}}
                label="Add Job"
                color="primary"
                variant="contained"
                icon={<AddIcon sx={{fontSize: 15, color: 'black'}}/>}
                  onClick={() => setCreateJob(!createJob)}
                  size="medium"
              />
              {createJob && (
                <CreateJob
                  setCreateJob={setCreateJob}
                  activeOrgId={activeOrgId}
                />
                )}
                {activeOrgData &&
                (activeOrgData?.admin === auth.currentUser.uid ||
                  (activeOrgData?.subAdmins &&
                    activeOrgData?.subAdmins.includes(auth.currentUser.uid))) && (
                  <Chip
                    // component={Link}
                    // to={`/s/${orgId}/archived-job`}
                    onClick={() => history.push(`/s/${orgId}/archived-job`)}
                    sx={{cursor: 'pointer', color:theme.palette.text.primary, borderColor: theme.palette.text.primary,backgroundColor: theme.palette.action.active}}
                    label="Archived Job"
                    color="primary"
                    variant="contained"
                  />
                )}
              </Box>
            </Box>
            <RecentJobs activeOrgData={activeOrgData} />
            
            <Box sx={{backgroundColor: theme.palette.background.light,width:"100%"}} >
              
            <SurveysList
            isAdmin={isAdmin}
            activeOrgData={activeOrgData}
            />
            </Box>
            <Divider sx={{ mb: 3 }} />
            <Box sx={{backgroundColor: theme.palette.background.light,width:"100%"}} >
            <TrainingsList
              isAdmin={isAdmin}
              activeOrgData={activeOrgData}
              />
              </Box>
            </Grid>
          <Grid container xs={10} md={10} sx={{ backgroundColor: theme.palette.background.white,display:"flex",justifyContent:"center",flexDirection:"column"}} >
          
            <Link to={`/s/${activeOrgId}/videochat/public/${roomId}`}>
              <Button sx={{ backgroundColor: theme.palette.action.active,color:theme.palette.text.main}}>video and chat</Button>
              
              </Link>
            
            test
          </Grid>

          </Grid> */}
        <Grid item xs={12} md={12} ml={1} mr={1} sx={{ display: 'flex',justifyContent: 'space-between',flexDirection: 'row', backgroundColor: theme.palette.background.active}}>
          {/* <Grid container xs={2} md={2} sx={{backgroundColor: theme.palette.background.white,height:"65vh"}} >
            
            <RecentJobs activeOrgData={activeOrgData}/>
            
          </Grid> */}
          <Grid container xs={12} md={12} columnSpacing={{ xs: 5}} sx={{ justifyContent: 'center',backgroundColor: theme.palette.background.light }}>
          
          <Grid item xs={12} md={6} sx={{ overflowY: 'scroll',  backgroundColor: theme.palette.background.light}}>
          <Divider sx={{mb: 3}}/>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            
            <Typography variant="h5" sx={{ marginBottom: 0, color: theme.palette.text.primary}}>Jobs</Typography>
                <Box sx={{ color: theme.palette.text.primary }}>
              <Chip
                sx={{ml: 1, mt: 1, marginRight: 1, mb:1, borderColor: theme.palette.text.primary, color:theme.palette.text.primary, backgroundColor: theme.palette.action.active}}
                label="Add Job"
                color="primary"
                variant="contained"
                icon={<AddIcon sx={{fontSize: 15, color: 'black'}}/>}
                onClick={() => setCreateJob(!createJob)}
              />
              {createJob && (
                <CreateJob
                  setCreateJob={setCreateJob}
                  activeOrgId={activeOrgId}
                />
              )}
              {activeOrgData &&
                (activeOrgData?.admin === auth.currentUser.uid ||
                  (activeOrgData?.subAdmins &&
                    activeOrgData?.subAdmins.includes(auth.currentUser.uid))) && (
                  <Chip
                    // component={Link}
                    // to={`/s/${orgId}/archived-job`}
                    onClick={() => history.push(`/s/${orgId}/archived-job`)}
                    sx={{ml: 1, mt: 1, mb:1, cursor: 'pointer', color:theme.palette.text.primary, borderColor: theme.palette.text.primary,backgroundColor: theme.palette.action.active}}
                    label="Archived Job"
                    color="primary"
                    variant="contained"
                  />
                )}
            </Box>
          </Box>
          <RecentJobs activeOrgData={activeOrgData}/>
        </Grid >
          <Grid item xs={12} md={6} sx={{backgroundColor: theme.palette.background.light}}>
          <Divider sx={{mb: 3}}/>
            <Box sx={{ mb: 5,backgroundColor: theme.palette.background.light}} >
              
              <SurveysList
              isAdmin={isAdmin}
              activeOrgData={activeOrgData}
            />
            </Box>
            <Divider sx={{mb: 3}}/>
          <TrainingsList
            isAdmin={isAdmin}
            activeOrgData={activeOrgData}
          />
            </Grid>
            {/* <Grid container xs={10} md={10} sx={{display:"flex",justifyContent:"center",flexDirection:"column"}} >
          
            <Link to={`/s/${activeOrgId}/videochat/public/${roomId}`}>
              <Button sx={{ backgroundColor: theme.palette.action.active,color:theme.palette.text.main}}>video and chat</Button>
              
              </Link>
            
            
            </Grid> */}
          </Grid>
        </Grid>
      
        
      
      {loading && <Loading/>}
      </Box>
      </ThemeProvider>
  );
};



export default DockJob;


import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  auth,
  db,
  updateDrag,
  updateUser,
} from "../../firebase/firebase.utils";
import { setStatusType } from "../../redux/org/org.actions";
import StatusTypeBoard from "../../components/dnd/board/status-type-board/status-type-board.component";
import BoardNewStatus from "../../components/board-new-status/board-new-status.component";
import "./archived-jobs.styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Priority from "../../components/modules/priority/priority.component";
import CreatedBy from "../../components/modules/created-by/created-by.component";
import CreatedDate from "../../components/modules/created-date/created-date.component";
import StartDate from "../../components/modules/startdate/startdate.component";
import Deadline from "../../components/modules/deadline/deadline.component";
import DaysLeft from "../../components/modules/days-left/days-left.component";
import Assign from "../../components/modules/assign/assign-component";
import Status from "../../components/modules/status/status.component";
import { message, Popconfirm } from "antd";
import Subtasks from "../../components/dnd/subtasks/subtasks.component";
import BoxRight from "../../components/retro/box-right/box-right.component";
import LargeTask from "../../components/large-task/large-task.component";
import LargeTaskArchived from "../../components/large-task/large-task-archived";
import { useActiveOrgData } from "../../hooks/useActiveOrgData.hook";
import { Dialog } from "@mui/material";

const ArchivedJobs = ({ job }) => {
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  const history = useHistory();
  const currentOrgId = history.location.pathname.split("/")[2];
  const [currentTask, setCurrentTask] = useState(null);
  const [deleteOpacity, setDeleteOpacity] = useState(0);
  const [tasks, setTasks] = useState([]);
  const activeOrgData = useActiveOrgData();

  useEffect(() => {
    getTasks();
  }, [job]);

  useEffect(() => {
    if (
      activeOrgData &&
      !(
        activeOrgData?.admin === auth.currentUser.uid ||
        (activeOrgData?.subAdmins &&
          activeOrgData?.subAdmins.includes(auth.currentUser.uid))
      )
    ) {
      history.push("/");
    }
  }, [activeOrgData]);

  const onDeleteArchiveTask = (taskId) => {
    db.collection("org")
      .doc(currentOrgId)
      .collection("archived-job")
      .doc(taskId)
      .delete()
      .then(() => {
        getTasks();
        message.success("Task Permanently Deleted!");
      })
      .catch((err) => console.log(err));
  };

  const getTasks = () =>
    db
      .collection("org")
      .doc(currentOrgId)
      .collection("archived-job")
      .get()
      .then((res) => setTasks(res.docs || []));

  const renderTask = (task, taskId) => (
    <div onClick={() => setCurrentTask({ ...task, id: taskId })}>
      <div
        onClick={() => setCurrentTask({ ...task, id: taskId })}
        className="task__logic"
      >
        <div className={`task`}>
          <div className="task__header">
            <div className="task__taskName">
              <p>{task.content}</p>
              <div onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title="Are you sure to delete this survey?"
                  onConfirm={() => onDeleteArchiveTask(taskId)}
                  okText="Yes"
                  cancelText="No"
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Popconfirm>
              </div>
            </div>
          </div>
          <Subtasks task={task} />
          <div style={{ pointerEvents: "none" }} className="task__modules">
            <Priority task={task} />
            <CreatedBy task={task} />
            <Status task={task} />
            <CreatedDate task={task} />
            <StartDate task={task} />
            <Deadline task={task} />
            <DaysLeft task={task} />
            <Assign task={task} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="archivedJob">
      <div className="archivedMenu ">
        <h2 style={{ marginLeft: -20 }}>Archived Job</h2>
      </div>
      <div className="task__logic__container">
        {tasks?.map((task) => renderTask(task.data(), task.id))}
      </div>
      {currentTask && <Dialog onClose={() => setCurrentTask(null)} open={currentTask}>
        <LargeTaskArchived onClose={() => setCurrentTask(null)} task={currentTask} />
      </Dialog> }
    </div>
  );
};

export default ArchivedJobs;

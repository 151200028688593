import React, { useState } from "react";
import { createNewOrg } from "../../firebase/firebase.utils";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import BoxLayerLite from "../retro/box-layer-lite/box-layer-lite.component";
import RetroInput from "../retro/input/input.component";
import RetroButton from "../retro/button/retro-button.component";
import Colors from "../colors/colors.component";
import { Dialog, Grid, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import "ui-neumorphism/dist/index.css";
import "./create-org.styles.scss";
import { useTheme, ThemeProvider} from "@mui/material/styles";
import { Box } from "@mui/system";
function CreateOrg({setLayer}) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [orgName, setOrgName] = useState("");
  const [currentColor, setCurrentColor] = useState("#8f00ff");
  const [showColors, setShowColors] = useState(false);
  const theme = useTheme();
  const handleNewOrg = () => {
    createNewOrg(orgName, currentUser, currentColor, setLayer);
  };
  return (
    <ThemeProvider theme={theme}>
    <Dialog onClose={() => setLayer(false)} open={true}>
        <div className="createOrg" >
          <div className="dashboard3" style={{ color: theme.palette.action.hover, backgroundColor: theme.palette.background.light}}>
            <h2 style={{ color: theme.palette.text.primary }}>Create org</h2>
          <div className="cs__icon">
            <p style={{ color: theme.palette.text.primary }}>Preview</p>
            <RetroButton
              style={{ background: currentColor }}
              onClick={() => setShowColors(!showColors)}
            >
              {orgName.charAt(0)}
            </RetroButton>
            <br />
            <p onClick={() => setShowColors(!showColors)} style={{ color: theme.palette.text.primary }}>Set color</p>
            {showColors && (
              <BoxLayerLite setLayer={setShowColors}>
                <div className="cs__chooseColor">
                  <Colors returnColor={setCurrentColor} />
                </div>
              </BoxLayerLite>
            )}
          </div>
          <div onChange={(e) => setOrgName(e.target.value)}>
            <TextField
              sx={{ mt: 1, color:"red"}}
              fullWidth
              label="Org Name (private, work, ect.. )"
              className="my-3"
            ></TextField>
          </div>

          <div className="cs__createBtn">
            <Button
              mode="gray"
              sx={{ color: theme.palette.text.primary}}
              onClick={() => setLayer(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              sx={{ color: "white" }}
              onClick={() => handleNewOrg()}
              style={{marginLeft:5}}
            >
              <Typography sx={{ color: "white" }}>Create</Typography>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
    </ThemeProvider>
  );
}

export default CreateOrg;

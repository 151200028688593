import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";
// import "./recent-jobs.styles.scss";
import { setJobData } from "../../redux/org/org.actions";
import { setOrgId, setJobId } from "../../redux/history/history.actions";
import { db } from "../../firebase/firebase.utils";
import JobItem from "../job-item/job-item.component.class";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import {Typography, Card} from "@mui/material";
function RecentJobs({activeOrgData}) {
  const history = useHistory();
  const orgId = useSelector((state) => state.history.orgId);
  const jobData = useSelector((state) => state.org.jobData);
  const [recentJob, setRecentJob] = useState(null);
  const dispatch = useDispatch();
  const activeOrgId = history.location?.pathname.split("/")[2];
  const theme= useTheme();
  useEffect(() => {
    db.collection("org")
      .doc(activeOrgId)
      .collection("jobs")
      /* .orderBy("created", "asc") */
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        dispatch(setJobData(docs));
      });
    if (activeOrgId) {
      dispatch(setOrgId(activeOrgId));
    }
  }, [activeOrgId, dispatch]);

  useEffect(() => {
    if (jobData) {
      setRecentJob(jobData.slice(-3));
    }
  }, [jobData]);

  return (
    <ThemeProvider theme={theme}>
    <div className="recentJobs" style={{overflowY: 'scroll', height: '370px',width:"100%"}}>
      {jobData?.length < 1 ? (
        <div className="recentJobs__noJobs">
          <div className="recentJobs__msg">
          <Typography style={{color: theme.palette.text.primary,textAlign:"center"}}>No Tasks!</Typography>
          </div>
        </div>
      ) : (
        <div>
              {jobData?.map((job) => {
            return (
              <Card className="jobitem" key={job.jobId} style={{marginBottom: 5,backgroundColor: theme.palette.action.task,color: theme.palette.text.primary}}>
                <JobItem data={job}/>
              </Card>
            );
          })}
        </div>
        // recentJob?.map((data) => {
        //   return (
        //     <div className="rs__item" key={data.jobId}>
        //       <Link to={`/s/${activeOrgData.orgId}/e/${data.jobId}/b`}>
        //         <button>
        //           {data.name}
        //         </button>
        //       </Link>
        //     </div>
        //   );
        // })
      )}
      </div>
      </ThemeProvider>
  );
}

export default RecentJobs;

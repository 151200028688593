import React, {useEffect, useState} from "react";
import RetroInput from "../../retro/input/input.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTasks} from "@fortawesome/free-solid-svg-icons";
import "./step1.styles.scss";
import Button from "@mui/material/Button";
import { useTheme} from "@mui/material/styles";
const Step1 = ({jobName, setJobName, setSteps}) => {
  const [isNameOK, setIsNameOK] = useState(false);
  const unClick = {
    PointerEvent: "none",
  };
  const theme = useTheme();
  const handleNext = () => {
    if (!isNameOK) {
      alert("Job name is req");
      return;
    }
    setSteps({
      step1: false,
      step2: true,
    });
  };

  useEffect(() => {
    if (jobName === "" || jobName === "Enter Job name") {
      setIsNameOK(false);
    } else {
      setIsNameOK(true);
    }
  }, [jobName]);

  return (
    <section className="sectionName" >
      <h2 style={{color: theme.palette.text.primary}}>Create Job</h2>
      <div className="sn__icon" >
        <FontAwesomeIcon icon={faTasks} size="3x"/>
      </div>
      <div className="sn__name">
        {/* <p>Job name</p> */}
        <div onChange={(e) => setJobName(e.target.value)} style={{marginTop: '10px',color: "black"}}>  
          <RetroInput placeholder={jobName}/>
        </div>
      </div>
      <div className="sn__next" >
        <Button
          variant="contained"
          sx={{color: theme.palette.text.main, borderColor:"white", backgroundColor: theme.palette.action.active}}
          onClick={() => {
            handleNext();
          }}
          style={isNameOK ? null : unClick}
        >
          <p style={{color: theme.palette.text.main}}>Next</p>
        </Button>
      </div>
    </section>
  );
};

export default Step1;

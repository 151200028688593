import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  db,
  createMessageToTask,
  changeTaskName,
  auth,
  changeTaskSurvey,
  saveTaskSurveyPDF,
} from "../../firebase/firebase.utils";

import "./large-task.styles.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import LoadModule from "../modules/load-module.component.jsx/load-module.component";
import RetroButton from "../retro/button/retro-button.component";
import TaskDescription from "../task-description/task-description.component";
import Avatar from "../retro/avatar/avatar.component";
import Message from "../message/message.component";
import Loading from "../retro/loading/loading.component";
import Subtasks from "../dnd/subtasks/subtasks.component";
import { setJobSurveys } from "../../redux/org/org.actions";
import { useDispatch } from "react-redux";
import DoSurvey from "./DoSurvey";
import useCurrentSubAdmins from "../../hooks/useCurrentSubAdmins";
import { Box, Button, Dialog, Grid, MenuItem, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import { useTheme} from "@mui/material/styles";
const { Option } = Select;
const LargeTask = ({ task, onClose }) => {
  const orgId = useSelector((state) => state.history.orgId);
  const moduleData = useSelector((state) => state.org.moduleData);
  const orgData = useSelector((state) => state.org.orgData);
  const surveysData = useSelector((state) => state.org.surveysData);
  console.log(surveysData);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [msgs, setMsgs] = useState([]);
  const [message, setMessage] = useState("");
  const [newTaskName, setNewTaskName] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showPDfResults, setShowPDfResults] = useState(false);
  const currentOrgSubAdmins = useCurrentSubAdmins();
  const [limit, setLimit] = useState(3);
  const inputTaskRef = useRef();
  const textareaRef = useRef();
  const history = useHistory();
  const { fromOrgId, fromJobId } = task;
  const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 700;

  const dispatch = useDispatch();
  useEffect(() => {
    if (!orgId) {
      return;
    }
    db.collection("org")
      .doc(orgId)
      .collection("surveys")
      .get()
      .then((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });
        dispatch(setJobSurveys(docs));
      });
  }, [task]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message) {
      alert("no message");
      return;
    }
    createMessageToTask(
      task.fromOrgId,
      task.fromJobId,
      message,
      currentUser.uid,
      task.id
    );
    textareaRef.current.value = "";
    setMessage("");
  };

  useEffect(() => {
    const { fromOrgId, fromJobId, id } = task;
    db.collection("org")
      .doc(fromOrgId)
      .collection("job")
      .doc(fromJobId)
      .collection("tasks")
      .doc("tasks")
      .collection("msg")
      /* .orderBy("created", "asc") */
      .where("taskId", "==", id)
      .limit(limit)
      .onSnapshot((msgsData) => {
        let list = [];
        msgsData.forEach((msg) => {
          list.push(msg.data());
        });
        setMsgs(list);
      });
  }, []);

  const handleChangeTask = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      await changeTaskName(fromOrgId, fromJobId, newTaskName, task.id);
    } catch (error) {
      console.log(error.message);
    } finally {
      setTimeout(() => {
        setShowLoading(false);
        inputTaskRef.current.blur();
      }, 500);
    }
  };

  const handleChangeTaskSurvey = async (e) => {
    try {
      setShowLoading(true);
      await changeTaskSurvey(fromOrgId, fromJobId, e.target.value, task.id);
    } catch (error) {
      console.log(error.message);
    } finally {
      setTimeout(() => {
        setShowLoading(false);
      }, 500);
    }
  };

  return (
    <div className="largeTask" style={{backgroundColor:theme.palette.background.light,color:"black"}}>
      {isMobile ? (
        <Box>
          <CloseIcon
            onClick={() =>
              onClose
                ? onClose()
                : history.replace(`/s/${fromOrgId}/e/${fromJobId}/b`)
            }
          />
        </Box>
      ) : null}
      <div className="lt__taskStuff d-flex">
        <form onSubmit={(e) => handleChangeTask(e)}>
          {showLoading && <Loading />}
          <input
            ref={inputTaskRef}
            placeholder={task.content}
            onChange={(e) => setNewTaskName(e.target.value)}
            styles={{color:theme.palette.text.main}}
          />
        </form>
        {(orgData.find((s) => s.orgId === orgId)?.admin ===
          auth.currentUser.uid ||
          currentOrgSubAdmins.includes(auth.currentUser.uid)) && (
          <select
            value={task?.surveyId || ""}
            style={{ padding: 5, paddingRight: 15 }}
            onChange={handleChangeTaskSurvey}
            label="Survey"
          >
            <option value="">Select a Survey</option>
            {surveysData.map((eachSurvey) => (
              <option value={eachSurvey.id}>{eachSurvey.title}</option>
            ))}
          </select>
        )}
      </div>
      <div className="lt__content">
        <div className="lt__modules">
          {moduleData?.map((module) => {
            return <LoadModule module={module} key={module.name} task={task} />;
          })}
          <div className="lt__description" >
            <TaskDescription description={task?.description} taskId={task.id} />
          </div>
          <div className="lt__subtasks">
            <Subtasks task={task} open opacity="opacityTrue" />
          </div>
          <br />

          <div className="lt__subtasks">
            <Grid gap={2} display="flex" flexDirection="row">
              {task?.surveyId && (
                <DoSurvey
                  saveTaskSurveyPDF={(fileName, pdfLink) =>
                    saveTaskSurveyPDF(
                      fromOrgId,
                      fromJobId,
                      fileName,
                      pdfLink,
                      task.id
                    )
                  }
                  surveyId={task?.surveyId}
                  task={task}
                  orgId={orgId}
                  onStart={onClose}
                />
              )}
              {(orgData.find((s) => s.orgId === orgId)?.admin ===
                auth.currentUser.uid ||
                currentOrgSubAdmins.includes(auth.currentUser.uid)) && (
                <>
                  <Button onClick={()=>setShowPDfResults(true)}>View Results</Button>
                  <Dialog onClose={() => setShowPDfResults(false)} open={showPDfResults}>
                    <Grid padding={3}>
                    <div className="lt__subtasks">
                      <ol className="survey-pdf-list">
                        <h3>Survey PDf List</h3>
                        {task?.surveyResults?.map((eachSurvey) => {
                          return (
                            <li>
                              <a target="_blank" href={eachSurvey?.pdfLink}>
                                {eachSurvey?.fileName}
                              </a>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                    </Grid>
                  </Dialog>
                </>
              )}
            </Grid>
          </div>
          <br />
        </div>

        <div className="lt__writeUpdate">
          <div className="lt__writeUpdate-msgs">
            {msgs.map((msg) => {
              return <Message msg={msg} />;
            })}
          </div>
          <div className="lt__writeUpdate-send">
            <div className="lt__writeUpdate-avatar">
              <Avatar src={currentUser.imageUrl} />
            </div>
            <div className="lt__writeUpdate-message">
              <form onSubmit={(e) => handleSubmit(e)}>
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  ref={textareaRef}
                  placeholder="Write update"
                />
                <Button
                  variant="contained"
                  size="small"
                  type="submit"
                  style={{ marginTop: 10 }}
                >
                  <Typography sx={{ color: "white" }}>Submit</Typography>
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LargeTask;

import React from "react";
import {useSelector, useDispatch} from "react-redux";
import TimeFilter from "./time-filter/time-filter.component";
import "./filter-styles.scss";
import {ThemeProvider, useTheme } from "@mui/material/styles";
const Filter = () => {
  const filter = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>

      <div className="filter" style={{ color: theme.palette.text.primary }}>
      <div className="filter__types">
        <div className="filter__time">
          <TimeFilter time={filter.time}/>
        </div>
      </div>
      </div>
      </ThemeProvider>
  );
};

export default Filter;

import historyActionTypes from "./history.types";

const initialState = {
  orgId: null,
  jobId: null,
  isLoading: true,
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case historyActionTypes.SET_ORG_ID:
      return {
        ...state,
        orgId: action.payload,
      };
    case historyActionTypes.SET_JOB_ID:
      return {
        ...state,
        jobId: action.payload,
      };
    case historyActionTypes.SET_IDS_NULL:
      return {
        orgId: null,
        jobId: null,
      };
    case historyActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default historyReducer;

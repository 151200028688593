import historyActionTypes from "./history.types";

export const setOrgId = (orgId) => ({
  type: historyActionTypes.SET_ORG_ID,
  payload: orgId,
});

export const setJobId = (jobId) => ({
  type: historyActionTypes.SET_JOB_ID,
  payload: jobId,
});

export const setIdsNull = () => ({
  type: historyActionTypes.SET_IDS_NULL,
});

export const setLoading = (state) => ({
  type: historyActionTypes.SET_LOADING,
  payload: state,
});

import React, {useState, useEffect} from "react";
import Button from "@mui/material/Button";
import {auth, updateUser, storage} from "../../firebase/firebase.utils";
import "./user-profile.styles.scss";
import Avatar from "../retro/avatar/avatar.component";
import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroInput from "../retro/input/input.component";
import RetroButton from "../retro/button/retro-button.component";
import Loading from "../retro/loading/loading.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {Dialog, Grid} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const UserProfile = ({ currentUser }) => {
  const theme = useTheme();
  const [showPage, setShowPage] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [newUser, setNewUser] = useState({
    userName: currentUser.userName,
    imageUrl: currentUser.imageUrl,
  });
  
  useEffect(() => {
  }, [currentUser]);

  function handleLogout() {
    auth.signOut();
  }

  const handleSave = () => {
    try {
      updateUser(currentUser.uid, newUser);
    } catch (error) {
      console.log(error.message);
    } finally {
      currentUser.userName = newUser.userName;
    }
  };

  const handleUploadImage = async (e) => {
    const file = e.target.files[0];
    const storageRef = storage.ref("profilePictures");
    const fileRef = storageRef.child(file.name);
    
    setShowLoading(true);
    await fileRef.put(file);
    const fileUrl = await fileRef.getDownloadURL();
    const newUser2 = {
      imageUrl: fileUrl,
    };
    updateUser(currentUser.uid, newUser2);
    setNewUser({...newUser, imageUrl: fileUrl});
    setShowLoading(false);
  };

  return (
    <div className="userProfile" >
      <div className="userProfile__icon" onClick={() => setShowPage(!showPage)} >
        <Avatar src={currentUser.imageUrl}/>
      </div>
      <Dialog onClose={() => setShowPage(false)} open={showPage} PaperProps={{
      style: {
          backgroundColor: theme.palette.background.light
          ,
      },
    }}>
        <Grid margin={4}>
          <div className="userProfile__page">
            <div className="userProfile__heading">
              <h2  style={{color: theme.palette.text.primary}}>My Profile</h2>
            </div>
            <div className="userProfile__info">
              <div className="userProfile__info-avatar">
                {showLoading && <Loading/>}
                <Avatar src={newUser.imageUrl}/>
                <input
                  type="file"
                  className="userProfile__info-input"
                  onChange={(e) => handleUploadImage(e)}
                />
              </div>
              <Grid container className="userProfile__info-user">
                <Grid item xs={12} md={6} className="userProfile__info-row">
                  <p style={{color: theme.palette.text.primary}}>Full Name</p>
                  <RetroInput dark
                    value={newUser.userName}
                    style={{color: "black"}}
                              onChange={(e) =>
                                setNewUser({...newUser, userName: e.target.value})
                              }
                  />
                </Grid>
                <Grid item xs={12} md={6} className="userProfile__info-row">
                  <p style={{color: theme.palette.text.primary}}>Email</p>
                  <div className="userProfile__info-email">
                    <p style={{color: theme.palette.text.primary, overflowX: 'scroll'}}>{currentUser.email}</p>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="userProfile__buttons">
              <div className="userProfile__saveChanges">
                <Button
                  variant="contained"
                  sx={{color: theme.palette.text.primary, borderColor: 'black', fontSize: '10px',backgroundColor: theme.palette.action.active}}
                  mode={newUser.userName === currentUser.userName && "cancel"}
                  onClick={() => handleSave()}
                >
                  Save Changes
                </Button>
              </div>
            </div>
          </div>
          </Grid>
        </Dialog>
    </div>
  );
};

export default UserProfile;

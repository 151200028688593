import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleStatus } from "../../redux/filter/filter.actions";
import { ThemeProvider, useTheme } from "@mui/material/styles";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["urgent", "high", "normal", "low"];

export default function FilterC() {
  const filter = useSelector((state) => state.filter);
  const selectedFilters = filter.status
    .filter((x) => x.status)
    .map((x) => x.name);
  const dispatch = useDispatch();

  const toggleFilter = (name) => {
    dispatch(toggleStatus(name));
  };
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ mr: 1 }}>
        <FormControl sx={{ m: 1, width: 150 }} size="small">
          <InputLabel
            size="small"
            id="demo-multiple-checkbox-label"
            sx={{ fontSize: 13, color: theme.palette.text.primary }}
          >
            Select Urgency
          </InputLabel>
          <Select
            size="small"
            multiple
            value={selectedFilters}
            input={
              <OutlinedInput label="Select Urgency" sx={{ fontSize: 13 }} />
            }
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem
                key={name}
                value={name}
                size="small"
                onClick={() => toggleFilter(name)}
              >
                <Checkbox
                  size="small"
                  checked={
                    selectedFilters && selectedFilters.indexOf(name) > -1
                  }
                />
                <ListItemText size="small" primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}

import React, {useState} from "react";
import {LoginWithGoogle, auth} from "../../firebase/firebase.utils";
import "./login.styles.scss";
import {Box, Typography} from "@mui/material";
import Button from '@mui/material/Button';


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const loginWithEmailAndPassword = () => {
    auth.signInWithEmailAndPassword(email, password).catch((error) => {
      const errorMessage = error.message;
      setErrorMessage(errorMessage);
      window.location.refresh()
    });
  }

  return (
    <Box className="status1">
      <Box>
        <Typography variant="h2" sx={{fontSize: 35, mb: 3, color: 'black'}}>Welcome Back!</Typography>
      </Box>
      <input
        onChange={(e) => setEmail(e.target.value)}
        placeholder="email"
        type="email"
        value={email}
      />
      <input
        onChange={(e) => setPassword(e.target.value)}
        placeholder="password"
        type="password"
        value={password}
      />
      <Button
        className="button"
        variant="text"
        onClick={() => loginWithEmailAndPassword()}
        sx={{width: "400px", backgroundColor: 'white'}}
      >
        <Typography sx={{color: 'black'}}>Sign In</Typography>
      </Button>
      <Button
        variant="text"
        onClick={() => LoginWithGoogle()}
        sx={{width: "400px", backgroundColor: 'white'}}
      >
        <Typography sx={{color: 'black'}}>Continue with Google</Typography>
      </Button>
      <Box className="login__error">
        <Typography>{errorMessage}</Typography>
      </Box>
    </Box>
  );
}

export default Login;

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import "./avatar.styles.scss";
import {Box} from "@mui/material";

const Avatar = ({src}) => (
  <Box className="avatar">
    {src ? <img alt="" src={src}/> : <FontAwesomeIcon icon={faUser}/>}
  </Box>
);

export default Avatar;

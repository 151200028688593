import {Button, Typography} from "ui-neumorphism";
import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router";

import {removeMember, newAdmin} from "../../../firebase/firebase.utils";

import RetroAvatar from "../../retro/avatar/avatar.component";
import RetroButton from "../../retro/button/retro-button.component";
import BoxLayer from "../../retro/box-layer/box-layer.component";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faStar,
  faTrashAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";

import "./member.styles.scss";
import {AnimatePresence} from "framer-motion";
import {message, Popconfirm} from "antd";
import {Avatar, Box} from "@mui/material";

function Member({member, activeOrgData, activeOrgId, addUserAsAdmin, showUserName= false,showActions=true}) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);
  const {admin, orgId, subAdmins = []} = activeOrgData;
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [adminStatus, setAdminStatus] = useState(false);

  useEffect(() => {
    if (admin === member.uid) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [admin, member]);

  const handleRemove = () => {
    if (member.uid === admin) {
      message.warning("cant delete admin");
      return;
    }
    if (currentUser.uid === member.uid) {
      message.warning("remove your self");
      return;
    }
    if (currentUser.uid === admin) {
      console.log("im admin ill delete you");
      setDeleteStatus(true);
      return;
    } else {
      message.warning("cant delet other users");
      return;
    }
  };

  const handleRemoveFull = () => {
    removeMember(orgId, member.uid);
    message.success("member removed");
    setDeleteStatus(false);
  };

  return (
    <>
      <Box key={member.uid} className="member">
        <Box style={{display:'flex', alignItems:'center', gap: 10}} className="member__avatar" sx={{mx: 0.5}}>
          <Avatar size="small" src={member.imageUrl} alt={member.userName}/>
          {showUserName && <Typography style={{fontSize:12, marginRight:15}}>{member.userName}</Typography> }
        </Box>
      {showActions && 
        <div className="member__controls">
          {currentUser.uid === admin && (
            <>
              {admin !== member.uid && (
                <>
                  <div
                    className="member__delete"
                    onClick={() => handleRemove()}
                  >
                     <Popconfirm
                      title={`Sure you want to remove ${member.userName} ?`}
                      onConfirm={ () => handleRemoveFull()}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                          <FontAwesomeIcon icon={faTrashAlt}/>
                    </Popconfirm>
                  </div>
                  <div className="member__noAdmin">
                    <Popconfirm
                      title={`Are you sure to ${
                        subAdmins.includes(member?.uid) ? "remove" : "add"
                      } this user as an admin?`}
                      onConfirm={() => addUserAsAdmin(member.uid)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <FontAwesomeIcon
                        style={{
                          ...(subAdmins.includes(member?.uid) && {
                            color: "#ffdf58",
                            opacity: 1,
                          }),
                        }}
                        icon={faUserShield}
                      />
                    </Popconfirm>
                  </div>
                  <div
                    className="member__noAdmin"
                    onClick={() => setAdminStatus(true)}
                  >
                     <Popconfirm
                      title={`Sure you want to set ${member.userName} as Admin?`}
                      onConfirm={ () => newAdmin(activeOrgId, member.uid)}
                      okText="Yes"
                      cancelText="Cancel"
                    >
                      <FontAwesomeIcon icon={faStar}/>
                    </Popconfirm>
                   
                  </div>
                </>
              )}
            </>
          )}
        </div>
}
      </Box>
      <AnimatePresence>
        {/* {deleteStatus && (
          <BoxLayer setLayer={setDeleteStatus}>
            <h3>Sure you want to remove {member.userName} ?</h3>
            <div className="member__deleteBtns">
              <Button onClick={() => setDeleteStatus(false)} mode="gray">
                cancel
              </Button>
              <Button onClick={() => handleRemoveFull()}>yes</Button>
            </div>
          </BoxLayer>
        )} */}
        {/* {adminStatus && (
          <BoxLayer setLayer={setAdminStatus}>
            <h3>
              Sure you want to set <i>{member.userName}</i> as Admin?
            </h3>
            <div className="member__addBtns">
              <Button onClick={() => setAdminStatus(false)} mode="gray">
                cancel
              </Button>
              <Button onClick={() => newAdmin(activeOrgId, member.uid)}>
                yes
              </Button>
            </div>
          </BoxLayer>
        )} */}
      </AnimatePresence>
    </>
  );
}

export default Member;

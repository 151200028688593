import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { setStatusType } from "../../redux/org/org.actions";
import "./timeline.styles.scss";
import TimelineCalendar from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import useCurrentMembers from "../../hooks/useCurrentMembers.hook";
import BoxRight from "../../components/retro/box-right/box-right.component";
import LargeTask from "../../components/large-task/large-task.component";
import { AnimatePresence } from "framer-motion";
import { updateTaskTime } from "../../firebase/firebase.utils";
import { useRef } from "react";
import { Box, Dialog, Tab, Tabs } from "@mui/material";
import EmployeeTimeline from "./EmployeeTimeline.component";
import TasksTimeline from "./TasksTimeline.component";

const Timeline = ({ job, currentJobId, currentOrgId }) => {
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  const memberPromise = useCurrentMembers();
  const [memberData, setMemberData] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const timelineScrollRef = useRef();

  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  console.log('currentOrgId',currentOrgId)

  useEffect(() => { 
    memberPromise.then((data) => {
      setMemberData(data);
    });
    if (job?.tasks && Object.values(job?.tasks).length > 0) {
      let tasks = [];
      console.log(job.tasks)
      let tasksList = [...Object.values(job?.tasks)].map((task) => {
        if (Array.isArray(task?.assign)) {
          task?.assign.map((eachAssign) => {
            tasks.push({
              id: task?.id + "," + eachAssign,
              group: eachAssign,
              title: task?.content,
              start_time: moment(task?.startdate?.toDate()),
              end_time: moment(task?.deadline?.toDate()),
              canMove: true,
              canResize: false,
              canChangeGroup: false,
              itemProps: {
                // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
                // 'data-custom-attribute': 'Random content',
                // 'aria-hidden': true,
                // onDoubleClick: () => { console.log('You clicked double!') },
                className: "weekend",
                style: {
                  background: "#1976d2",
                  borderRadius: 10,
                  paddingLeft: 10,
                  fontWeight: 700,
                },
              },
            });
          });
        }
        return {
          id: task?.id,
          group: task?.id,
          title: task?.content,
              start_time: moment(task?.startdate?.toDate()),
              end_time: moment(task?.deadline?.toDate()),
              canMove: true,
              canResize: false,
              canChangeGroup: false,
              itemProps: {
                // these optional attributes are passed to the root <div /> of each item as <div {...itemProps} />
                // 'data-custom-attribute': 'Random content',
                // 'aria-hidden': true,
                // onDoubleClick: () => { console.log('You clicked double!') },
                className: "weekend",
                style: {
                  background: "#1976d2",
                  borderRadius: 10,
                  paddingLeft: 10,
                  fontWeight: 700,
                },
              },
            }
      });
      setTasks(tasks);
      setTasksList(tasksList)
    }
    // setTimeout(()=> { timelineScrollRef.scrollLeft = 0 },500);
  }, [job]);

  useEffect(() => {
    dispatch(setStatusType(job.statusType));
    memberPromise.then((data) => {
      setMemberData(data);
    });
  }, [job]);

  const changeHandler = async (event) => {
    let task = job?.tasks
      ? Object.values(job?.tasks).find((task) => task.id === event?.itemId.split(',')[0])
      : {};
    let newTime = moment(event?.time);
    let startdate = moment(task?.startdate?.toDate());
    let deadline = moment(task?.deadline?.toDate());
    let diff = newTime.diff(startdate, "minutes");

    if (new Date(event?.time) > new Date(task?.startdate?.toDate())) {
      startdate.add(diff, "minutes");
      deadline.add(diff, "minutes");
    } else {
      startdate.add(diff, "minutes");
      deadline.add(diff, "minutes");
    }

    if (task) {
      updateTaskTime({
        currentOrgId,
        currentJobId,
        deadline: deadline,
        startdate,
        taskId: event?.itemId.split(',')[0],
      });

      // setTasks(prevTasks => {
      //   let tasks = [...prevTasks];
      //   let index = tasks.findIndex(task => task.id === event.itemId)
      //   tasks[index].end_time = deadline;
      //   tasks[index].start_time = startdate;
      //   return tasks
      // });
    }
  };

  const debouncedChangeHandler = useCallback(debounce(changeHandler, 300), [
    tasks,
    job,
  ]);

  return (
    <Box sx={{ width: "100%", marginTop: '20px' }}>
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Employees" c />
          <Tab label="Tasks" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
      <EmployeeTimeline
          job={job}
          tasks={tasks}
          memberData={memberData}
          debouncedChangeHandler={debouncedChangeHandler}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
      <TasksTimeline
          job={job}
          tasks={tasksList}
          memberData={memberData}
          debouncedChangeHandler={debouncedChangeHandler}
        />
      </TabPanel>
    </Box>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{paddingTop:'15px'}} >{children}</Box>}
    </div>
  );
}

export default Timeline;

import React, { useEffect, useState} from 'react'
import './respondinput.scss'
import { db } from '../../../firebase/firebase.utils';
import firebase from 'firebase';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setOrgId } from "../../../redux/history/history.actions";
import { useHistory } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GifIcon from '@mui/icons-material/Gif';
function Respondinput({roomNumber}) {
    const [input, setInput] = useState("");
  const currentUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme= useTheme();
  const activeOrgId = history.location.pathname.split("/")[2];
  const status = history.location.pathname.split("/")[4];
  const messageId = history.location.pathname.split("/")[5];
    function sendMessage(e) {
      e.preventDefault();
      console.log(roomNumber, "roomNumber");
      if (status=="private") {
        db.collection("message").doc(activeOrgId).collection("privatemessage").doc(messageId).collection("messaging").add({
          imageUrl:currentUser.imageUrl,
          username: currentUser.userName,
          respond: input,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          id:currentUser.uid
          });
      } else {
        db.collection("message").doc(activeOrgId).collection("rooms").doc(messageId).collection("messaging").add({
          imageUrl:currentUser.imageUrl,
          username: currentUser.userName,
          respond: input,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          id:currentUser.uid
          });
      }
        
      setInput("");
    }
  return (
   

        <Box  component="form" sx={{width:'70%',height:"50px"}} onSubmit={sendMessage}>
        <input class="chatinput"
        
        value={input}
        placeholder="Type a new message"
        onChange={(e) => setInput(e.target.value)}
          
        ></input>
      <Box sx={{ width: '100%', height: "50px", display: "flex", justifyContent: "space-between", pl:1, pr:1, pt:1}}>
          <Box >
          <SpellcheckIcon sx={{mr:1}} />
            <AttachFileIcon sx={{mr:1}} />
            <EmojiEmotionsIcon sx={{mr:1}} />
            <GifIcon sx={{mr:1}} />
          </Box>
          <Box >
          <SendIcon  onClick={sendMessage}/>
          </Box>
                  
          </Box>
      </Box>
    
    
  )
}

export default Respondinput
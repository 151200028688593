import React, {useEffect, useState} from "react";
import "./videochat.styles.scss";
import { db2, firepadRef, db } from "../../firebase/firebase.utils";
import firebase from "firebase/app";
import {useSelector} from "react-redux";
import Loading from "../../components/retro/loading/loading.component";
import {useHistory,Route,Switch} from "react-router";
import {Box, Grid,Button,Typography,Divider,Avatar} from "@mui/material";
import { useTheme, ThemeProvider } from "@mui/material/styles";
import MainScreen from "../../components/MainScreen/MainScreen.component";
import {
  setMainStream,
  addParticipant,
  setUser,
  updateUser,
  removeParticipant,
  updateParticipant,
} from "../../redux/store/actioncreator";
import { connect } from "react-redux";
import Message from "../message/message.componet.class";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { AnswerCountValidator } from "survey-react";
import { current } from "@reduxjs/toolkit";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemButton from '@mui/material/ListItemButton';
import Input from '@mui/material/Input';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import MessageIcon from '@mui/icons-material/Message';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import AddIcon from '@mui/icons-material/Add';
import { TagIcon,ExpandMore,NavigateBefore } from '@mui/icons-material';

import useCurrentMembers from "../../hooks/useCurrentMembers.hook";
import {useActiveOrgData} from "../../hooks/useActiveOrgData.hook";
import { Dialog, DialogTitle } from "@mui/material";
import Member from "../../components/members/member/member.component";
import {setOrgData} from "../../redux/org/org.actions";
import { setOrgId } from "../../redux/history/history.actions";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import VideocamIcon from '@mui/icons-material/Videocam';
import CallIcon from '@mui/icons-material/Call';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
const Videochat = () => {

  const mainStream = useSelector((state) => state.store.mainStream);
  const user = useSelector((state) => state.store.currentUser);
  const currentUser = useSelector((state) => state.user.currentUser);
  const orgData = useSelector((state) => state.org.orgData);
  const state = useSelector((state) => state);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch()
  const activeOrgId = history.location.pathname.split("/")[2];
  const roomID = history.location.pathname.split("/")[5];
  const theme = useTheme();
  const [participantId, setparticipantId] = useState();
  const [participantreff,setparticipantreff] = useState();
  const getUserStream = async () => {
    const localStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });

    return localStream;
  };
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [videoOpen, setvideoOpen] = useState(false);
  const [messageOpen, setmessageOpen] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [curRoom, setcurRoom] = useState();
  const [curRoomName, setcurRoomName] = useState();
  const [addchannelOpen, setaddchannelOpen] = useState(false);
  const [addchannelName, setaddchannelName] = useState();
  const [memberData, setMemberData] = useState([]);
  const [selectedmemberData, setselectedmemberData] = useState();
  const memberPromise = useCurrentMembers();
  const [addMembersOpen, setaddMembersOpen] = useState(false);
  const [addPmessageOpen, setaddPmessageOpen] = useState(false);
  const activeOrgData = useActiveOrgData();
  const [channelOpen, setchannelOpen] = useState(true);
  const [PmessageOpen, setPmessageOpen] = useState(true);
  const [OrgName, setOrgName] = useState();
  const [ChannelName, setChannelName] = useState();
  const [MembersName, setMembersName] = useState([]);
  const [PmessageName, setPmessageName] = useState([]);
  const [privatemodel, setprivatemodel] = useState(true);  
  useEffect(() => {
    
    history.listen(() => { 
     
      memberPromise.then((data) => {
        setMemberData(data);
      });
      console.log(memberData,"sdsmemberData");
      const userStatusRef = participantRef.child(currentUser.uid);
      userStatusRef.remove();
      setvideoOpen(false);
      setmessageOpen(true);
      forceUpdate();
      
      
   }) 
  }, [location]);

  
 

  useEffect(() => {
    if (orgData) {
      dispatch(setOrgData(orgData));
    }
    if (activeOrgId) {
      dispatch(setOrgId(activeOrgId));
    }
    
    db.collection("org").doc(activeOrgId).get().then((org) => {
      setOrgName(org.data().name);
    });
    // if (activeOrgId != roomID) {
    //   db.collection("message").doc(activeOrgId).collection("rooms").doc(roomID).get().then((org) => {
    //     setChannelName(org.data().name);
        
    //   });
    //   db.collection("message").doc(activeOrgId).collection("rooms").doc(roomID).collection("members").onSnapshot((snapshot) => { 
    //     setMembersName(snapshot.docs.map((doc) => ({
    //       name: doc.data().name,
    //       memberscolor: doc.data().memberscolor
    //     })));
     
  
    //   });
    // }
   
    db.collection("message").doc(activeOrgId).collection("rooms").onSnapshot((snapshot) => {
     setRooms(snapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().name,
          members: doc.data().members,
          maincolor: doc.data().maincolor,
          secondcolor: doc.data().secondcolor
        })));
    })
    
    db.collection("message").doc(activeOrgId).collection("privatemessage").onSnapshot((snapshot) => {
      setPmessageName(snapshot.docs.filter(doc => doc.data().participantId1 === currentUser.uid || doc.data().participantId2 === currentUser.uid));
       })
  }, []);
  
  useEffect(async () => {
    
    
     
    
    const stream = await getUserStream();
    stream.getVideoTracks()[0].enabled = false;
    dispatch(setMainStream(stream));
    
    
  }, []);
 
  
  const disconnectVideochat = () => {
   
    
    const userStatusRef = participantRef.child(currentUser.uid);
    userStatusRef.remove();
    setvideoOpen(false);
    setmessageOpen(true);
    forceUpdate();
  };

  const videoControl = () => { 
    if (participantId==currentUser.uid) {
      console.log(participantId);
    } else {
      connectedRef.on("value", (snap) => {
        if (snap.val()) {
          const defaultPreference = {
            audio: true,
            video: false,
            screen: false,
          };
          const userStatusRef = participantRef.child(currentUser.uid);
          userStatusRef.update({
            userName: currentUser.userName,
            preferences: defaultPreference,
          });
          setparticipantId(userStatusRef.key);
          dispatch(setUser({
            [userStatusRef.key]: { name: currentUser.userName, orgId:activeOrgId, ...defaultPreference },
          }))
         
         
          userStatusRef.onDisconnect().remove();
        }
        
      });
      participantRef.on("child_removed", (snap) => {

        dispatch(removeParticipant(snap.key));
        console.log(state, "removed participant");
      });
      
    }
    
    setvideoOpen(true);
    setmessageOpen(false);
  };

  const messageControl = () => { 
    setvideoOpen(false);
    setmessageOpen(true);
  };
  const connectedRef = db2.database().ref(".info/connected");
  const firepadRef1 = firepadRef.child(activeOrgId);
  const participantRef = firepadRef1.child("participants");
  
  const isUserSet = !!user;
  const isStreamSet = !!mainStream;
  useEffect(() => {
   
    if (isStreamSet && isUserSet) {
      participantRef.on("child_added", (snap) => {
        const preferenceUpdateEvent = participantRef
          .child(snap.key)
          .child("preferences");
        preferenceUpdateEvent.on("child_changed", (preferenceSnap) => {
          dispatch(updateParticipant({
            [snap.key]: {
              [preferenceSnap.key]: preferenceSnap.val(),
            },
          }));
        });
        const { userName: name, preferences = {} } = snap.val();
        console.log(snap.val(),"sdx");
        dispatch(addParticipant({
          [snap.key]: {
            name,
            ...preferences,
          },
        })
        );
      });
      participantRef.on("child_removed", (snap) => {
        dispatch(removeParticipant(snap.key));
      });
    }
  }, [isStreamSet, isUserSet]);


  const handleRoomChange = (roomId) => () => { 
    setprivatemodel(false);
    setcurRoom(roomId);
    db.collection("message").doc(activeOrgId).collection("rooms").doc(roomId).collection("members").onSnapshot((snapshot) => { 
      setMembersName(snapshot.docs.map((doc) => ({
        name: doc.data().name,
        memberscolor: doc.data().memberscolor
      })));
   

    });
    history.push(`/s/${activeOrgId}/videochat/public/${roomId}`);
  }
  
  const handlePmessageChange = (roomId) => () => { 
    setprivatemodel(true);
    setcurRoom(roomId);
    history.push(`/s/${activeOrgId}/videochat/private/${roomId}`);
  }
  
  const OpenChannel = () => { 
    setaddchannelOpen(true);
    if (addchannelOpen) {
      setaddchannelOpen(false);
    }
  }
  // const OpenChannel = () => { 
  //   setaddchannelOpen(true);
  //   if (addchannelOpen) {
  //     setaddchannelOpen(false);
  //   }
  // }
  function sendChannel(e) {
    e.preventDefault();
    setcurRoomName(addchannelName);
    db.collection("message").doc(activeOrgId).collection("rooms").add({
      name: addchannelName,
      members: 0,
      maincolor: "#" + Math.floor(Math.random() * 16777215).toString(16),
      secondcolor: "#" + Math.floor(Math.random() * 16777215).toString(16)
    }).then(doc => { 
        setcurRoom(doc.id);
        history.push(`/s/${activeOrgId}/videochat/public/${doc.id}`);
        
      });
    setaddchannelName("");
    setaddchannelOpen(false);
  }
  
  const AddRoomMembers = (id,memberName) => { 
    db.collection("message").doc(activeOrgId).collection("rooms").doc(curRoom).collection("members").add({
    
      userid: id,
      name: memberName,
      memberscolor: "#" + Math.floor(Math.random() * 16777215).toString(16)
    })
    db.collection("message").doc(activeOrgId).collection("rooms").doc(curRoom).update({
      members: firebase.firestore.FieldValue.increment(1)
    })
    
  }

  const AddPmessage = (id,memberName) => { 
    db.collection("message").doc(activeOrgId).collection("privatemessage").add({
      participantId1: currentUser.uid,
      participantId2: id,
      name: memberName,
      memberscolor: "#" + Math.floor(Math.random() * 16777215).toString(16)
    })
    
  }

  const channelexpand = () => { 
    setchannelOpen(true);
    if (channelOpen) {
      setchannelOpen(false);
    }
  }
  const Pmessageexpand = () => { 
    setPmessageOpen(true);
    if (PmessageOpen) {
      setPmessageOpen(false);
    }
  }
  const seatsDialog = () => (
    
    <Dialog  onClose={()=>setaddMembersOpen(false)} open={addMembersOpen}>
      <DialogTitle>Members</DialogTitle>
      <List style={{ minWidth: 300, maxHeight: 600, overflowY: 'scroll' }} sx={{ pt: 0 }}>
      
        {memberData.map((member) =>
        
        {
         console.log(memberData,"sdsdmemberData");
          return(
          <ListItem button key={member.uid}>
           <Box style={{display:'flex', alignItems:'center', gap: 10}} className="member__avatar" sx={{mx: 0.5}} onClick={()=>AddRoomMembers(member.uid,member.userName)}>
          <Avatar size="small" src={member.imageUrl} alt={member.userName}/>
          <Typography style={{fontSize:12, marginRight:15}}>{member.userName}</Typography>
          </Box>
                
          </ListItem>
        )})}
      </List>
    </Dialog>
  )

  const seatDialog = () => (
    
    <Dialog  onClose={()=>setaddPmessageOpen(false)} open={addPmessageOpen}>
      <DialogTitle>Members</DialogTitle>
      <List style={{ minWidth: 300, maxHeight: 600, overflowY: 'scroll' }} sx={{ pt: 0 }}>
      
        {memberData.map((member) =>
        
        {
         console.log(memberData,"sdsdmemberData");
          return(
          <ListItem button key={member.uid}>
           <Box style={{display:'flex', alignItems:'center', gap: 10}} className="member__avatar" sx={{mx: 0.5}} onClick={()=>AddPmessage(member.uid,member.userName)}>
          <Avatar size="small" src={member.imageUrl} alt={member.userName}/>
          <Typography style={{fontSize:12, marginRight:15}}>{member.userName}</Typography>
          </Box>
                
          </ListItem>
        )})}
      </List>
    </Dialog>
  )
  console.log(MembersName,"MembersName");
  // useEffect(() => { 
   
      
    
  // })
  return (
    <ThemeProvider theme={theme}>

    <Box sx={{color:theme.palette.text.primary,backgroundColor:"#7f00ab",height:"100vh",position:"initial", zIndex:5000,pt:5}} >
    
       
        
        
        <Grid item xs={12} md={12}  sx={{ display: 'flex',justifyContent: 'space-between',flexDirection: 'row', backgroundColor:"#7f00ab",height:"95vh"}}>
        
          <Grid xs={2} md={2} sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", mt: "20px", backgroundColor: "#7f00ab" }} >
            <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", pl:5}}>
              
              
              {/* <Typography sx={{ fontSize: 36, color: theme.palette.text.main }}>  {activeOrgData.name} </Typography> */}
              <ModeEditOutlineIcon sx={{color: theme.palette.text.main,mt:2}} />
              
            </Grid>
            <Divider sx={{ mb: 3, mt: 3, backgroundColor: theme.palette.text.main, ml: 5 }} />
            <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <Typography sx={{ fontSize: 24, color: theme.palette.text.main, ml: 5}}> GROUPS AND MESSAGES </Typography>
              <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Button sx={{ backgroundColor: "#7f00ab", color: theme.palette.text.main, ml: 5 }} onClick={channelexpand}> Channel {channelOpen ? <ExpandMore /> : <NavigateBefore />} </Button>
                <Button sx={{ backgroundColor: "#7f00ab", color: theme.palette.text.main }} onClick={OpenChannel}>Add <AddIcon/></Button>
                
              </Grid>
              
              {channelOpen ? (
               <List sx={{ml:5}}>
                      
               {addchannelOpen?
                 (
                   <> <form onSubmit={sendChannel} >
                     <ListItem>
                 {/* <ListItemAvatar>
                   <Avatar>
                     <DeleteIcon />
                   </Avatar>
                 </ListItemAvatar> */}
                 
               
                   
                       
                   <Input
                     value={addchannelName}
                     placeholder=" type here "
                     onChange={(e) => setaddchannelName(e.target.value)}
                     sx={{color:theme.palette.text.main}}
                   ></Input>
                       
                   </ListItem>
                     
                     </form ></>
                 ) : null
                 }
             {rooms.map((room) => {
               return (
                 
                 <ListItemButton onClick={handleRoomChange(room.id)} >
                   <ListItem sx={{color: theme.palette.text.main}}>
                     
                      <Avatar sx={{ bgcolor: room.maincolor, mr:1, ml:-5}}>{ room.name.charAt(0).toUpperCase() }</Avatar>
               
                      <ListItemText
                       primary={room.name}
                       sx={{justifyItems:"left"}}
                     />
                     
                     <Avatar sx={{ bgcolor: room.secondcolor, mr: 1, ml: -5 }}>{ room.members}</Avatar>
                    
               
                       
                   </ListItem>
                   </ListItemButton>
                   
               ) 
             })}
           </List>
              ): null
                    }
              
             
            </Grid>
            <Grid sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Button sx={{ backgroundColor: "#7f00ab", color: theme.palette.text.main, ml: 5 }} onClick={Pmessageexpand}> Deirct Message {channelOpen ? <ExpandMore /> : <NavigateBefore />} </Button>
                <Button sx={{ backgroundColor: "#7f00ab", color: theme.palette.text.main }} onClick={()=>setaddPmessageOpen(true)}>Add <AddIcon/></Button>
                
            </Grid>
            
            {PmessageOpen ? (
               <List sx={{ml:5}}>
                      
              
             {PmessageName.map((room) => {
               return (
                 
                 <ListItemButton onClick={handlePmessageChange(room.id)} >
                   <ListItem sx={{color: theme.palette.text.main}}>
                     
                      <Avatar sx={{ bgcolor: room.data().memberscolor, mr:1, ml:-5}}>{ room.data().name.charAt(0).toUpperCase() }</Avatar>
               
                      <ListItemText
                       primary={room.data().name}
                       sx={{justifyItems:"left"}}
                     />
                     
                     
                    
               
                       
                   </ListItem>
                   </ListItemButton>
                   
               ) 
             })}
           </List>
              ): null
                    }

          </Grid>
          <Grid container xs={10} md={10}  sx={{ backgroundColor: theme.palette.background.lightwhite,display:"flex",justifyContent:"center",flexDirection:"column",mx: 'auto'}} >
            <Grid sx={{ backgroundColor: theme.palette.background.lightwhite, display: "flex", justifyContent: "space-around", flexDirection: "row" }}>
            {privatemodel?
              (<Box></Box>) : (<Grid  sx={{ backgroundColor: theme.palette.background.lightwhite, display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
              <Button sx={{ fontSize: 24, color: theme.palette.text.primary}}>Members: </Button>
                {MembersName.map((member) => { 
                  return <Avatar sx={{ bgcolor: member.memberscolor,mr:1,mt:1}}>{ member.name.charAt(0).toUpperCase() }</Avatar>
                })}
                <Button sx={{ color: theme.palette.text.primary,fontSize:18}} onClick={()=>setaddMembersOpen(true)}>Add <AddIcon/> </Button>
              </Grid>)
            }
              
              <Grid  sx={{ backgroundColor: theme.palette.background.lightwhite}}>
                <Typography sx={{ fontSize: 24, color: theme.palette.text.primary, mr: 25,fontWeight:"bold" }}>{ChannelName}</Typography> 
              </Grid>
              <Grid sx={{ backgroundColor: theme.palette.background.lightwhite }}>
              
              <VideocamOffIcon sx={{ color: theme.palette.text.dark,fontSize: 36, mr:2}}onClick={disconnectVideochat}/>
              
              <VideocamIcon sx={{ color: theme.palette.text.dark,fontSize: 36, mr:2}} onClick={videoControl}/>
              <CallIcon sx={{ color: theme.palette.text.dark,fontSize: 36}}/>
              
              {/* <Button sx={{ backgroundColor: theme.palette.action.active, color: theme.palette.text.main, mx: "10px" }} onClick={messageControl}>
                <MessageIcon/>
                Message</Button> */}
              </Grid>
             
            </Grid>
            <Divider sx={{ mb: 3, mt: 3, backgroundColor: theme.palette.text.dark, mx:5}} />
            {videoOpen?
              (<MainScreen onDisconnect={disconnectVideochat}/>) : null
            }
            {messageOpen?
              (
                <Grid container sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:"10px"}}>
                  
                  <Grid xs={10} md={10} >
                    <Route
                    exact
                    path="/s/:id/videochat/public/:id"
                    render={() => <Message/>}
                      />
                    <Route
                    exact
                    path="/s/:id/videochat/private/:id"
                    render={() => <Message/>}
                      />
                     
                  </Grid>
                  
                  
                  </Grid>
              ) : <Grid container sx={{display:"flex",flexDirection:"row",justifyContent:"center",mt:"10px"}}>
                  
              
              
              
              </Grid>
            }
           
            
            
          </Grid>

          </Grid>
       
        
        {seatsDialog()}
        {seatDialog()}
      {loading && <Loading/>}
      </Box>
      </ThemeProvider>
  );
};


export default Videochat;


import { message } from "antd";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/database";
import { v4 as uuidv4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyAR8-sLo8fzVgiZlTbtUsa2DAIo9srCYaw",
  authDomain: "klareos-3080c.firebaseapp.com",
  databaseURL: "https://klareos-3080c-default-rtdb.firebaseio.com",
  projectId: "klareos-3080c",
  storageBucket: "klareos-3080c.appspot.com",
  messagingSenderId: "974773042013",
  appId: "1:974773042013:web:0f76ad7ebbd1b296cf46b4",
  measurementId: "G-502K3MXP9T",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const db = firebase.firestore();
const db2 = firebase;
var firepadRef = firebase.database().ref();

// const urlparams = new URLSearchParams(window.location.search);
// const roomId = urlparams.get("orgid");
// console.log(roomId, "roomId");
// if (roomId) {
//   firepadRef = firepadRef.child(roomId);
// } else {
// }

var provider = new firebase.auth.GoogleAuthProvider();
const auth = firebase.auth();
export const cloudFunctions = firebase.functions();
export const timestamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export var storage = firebase.storage();
if (process.env.NODE_ENV === "development") {
  cloudFunctions.useFunctionsEmulator("http://localhost:5001");
}

const LoginWithGoogle = async () => {
  try {
    await auth.signInWithPopup(provider);
    window.location.reload();
  } catch (error) {
    console.log(error.message);
  }
};

const loginWithEmailAndPassword = (email, password) => {
  auth.signInWithEmailAndPassword(email, password).catch((error) => {
    var errorMessage = error.message;
    console.log(errorMessage);
  });
  window.location.refresh();
};

export const registerUserFb = async (user, userName) => {
  const { uid, email, photoURL } = user;
  const userRef = await db.collection("users").doc(uid).get();
  if (!userRef.exists) {
    await db.collection("users").doc(uid).set({
      userName: userName,
      uid: uid,
      email: email,
      assignedTasks: [],
      favoriteJobs: [],
      imageUrl: photoURL,
      open: true,
    });
    const userImage = await db.collection("users").doc(uid).get();
    const userImageUrl = userImage.data().imageUrl;
    if (userImageUrl === null) {
      db.collection("users").doc(uid).set(
        {
          imageUrl: "https://cdn-icons-png.flaticon.com/512/3135/3135715.png",
        },
        { merge: true }
      );
    }
    // create subscription
  } else {
    console.log("no code");
  }
};

const createNewOrg = async (name, currentUser, color, setLayer) => {
  const { uid } = currentUser;
  if (!name) {
    alert("Org name is req");
    return;
  }

  await db
    .collection("org")
    .add({
      name: name,
      admin: uid,
      color: color,
      members: firebase.firestore.FieldValue.arrayUnion(uid),
      created: firebase.firestore.FieldValue.serverTimestamp(),
      description: "Add description",
    })
    .then((data) => {
      let id = data.id;
      db.collection("org").doc(id).set(
        {
          orgId: id,
        },
        { merge: true }
      );
    });

  if (setLayer) {
    setLayer(false);
  }
};

export const createNewJob2 = async (
  orgId,
  jobName,
  statusType,
  statusOrder,
  modules
) => {
  let jobId = "123";
  const jobsRef = db.collection("org").doc(orgId).collection("jobs");

  await jobsRef
    .add({
      name: jobName,
      description: "Add description",
      fromOrgId: orgId,
    })
    .then((data) => {
      let id = data.id;
      jobId = id;
      jobsRef.doc(id).set(
        {
          jobId: id,
        },
        { merge: true }
      );
      jobsRef.doc(id).collection("tasks").doc("tasks").set({
        tasks: null,
        statusType,
        statusOrder,
      });
      jobsRef.doc(id).collection("modules").doc("modules").set({
        modules,
      });
    });
  return jobId;
};

export const createNewTask = async (
  orgId,
  jobId,
  statusName,
  newTaskName,
  userId
) => {
  const docRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");
  const getData = await docRef.get();
  const data = getData.data();
  const { statusType, tasks } = data;

  // TASKS
  let v4 = uuidv4();
  let newTask = {
    [v4]: {
      id: v4,
      content: newTaskName,
      createdBy: userId,
      assign: null,
      created: new Date(),
      timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
      startdate: null,
      deadline: null,
      fromOrgId: orgId,
      fromJobId: jobId,
      message: [],
      description: "Add description",
      done: false,
      subtasks: [],
      priority: [
        { name: "Urgent", active: false, color: "rgb(226, 68, 92)" },
        { name: "High", active: false, color: "rgb(253, 171, 61)" },
        { name: "Normal", active: true, color: "rgb(52, 181, 228)" },
        { name: "Low", active: false, color: "rgb(5, 206, 145)" },
      ],
    },
  };
  let newTasks = {
    ...tasks,
    ...newTask,
  };

  let taskIds = statusType[statusName].taskIds;

  taskIds.push(v4);

  const newData = {
    ...data,
    statusType: {
      ...statusType,
      [statusName]: {
        ...data.statusType[statusName],
        taskIds: taskIds,
      },
    },
    tasks: newTasks,
  };
  docRef.set({
    ...newData,
  });
};

export const createNewSubtask = async (orgId, jobId, taskId, content) => {
  let v4 = uuidv4();

  const docRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  const getRef = await docRef.get();
  const data = getRef.data();

  const newSubtask = {
    id: v4,
    created: new Date(),
    content: content,
    done: false,
    fromOrgId: orgId,
    fromJobId: jobId,
    fromTaskId: taskId,
  };

  const newData = {
    ...data,
    tasks: {
      ...data.tasks,
      [taskId]: {
        ...data.tasks[taskId],
        subtasks: [...data.tasks[taskId].subtasks, newSubtask],
      },
    },
  };

  docRef.set({
    ...newData,
  });
};

export const updateSubDrag = (orgId, jobId, taskId, newData) => {
  let task = {};
  let tasks = {};
  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      task = taskData.data().tasks[taskId];
      tasks = taskData.data().tasks;
      console.log(taskData.data());
      tasks = {
        ...tasks,
        [taskId]: {
          ...task,
          subtasks: [...newData],
        },
      };
    })
    .then(() => {
      tasksRef.update({
        tasks: {
          ...tasks,
        },
      });
    });
};

export const updateSubTaskAsDone = async (
  orgId,
  jobId,
  taskId,
  subId,
  done
) => {
  let task = {};
  let tasks = {};
  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      task = taskData.data().tasks[taskId];
      tasks = taskData.data().tasks;
      let subtasks = task.subtasks;
      const findIndex = subtasks.findIndex((item) => item.id === subId);
      subtasks[findIndex].done = !subtasks[findIndex].done;
      console.log(subtasks);
      /*     const toogleSubDone = !subDone;
    console.log(toogleSubDone); */
      tasks = {
        ...tasks,
        [taskId]: {
          ...tasks[taskId],
          subtasks: [...subtasks],
        },
      };
    })
    .then(() => {
      tasksRef.update({
        tasks: {
          ...tasks,
        },
      });
    });
};

export const updateDrag = (orgId, jobId, newState) => {
  db.collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks")
    .set({
      ...newState,
    });
};

export const updateTaskTime = async ({
  currentOrgId,
  currentJobId,
  taskId,
  startdate,
  deadline,
}) => {
  let tasksRef = db
    .collection("org")
    .doc(currentOrgId)
    .collection("jobs")
    .doc(currentJobId)
    .collection("tasks")
    .doc("tasks");
  let data = await tasksRef.get();
  let tasks = data.data()?.tasks;
  if (tasks) {
    tasks[taskId] = {
      ...tasks[taskId],
      deadline: firebase.firestore.Timestamp.fromDate(deadline.toDate()),
      startdate: firebase.firestore.Timestamp.fromDate(startdate.toDate()),
    };

    await tasksRef
      .update({
        tasks,
      })
      .catch((err) => console.log(err));
  }
};

export const removeMember = (orgId, memberId) => {
  console.log(orgId, memberId);
  db.collection("org")
    .doc(orgId)
    .update({
      members: firebase.firestore.FieldValue.arrayRemove(memberId),
    });
};

export const newAdmin = (orgId, memberId) => {
  db.collection("org").doc(orgId).update({
    admin: memberId,
  });
};

export const setOrgAsFavorite = (userId, orgId) => {
  db.collection("users").doc(userId).update({
    favoriteOrg: orgId,
  });
};

export const getFavoriteJobs = async (favoriteOrgId) => {
  if (!favoriteOrgId) return;
  const colRef = db.collection("org").doc(favoriteOrgId).collection("jobs");
  const querySnapshop = await colRef.get();
  if (querySnapshop.empty) {
    console.log("empity");

    let list = [];
    querySnapshop.forEach((data) => {
      list.push(data.data());
    });
    return list;
  }
};

export const renameJob = (orgId, jobId, newName) => {
  db.collection("org").doc(orgId).collection("jobs").doc(jobId).update({
    name: newName,
  });
};

export const renameOrg = (orgId, newName) => {
  db.collection("org").doc(orgId).update({
    name: newName,
  });
};

export const deleteOrg = (orgId) => {
  db.collection("org")
    .doc(orgId)
    .collection("jobs")
    .get()
    .then((jobQuery) => {
      if (!jobQuery.empty) {
        jobQuery.forEach((jobDoc) => {
          db.collection("org")
            .doc(orgId)
            .collection("jobs")
            .doc(jobDoc.id)
            .collection("tasks")
            .doc("tasks")
            .delete();
          db.collection("org")
            .doc(orgId)
            .collection("jobs")
            .doc(jobDoc.id)
            .collection("modules")
            .doc("modules")
            .delete();
          jobDoc.ref.delete();
        });
      }
    })
    .then(() => {
      db.collection("org").doc(orgId).delete();
    });
};

export const deleteJob = async (orgId, jobId) => {
  await db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("modules")
    .doc("modules")
    .delete();

  await db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks")
    .collection("msg")
    .get()
    .then((query) => {
      query.forEach((msgDoc) => {
        db.collection("org")
          .doc(orgId)
          .collection("jobs")
          .doc(jobId)
          .collection("tasks")
          .doc("tasks")
          .collection("msg")
          .doc(msgDoc.id)
          .delete();
      });
    })
    .then(() => {
      db.collection("org")
        .doc(orgId)
        .collection("jobs")
        .doc(jobId)
        .collection("tasks")
        .doc("tasks")
        .delete();

      db.collection("org").doc(orgId).collection("jobs").doc(jobId).delete();
    });
};

export const updateColorOfOrg = (orgId, color) => {
  db.collection("org").doc(orgId).update({
    color: color,
  });
};

export const changeDescriptionOfOrg = (orgId, newDesc) => {
  db.collection("org").doc(orgId).update({
    description: newDesc,
  });
};

export const changeDescriptionOfJob = (orgId, jobId, newDesc) => {
  db.collection("org").doc(orgId).collection("jobs").doc(jobId).update({
    description: newDesc,
  });
};
export const changeNameOfJob = (orgId, jobId, newName) => {
  console.log(orgId, jobId, newName);
  db.collection("org").doc(orgId).collection("jobs").doc(jobId).update({
    name: newName,
  });
};

export const changeTaskName = async (orgId, jobId, newName, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        content: newName,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const changeTaskSurvey = async (orgId, jobId, surveyId, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        surveyId,
      };
    })
    .then(() => {
      tasksRef
        .set(
          {
            tasks: {
              ...allTasks,
              [taskId]: {
                ...task,
              },
            },
          },
          { merge: true }
        )
        .then(() => message.success("Survey Changed!"));
    });
};

export const saveTaskSurveyPDF = async (
  orgId,
  jobId,
  fileName,
  pdfLink,
  taskId
) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        surveyResults: [...(task?.surveyResults || []), { fileName, pdfLink }],
      };
    })
    .then(() => {
      tasksRef
        .set(
          {
            tasks: {
              ...allTasks,
              [taskId]: {
                ...task,
              },
            },
          },
          { merge: true }
        )
        .then(() => message.success("Results Saved!"));
    });
};

export const updateModulesDb = (orgId, jobId, module, modules) => {
  let objIndex = modules.findIndex((item) => item.name === module.name);
  modules[objIndex].active = !modules[objIndex].active;
  db.collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("modules")
    .doc("modules")
    .set({
      modules,
    });
};

export const getUserDataWithId = async (userId) => {
  const userRef = db.collection("users").doc(userId);
  const userData = await userRef.get();
  const data = userData.data();
  return data;
};

export const assignMember = (orgId, jobId, taskId, userId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        assign: Array.isArray(task.assign)
          ? [...task.assign, userId]
          : [userId + ""],
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const unAssignMember = (orgId, jobId, taskId, userId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        assign: Array.isArray(task.assign)
          ? task.assign?.filter((it) => it !== userId)
          : [],
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const unAssign = (orgId, jobId, taskId) => {
  let task = {};
  let tasks = {};
  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      task = taskData.data().tasks[taskId];
      tasks = taskData.data().tasks;
      console.log(taskData.data());
      tasks = {
        ...tasks,
        [taskId]: {
          ...task,
          assign: [],
        },
      };
    })
    .then(() => {
      console.log(tasks);
      tasksRef.update({
        tasks: {
          ...tasks,
        },
      });
    });
};

export const setPriority = (orgId, jobId, taskId, allPriority, priority) => {
  let allTasks = [];
  let task = [];

  // set all false
  allPriority.map((item) => (item.active = false));

  // set active on clicked element
  priority.active = true;

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        priority: [...allPriority],
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const setStatus = (
  orgId,
  jobId,
  currentStatusType,
  taskId,
  status,
  statusType
) => {
  let removedStatus = {};

  console.log(statusType);

  // remove taskId from current
  let removeCurrentId = currentStatusType.taskIds.filter((id) => id !== taskId);

  removedStatus = currentStatusType.taskIds = removeCurrentId;
  console.log(removedStatus[0]);

  // add taskId to new

  statusType[status.name] = {
    ...status,
    taskIds: [...status.taskIds, taskId],
  };

  console.log(statusType);

  // set taskIds

  const statusTypeRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  statusTypeRef.set(
    {
      statusType: { ...statusType },
    },
    { merge: true }
  );
};

export const convertDate = (timestamp) => {
  if (!timestamp) {
    return;
  }

  let myTime = timestamp.toDate();
  let date = myTime.getDate();
  let month = myTime.getMonth();
  let year = myTime.getFullYear();
  return `${date}.${month + 1}`;
};

export const setStartDate = async (orgId, jobId, date, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  await tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        startdate: date,
      };
    })
    .then(async () => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );

      if (Array.isArray(task.assign)) {
        await Promise.all(
          task.assign.map(async (userId) => {
            let userData = await db.collection("users").doc(userId).get();
            if (
              Array.isArray(userData?.data()?.assignedTasks) &&
              userData?.data()?.assignedTasks.length > 0
            ) {
              let allTasks = userData.data().assignedTasks;
              let taskDataInUserIndex = allTasks.findIndex(
                (task) => task.id === taskId
              );
              let taskDataInUser = allTasks[taskDataInUserIndex];
              taskDataInUser = {
                ...taskDataInUser,
                startdate: date,
              };
              allTasks[taskDataInUserIndex] = taskDataInUser;
              await userData.ref.update({
                assignedTasks: allTasks,
              });
            }
          })
        );
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const setDeadlineDate = async (orgId, jobId, date, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  await tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        deadline: date,
      };
    })
    .then(async () => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );

      if (Array.isArray(task.assign)) {
        await Promise.all(
          task.assign.map(async (userId) => {
            let userData = await db.collection("users").doc(userId).get();
            if (
              Array.isArray(userData?.data()?.assignedTasks) &&
              userData?.data()?.assignedTasks.length > 0
            ) {
              let allTasks = userData.data().assignedTasks;
              let taskDataInUserIndex = allTasks.findIndex(
                (task) => task.id === taskId
              );
              let taskDataInUser = allTasks[taskDataInUserIndex];
              taskDataInUser = {
                ...taskDataInUser,
                deadline: date,
              };
              allTasks[taskDataInUserIndex] = taskDataInUser;
              await userData.ref.update({
                assignedTasks: allTasks,
              });
            }
          })
        );
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
export const setTimeTask = async (orgId, jobId, time, taskId) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  await tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        time: time,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export const changeStatusTypeName = (
  orgId,
  jobId,
  statusName,
  newName,
  statusTypeCheck
) => {
  let keys = Object.keys(statusTypeCheck);
  if (statusName === newName) {
    alert("same name");
    return;
  }
  if (keys.includes(newName)) {
    alert("name allready takaen");
    return;
  }

  let statusOrder = [];
  let statusType = {};

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      statusOrder = taskData.data().statusOrder;
      let indexStatus = statusOrder.findIndex((item) => item === statusName);
      statusOrder[indexStatus] = newName;
      console.log(statusOrder);

      statusType = taskData.data().statusType;

      statusType = {
        ...statusType,
        [statusName]: {
          ...statusType[statusName],
          name: newName,
          id: newName,
        },
      };
      statusType[newName] = {
        ...statusType[statusName],
      };
      delete statusType[statusName];
    })
    .then(() => {
      tasksRef.update({
        statusOrder,
        statusType,
      });
    });
};

export const deleteStatusType = (orgId, jobId, statusName) => {
  let statusOrder = [];
  let newOrder = [];
  let statusType = {};
  let tasks = {};

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      // get all data
      statusOrder = taskData.data().statusOrder;
      statusType = taskData.data().statusType;
      tasks = taskData.data().tasks;

      // set status order
      newOrder = statusOrder.filter((item) => item !== statusName);
      console.log(newOrder);
      let taskIds = statusType[statusName]?.taskIds || [];

      taskIds.map((eachTaskId) => {
        let taskData = tasks[eachTaskId];
        db.collection("org")
          .doc(orgId)
          .collection("archived-job")
          .add(taskData);
      });

      // set status type
      delete statusType[statusName];
      console.log(statusType);
    })
    .then(() => {
      tasksRef.update({
        statusOrder: [...newOrder],
        statusType,
      });
    });
};

export const createNewStatus = (orgId, jobId, newName) => {
  let statusOrder = [];
  let statusType = {};

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      // get all data
      statusOrder = taskData.data().statusOrder;
      statusType = taskData.data().statusType;

      // chekc conditions
      if (statusOrder.includes(newName)) {
        alert("name allready taken");
        return;
      }

      // set status order
      statusOrder.push(newName);

      // set status type
      statusType = {
        ...statusType,
        [newName]: {
          color: "rgb(234, 236, 239)",
          fontColor: "rgb(246, 91, 196)",
          id: newName,
          name: newName,
          taskIds: [],
          open: true,
        },
      };
      console.log(statusType);
    })
    .then(() => {
      tasksRef.update({
        statusOrder,
        statusType,
      });
    });
};

export const setTaskColor = (orgId, jobId, statusName, newColor) => {
  console.log("setting new color", newColor);
  let statusType = {};

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      // get all data
      statusType = taskData.data().statusType;

      // set status type
      statusType = {
        ...statusType,
        [statusName]: {
          ...statusType[statusName],
          fontColor: newColor,
        },
      };
      console.log(statusType);
    })
    .then(() => {
      tasksRef.update({
        statusType,
      });
    });
};

export const updateUser = (userId, user) => {
  const userRef = db.collection("users").doc(userId);
  userRef.update({
    ...user,
  });
};

export const getTaskData = (orgId, jobId) => {
  return db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .get()
    .then((data) => {
      return data.data();
    });
};

export const removeStarFavorite = (userId, jobId) => {
  db.collection("users")
    .doc(userId)
    .update({
      favoriteJobs: firebase.firestore.FieldValue.arrayRemove(jobId),
    });
};
export const addStarFavorite = (userId, jobId) => {
  db.collection("users")
    .doc(userId)
    .update({
      favoriteJobs: firebase.firestore.FieldValue.arrayUnion(jobId),
    });
};

export const addSurveyToJob = (title, surveyData, orgId) =>
  db.collection("org").doc(orgId).collection("surveys").add({
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    title,
    surveyData,
  });

export const addTrainingToJob = (title, surveyData, orgId) =>
  db.collection("org").doc(orgId).collection("trainings").add({
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    title,
    surveyData,
  });

/* export const unAssignFromAllTasks = (assignedArray, orgId, userId) => {
  let mustRemoveTasks = assignedArray.filter(
    (item) => item.fromOrgId === orgId
  );
  console.log(mustRemoveTasks);
  mustRemoveTasks.map((task) => {
    let allTasks = {};
    const { fromOrgId, fromJobId } = task;
    const tasksRef = db
      .collection("org")
      .doc(fromOrgId)
      .collection("jobs")
      .doc(fromJobId)
      .collection("tasks")
      .doc("tasks");

    tasksRef
      .get()
      .then((tasksData) => {
        allTasks = tasksData.data().tasks;
        allTasks[task.id].assign = [];
      })
      .then(() => {
        tasksRef.set(
          {
            tasks: {
              ...allTasks,
            },
          },
          { merge: true }
        );
      });
  });
}; */

export const createMessageToTask = (
  orgId,
  jobId,
  newMessage,
  userId,
  taskId
) => {
  db.collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks")
    .collection("msg")
    .add({
      message: newMessage,
      from: userId,
      created: new Date(),
      taskId: taskId,
    });
};

export const toggleStatus = (orgId, jobId, statusName) => {
  let statusType = {};
  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");
  tasksRef.get().then((taskData) => {
    statusType = taskData.data().statusType;
    statusType = {
      ...statusType,
      [statusName]: {
        ...statusType[statusName],
        open: !statusType[statusName].open,
      },
    };
    tasksRef.update({
      statusType,
    });
  });
};

export const setOpenFb = (userId, currentOpen) => {
  db.collection("users").doc(userId).update({
    open: !currentOpen,
  });
};

export const setTaskDescription = (orgId, jobId, taskId, taskDescription) => {
  let task = {};
  let tasks = {};
  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      task = taskData.data().tasks[taskId];
      tasks = taskData.data().tasks;
      console.log(taskData.data());
      tasks = {
        ...tasks,
        [taskId]: {
          ...task,
          description: taskDescription,
        },
      };
    })
    .then(() => {
      console.log(tasks);
      tasksRef.update({
        tasks: {
          ...tasks,
        },
      });
    })
    .catch((err) => console.log(err));
};

export const toggleCheckBox = async (orgId, jobId, taskId, currentCheck) => {
  let allTasks = [];
  let task = [];

  const tasksRef = db
    .collection("org")
    .doc(orgId)
    .collection("jobs")
    .doc(jobId)
    .collection("tasks")
    .doc("tasks");

  tasksRef
    .get()
    .then((taskData) => {
      allTasks = taskData.data().tasks;
      task = taskData.data().tasks[taskId];
      task = {
        ...task,
        done: !currentCheck,
      };
    })
    .then(() => {
      tasksRef.set(
        {
          tasks: {
            ...allTasks,
            [taskId]: {
              ...task,
            },
          },
        },
        { merge: true }
      );
    });
};

export {
  db2,
  db,
  auth,
  LoginWithGoogle,
  loginWithEmailAndPassword,
  createNewOrg,
  firepadRef,
};

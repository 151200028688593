import React, {useState, useMemo} from "react";
import {useSelector} from "react-redux";
import "./priority.styles.scss";
import Tooltip from "../../retro/tooltip/tooltip.component";
import {setPriority} from "../../../firebase/firebase.utils";
import {Chip, Menu} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";

const Priority = ({task, onlyShow = false}) => {
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const [currentPriority, setCurrentPriority] = useState();
  const [showPriority, setShowPriority] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useMemo(() => {
    if (!task.priority) return;
    const {priority} = task;
    const findActive = priority.filter((item) => item.active === true);
    setCurrentPriority(findActive[0]);
  }, [task]);

  const priority = task.priority.filter(x => x.active)[0]

  const priorityMap = {
    Urgent: 'error',
    High: 'warning',
    Normal: 'primary',
    Low: 'success'
  }

  return (
    <div className="priority">
      <>
        {currentPriority && (
          <div
            className="priority__current"
            onClick={() => !onlyShow && setShowPriority(!showPriority)}
          >
            {currentPriority && (<div
              className="priority__current"
              onClick={handleClick}
            >
              <Tooltip text={onlyShow ? "Priority" : "Set priority"}/>
              <Chip
                sx={{cursor: 'pointer'}}
                size="small"
                label={priority.name}
                color={priorityMap[priority.name]}
                variant="contained"
              />
            </div>)}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {task?.priority?.map((priority) => {
                return (
                  <MenuItem
                    key={priority.name}
                    onClick={() => {
                      setPriority(
                        orgId,
                        jobId,
                        task.id,
                        task.priority,
                        priority
                      );
                      setAnchorEl(null);
                    }}
                  >
                    <p style={{color: priority.color}}>{priority.name}</p>
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        )}
      </>
    </div>
  );
};

export default Priority;

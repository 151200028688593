import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {db} from "../../firebase/firebase.utils";
import {ListItemButton} from "@mui/material";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
function NestedJobComponent({activeOrgId}) {
  const [jobData, setJobData] = useState([]);
  const theme = useTheme();
  useEffect(() => {
    db.collection("org")
      .doc(activeOrgId)
      .collection("jobs")
      /* .orderBy("created", "asc") */
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push(doc.data());
        });
        setJobData(docs)
      });
  }, [activeOrgId]);

  return (
    <ThemeProvider theme={theme}>
    <div style={{overflowY: 'scroll'}}>
      {jobData?.length < 1 ? (
        <div>
            <div>
            <ListItemButton sx={{pl: 8}} style={{marginBottom: 5}}>
                  <h3 style={{ color: theme.palette.text.main}}>No Jobs!</h3>
              </ListItemButton>
          </div>
        </div>
      ) : (
        <div>
          {jobData?.map((job) => {
            return (
              <ListItemButton sx={{pl: 8}} key={`/s/${activeOrgId}/e/${job.jobId}/b`} style={{marginBottom: 5}}>
                <Link to={`/s/${activeOrgId}/e/${job.jobId}/b`}>
                  <h3 style={{ color: theme.palette.text.main }}>{job.name}</h3>
                </Link>
              </ListItemButton>
            );
          })}
        </div>
      )}
      </div>
      </ThemeProvider>
  );
}

export default NestedJobComponent;

import React, {useEffect, useState, useRef} from "react";
import {useSelector} from "react-redux";
import {
  db,
  createMessageToTask,
  changeTaskName,
  auth,
  changeTaskSurvey,
  saveTaskSurveyPDF,
} from "../../firebase/firebase.utils";
import "./large-task.styles.scss";
import LoadModule from "../modules/load-module.component.jsx/load-module.component";
import Loading from "../retro/loading/loading.component";
import Subtasks from "../dnd/subtasks/subtasks.component";
import {Select} from "antd";
import {setJobSurveys} from "../../redux/org/org.actions";
import {useDispatch} from "react-redux";
import DoSurvey from "./DoSurvey";
import useCurrentSubAdmins from "../../hooks/useCurrentSubAdmins";

const {Option} = Select;
const LargeTask = ({task}) => {
  const orgId = useSelector((state) => state.history.orgId);
  const moduleData = useSelector((state) => state.org.moduleData);
  const orgData = useSelector((state) => state.org.orgData);
  const surveysData = useSelector((state) => state.org.surveysData);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [msgs, setMsgs] = useState([]);
  const [message, setMessage] = useState("");
  const [newTaskName, setNewTaskName] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const currentOrgSubAdmins = useCurrentSubAdmins();
  const [limit, setLimit] = useState(3);
  const inputTaskRef = useRef();
  const textareaRef = useRef();

  const {fromOrgId, fromJobId} = task;
  // debugger;

  const dispatch = useDispatch();
  useEffect(() => {
    db.collection("org")
      .doc(orgId)
      .collection("surveys")
      .get()
      .then((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({...doc.data(), id: doc.id});
        });
        dispatch(setJobSurveys(docs));
      });
  }, [task]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message) {
      alert("no message");
      return;
    }
    createMessageToTask(
      task.fromOrgId,
      task.fromJobId,
      message,
      currentUser.uid,
      task.id
    );
    textareaRef.current.value = "";
    setMessage("");
  };

  useEffect(() => {
    const {fromOrgId, fromJobId, id} = task;
    db.collection("org")
      .doc(fromOrgId)
      .collection("job")
      .doc(fromJobId)
      .collection("tasks")
      .doc("tasks")
      .collection("msg")
      /* .orderBy("created", "asc") */
      .where("taskId", "==", id)
      .limit(limit)
      .onSnapshot((msgsData) => {
        let list = [];
        msgsData.forEach((msg) => {
          list.push(msg.data());
        });
        setMsgs(list);
      });
  }, []);

  const handleChangeTask = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);
      await changeTaskName(fromOrgId, fromJobId, newTaskName, task.id);
    } catch (error) {
      console.log(error.message);
    } finally {
      setTimeout(() => {
        setShowLoading(false);
        inputTaskRef.current.blur();
      }, 500);
    }
  };

  const handleChangeTaskSurvey = async (e) => {
    try {
      setShowLoading(true);
      await changeTaskSurvey(fromOrgId, fromJobId, e, task.id);
    } catch (error) {
      console.log(error.message);
    } finally {
      setTimeout(() => {
        setShowLoading(false);
      }, 500);
    }
  };

  return (
    <div className="largeTask">
      <div className="lt__taskStuff d-flex">
        <form onSubmit={(e) => handleChangeTask(e)}>
          {showLoading && <Loading/>}
          <input
            ref={inputTaskRef}
            placeholder={task.content}
            onChange={(e) => setNewTaskName(e.target.value)}
            disabled
          />
        </form>
      </div>
      <div className="lt__content">
        <div className="lt__modules">
          {moduleData.map((module) => {
            return <LoadModule module={module} key={module.name} task={task}/>;
          })}

          <br/>

          <br/>
          {(orgData.find((s) => s.orgId === orgId)?.admin ===
            auth.currentUser.uid ||
            currentOrgSubAdmins.includes(auth.currentUser.uid)) && (
            <div className="lt__subtasks">
              <ol className="survey-pdf-list">
                <span>Survey PDf List</span>
                {task?.surveyResults?.map((eachSurvey) => {
                  return (
                    <li>
                      <a target="_blank" href={eachSurvey?.pdfLink}>
                        {eachSurvey?.fileName}
                      </a>
                    </li>
                  );
                })}
              </ol>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LargeTask;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./create-job.styles.scss";
import { createNewJob2 } from "../../firebase/firebase.utils";
import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroButton from "../retro/button/retro-button.component";
import Loading from "../retro/loading/loading.component";
import Step1 from "./step1/step1.component";
import Step2 from "./step2/step2.component";
import Step3 from "./step3/step3.component";
import Button from "@mui/material/Button";
import { Dialog,Grid } from "@mui/material";
import { useTheme} from "@mui/material/styles";
const CreateJob = ({ setCreateJob, activeOrgId }) => {
  const history = useHistory();
  const [jobName, setJobName] = useState("Enter Job name");
  const [jobId, setJobId] = useState("");
  const [force, setForce] = useState(0);
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState({
    step1: true,
    step2: false,
    step3: false,
  });
  const [statusType, setStatusType] = useState({
    "to do": {
      id: "to do",
      name: "to do",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#34b5e4",
      open: true,
    },
    "on it": {
      id: "on it",
      name: "on it",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#FDAB3D",
      open: true,
    },
    stuck: {
      id: "stuck",
      name: "stuck",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#e2445c",
      open: true,
    },
    done: {
      id: "done",
      name: "done",
      taskIds: [],
      color: "rgb(234 236 239)",
      fontColor: "#05ce91",
      open: true,
    },
  });

  const [statusOrder, setStatusOrder] = useState(["to do", "stuck", "done"]);

  useEffect(() => {
    let keys = Object.keys(statusType);
    setStatusOrder(keys);
  }, [statusType, setStatusType, force, setForce]);

  const [modules, setModules] = useState([
    { name: "CreatedBy", active: false, icon: "faUser" },
    { name: "Assign", active: true, icon: "faUserCheck" },
    { name: "Status", active: false, icon: "faInfoCircle" },
    { name: "CreatedDate", active: false, icon: "faCalendarCheck" },
    { name: "StartDate", active: true, icon: "faCalendarAlt" },
    { name: "Deadline", active: true, icon: "faCalendarAlt" },
    { name: "DaysLeft", active: true, icon: "faHourglassHalf" },
    { name: "Priority", active: true, icon: "faExclamationCircle" },
  ]);
  const theme = useTheme();
  const handleCreate = async () => {
    setLoading(true);
    const jobId = await createNewJob2(
      activeOrgId,
      jobName,
      statusType,
      statusOrder,
      modules
    );
    setTimeout(() => {
      setLoading(false);
      setCreateJob(false);
      if (!jobId) return;
      history.push(`/s/${activeOrgId}/e/${jobId}/b`);
    }, 300);
  };

  return (
    <div className="createJob">
      <Dialog onClose={() => setCreateJob(false)} open={true} PaperProps={{
      style: {
          backgroundColor: theme.palette.background.light
          ,
      },
    }} >
        <Grid margin={4}>
          {loading && <Loading />}
          <div className="cs__createPop">
            {steps.step1 && (
              <Step1
                jobName={jobName}
                setJobName={setJobName}
                setSteps={setSteps}
              />
            )}
            {steps.step2 && (
              <Step2
                setSteps={setSteps}
                statusType={statusType}
                setStatusType={setStatusType}
              />
            )}
            {steps.step3 && (
              <>
                <Step3
                  setSteps={setSteps}
                  modules={modules}
                  setModules={setModules}
                  setForce={setForce}
                  force={force}
                />

                <div className="cs__create-btn">
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ color: theme.palette.text.main, borderColor: "black", mr: 1,backgroundColor:theme.palette.action.active }}
                    onClick={() =>
                      setSteps({
                        step3: false,
                        step2: true,
                      })
                    }
                  >
                    back
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ color: theme.palette.text.main, borderColor: "black",backgroundColor:theme.palette.action.active}}
                    onClick={() => handleCreate()}
                  >
                    create
                  </Button>
                </div>
              </>
            )}
          </div>
        </Grid>
      </Dialog>
    </div>
  );
};

export default CreateJob;

import React, {useEffect, useState} from "react";
import {Route, useHistory} from "react-router-dom";
import {db} from "../../firebase/firebase.utils";
import Board from "../board/board.component";
import List from "../list/list.component";
import JobMenu from "../../components/create-job/job-menu/job-menu.component";
import "./enter-job.stayles.scss";
import Timeline from "../timeline/timeline.component";
import Calendar from "../calendar/calendar.component";
import {setActiveModules, setCurrentJobData, setJobData, setModules} from "../../redux/org/org.actions";
import {setJobId, setOrgId} from "../../redux/history/history.actions";
import {useDispatch} from "react-redux";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import {useSelector} from "react-redux";
const EnterJob = () => {
  const history = useHistory();
  const currentOrgId = history.location.pathname.split("/")[2];
  const currentJobId = history.location.pathname.split("/")[4];
  const [job, setJob] = useState([]);
  const activeOrgId = history.location.pathname.split("/")[2];
  const activeJobId = history.location.pathname.split("/")[4];
  const dispatch = useDispatch()
  const theme = useTheme();
  useEffect(() => {
    if (activeOrgId) {
      dispatch(setOrgId(activeOrgId));
    }
  }, [activeOrgId]);

  useEffect(() => {
    if (!currentOrgId) return;
    if (!currentJobId) return;
    db.collection("org")
      .doc(currentOrgId)
      .collection("jobs")
      .doc(currentJobId)
      .collection("tasks")
      .doc("tasks")
      .onSnapshot((jobData) => {
        setJob(jobData.data());
      });
      db.collection("org")
      .doc(currentOrgId)
      .collection("jobs")
      .doc(currentJobId)
      .onSnapshot((jobData) => {
        dispatch(setCurrentJobData(jobData.data()));
      });

  }, [currentOrgId, currentJobId]);


  useEffect(() => {
    if (activeJobId) {
      db.collection("org")
        .doc(activeOrgId)
        .collection("jobs")
        .doc(activeJobId)
        .collection("modules")
        .doc("modules")
        .onSnapshot((doc) => {
          if (doc.exists) {
            const modules = doc.data().modules;
            const filterData = modules.filter((item) => item.active === true);
            dispatch(setModules(modules));
            dispatch(setActiveModules(filterData));
          }
        });
      dispatch(setJobId(activeJobId));
    }
  }, [activeJobId, activeOrgId, dispatch]);

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ width: '100%', height: '100vh' }}>
    <div className="enter" >
      <div className="enterJob">
        <Route path="/s/:id/e/:id">
          <JobMenu/>
          </Route>
        <Route
          exact
          path="/s/:id/e/:id/b"
          render={() => <Board job={job}/>}
        />
        <Route
          exact
          path="/s/:id/e/:id/l"
          render={() => <List job={job}/>}
        />
        <Route
          exact
          path="/s/:id/e/:id/t"
          render={() => <Timeline job={job} currentOrgId={currentOrgId} currentJobId={currentJobId}/>}
        />

        <Route
          exact
          path="/s/:id/e/:id/c"
          render={() => <Calendar job={job}/>}
        />
      </div>
        </div>
        </Box>
      </ThemeProvider>
  );
};

export default EnterJob;

import orgActionTypes from "./org.types";

export const setOrgData = (orgData) => ({
  type: orgActionTypes.SET_ORG_DATA,
  payload: orgData,
});

export const removeOrgData = () => ({
  type: orgActionTypes.REMOVE_ORG_DATA,
  payload: null,
});

export const setJobData = (jobData) => ({
  type: orgActionTypes.SET_JOB_DATA,
  payload: jobData,
});
export const setCurrentJobData = (jobData) => ({
  type: orgActionTypes.SET_CURRENT_JOB_DATA,
  payload: jobData,
});

export const setJobSurveys = (jobSurveys) => ({
  type: orgActionTypes.SET_JOB_SURVEYS,
  payload: jobSurveys,
});

export const setJobTrainings = (jobTrainings) => ({
  type: orgActionTypes.SET_JOB_TRAININGS,
  payload: jobTrainings,
});

export const removeOneOrg = (orgId) => ({
  type: orgActionTypes.REMOVE_ONE_ORG,
  payload: orgId,
});

export const setModules = (moduleData) => ({
  type: orgActionTypes.SET_MODULES,
  payload: moduleData,
});

export const setActiveModules = (moduleData) => ({
  type: orgActionTypes.SET_ACTIVE_MODULES,
  payload: moduleData,
});

export const setStatusType = (statusType) => ({
  type: orgActionTypes.SET_STATUS_TYPE,
  payload: statusType,
});

export const logOut = () => ({
  type: orgActionTypes.LOG_OUT,
});

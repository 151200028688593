import * as React from 'react'
import Chart from 'react-apexcharts'
import {Box, Typography} from "@mui/material";


export default class ChartLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [74, 25],
      options: {
        showTooltips: false,
        dataLabels: {
          enabled: false,
          show: false,
        },
        legend: {
          enabled: false,
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              size: '90%',
              labels: {
                show: true,
                total: {
                  show: true,
                  label: '',
                  formatter: () => '70% of work completed'
                }
              }
            }
          }
        },
        stroke: {
          colors: ['transparent'],
        },
        colors: ['#efefef', 'transparent'],
        chart: {
          type: 'donut',
          width: '10px',
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 50
            },
          }
        }]
      },


    };
  }

  render() {
    return (
      <Box id="chart" sx={{px: 5}}>
        <Chart style={{marginLeft: '50px'}} options={this.state.options} series={this.state.series} type="donut"/>
      </Box>
    );
  }
}


// import {Doughnut} from 'react-chartjs-2';
// import * as React from 'react'
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
//   PointElement,
//   LineElement,
//   ArcElement,
//   defaults
// } from 'chart.js';
// import {Box} from "@mui/material";
//
// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   PointElement,
//   LineElement,
//   ArcElement,
//   Title,
//   Tooltip,
//   Legend,
// );
//
//
// export default function ChartLine() {
//
//
//   const data = {
//     datasets: [{
//       data: [350, 100],
//       backgroundColor: [
//         'white',
//         'transparent'
//       ],
//       borderColor: [
//         'white',
//         'transparent'
//       ],
//       borderWidth: 1
//     }]
//   }
//
//   const options = {
//     maintainAspectRatio: false,
//     plugins: {
//       tooltip: {
//         enabled: false,
//       }
//     },
//     cutout: '90%',
//     responsive: true,
//     scales: {
//       yAxes: [{
//         ticks: {beginAtZero: true}
//       }]
//     }
//   }
//
//   const centerText = {
//     display: true,
//     text: "280"
//   }
//
//   return (
//     // <Box style={{position: 'absolute', height: '50vh', width: '50vw'}}>
//     <Doughnut
//       style={{position: 'absolute', height: '50vh', width: '50vw'}}
//       type="doughnut"
//       data={data}
//       options={options}
//       text={centerText}
//     />
//     // </Box>
//   )
// }

import { useSelector } from "react-redux";
import { db } from "../firebase/firebase.utils";

const useCurrentMembers = async () => {
  const orgData = useSelector((state) => state.org.orgData);
  const activeOrgId = useSelector((state) => state.history.orgId);
  if (!orgData || !orgData[0]?.members) return;
  const data = orgData.filter((data) => data.orgId === activeOrgId);
  if (!data || !data[0]) {
    return [];
  }

  const members = data[0].members;
  let listDocs = [];
  const ref = db.collection("users").where("uid", "in", members);
  const docs = await ref.get();
  docs.forEach((doc) => {
    listDocs.push(doc.data());
  });
  return listDocs;
};

export default useCurrentMembers;

import {Button} from "ui-neumorphism";
import {Subtitle1, Subtitle2, Card, H4, H6, ListItem, ListItemGroup} from 'ui-neumorphism'
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import RetroButton from "../retro/button/retro-button.component";
import CreateJob from "../create-job/create-job.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faPlusSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./surveys-list.styles.scss"
import {message, Modal, Popconfirm} from "antd";
import SurveyCreator from "../survey-creator/servey-creator";
import {addSurveyToJob, db} from "../../firebase/firebase.utils";
import {setJobSurveys} from "../../redux/org/org.actions";
import AddIcon from "@mui/icons-material/Add";
import {Box, Chip, Typography} from "@mui/material";
import { useTheme, ThemeProvider } from "@mui/material/styles";
function SurveysList({activeOrgData, isAdmin}) {
  const orgId = useSelector((state) => state.history.orgId);
  const surveysData = useSelector((state) => state.org.surveysData);
  const [recentJob, setRecentJob] = useState(null);
  const [createSurvey, setCreateSurvey] = useState(false);
  const [currentSurvey, setCurentSurvey] = useState(null);
  const theme= useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!orgId) {
      return
    }
    db.collection("org")
      .doc(orgId)
      .collection("surveys")
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({...doc.data(), id: doc.id});
        });
        dispatch(setJobSurveys(docs));
      });
  }, [orgId]);

  const onSurveySave = (result) => {
    // edit survey
    if (currentSurvey) {
      let title = JSON.parse(result)?.title;

      if (!title) {
        message.error("Please add title!");
        return;
      }

      db.collection("org")
        .doc(orgId)
        .collection("surveys")
        .doc(currentSurvey.id)
        .update({
          surveyData: result,
          title,
        })
        .then((res) => {
          setCreateSurvey(false);
          message.success("Survey Saved!");
        });
      return;
    }

    if (!(result.length > 0)) return;

    let title = JSON.parse(result)?.title;

    if (!title) {
      message.error("Please add title!");
      return;
    }

    addSurveyToJob(title, result, orgId).then((res) => {
      setCreateSurvey(false);
      message.success("Survey Added!");
    });
  };

  const onDeleteSurvey = (surveyId) => {
    db.collection("org")
      .doc(orgId)
      .collection("surveys")
      .doc(surveyId)
      .delete()
      .then(() => message.success("Survey deleted!"));
  };

  return (
    <ThemeProvider theme={theme}>
    <div className="recentSurveys">
      <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center',color: theme.palette.text.primary}}>
          <Typography variant="h5" sx={{color: theme.palette.text.primary}}>Surveys</Typography>
        <Chip
          sx={{marginRight: 1, color: theme.palette.text.primary, borderColor: theme.palette.text.primary,backgroundColor: theme.palette.action.active}}
          label="Add Survey"
          color="primary"
          variant="contained"
          icon={<AddIcon sx={{fontSize: 15}}/>}
          mode="create"
          onClick={() => setCreateSurvey(true)}
          
        />
      </Box>
      <div className="surveyList" style={{overflowY: 'scroll', height: '150px'}}>
        {surveysData?.length < 1 ? (
          <div className="recentSurveys__noSurveys" style={{color: theme.palette.text.primary}}>
            <div className="recentSurveys__msg" >
              <Typography style={{color: theme.palette.text.primary}}>No Surveys!</Typography>
            </div>
          </div>
        ) : (
          surveysData?.map((data) => {
            return (
              <div
                // style={{overflowY: 'scroll', height: '40px'}}
                onClick={() => {
                  setCurentSurvey(data);
                  setCreateSurvey(true);
                }}
                className="su__item"
                key={data.id}
              >
                {/* <Link to={`/s/${activeOrgData.orgId}/e/${data.jobId}/b`}> */}
                <Box >
                <button style={{backgroundColor: theme.palette.action.task}}>
                  <div className="survey-btn-inner-container" style={{color: theme.palette.text.primary}}>
                    {data.title}
                    {isAdmin && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <Popconfirm
                          title="Are you sure to delete this survey?"
                          onConfirm={() => onDeleteSurvey(data.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <FontAwesomeIcon icon={faTrash}/>
                        </Popconfirm>
                      </div>
                    )}
                  </div>
                  </button>
                  </Box>
                {/* </Link> */}
              </div>
            );
          })
        )}
      </div>
      {createSurvey && (
        <Modal
          onCancel={() => setCreateSurvey(false)}
          footer={null}
          className="surveyjsmodal"
          visible={true}
        >
          <SurveyCreator
            currentSurvey={currentSurvey}
            onSurveySave={onSurveySave}
          />
        </Modal>
      )}
      </div>
      </ThemeProvider>
  );
}

export default SurveysList;

import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {auth, registerUserFb} from "../../firebase/firebase.utils";
import Input from "../retro/input/input.component";
import RetroButton from "../retro/button/retro-button.component";
import {setCurrentUser} from "../../redux/user/user.actions";
import "./register.styles.scss";
import Loading from "../retro/loading/loading.component";
import {Box, Typography} from "@mui/material";
import Button from '@mui/material/Button';


const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const signInUrl = history.location.pathname.split("/")[1];

  const register = async (e) => {
    e.preventDefault();
    if (password !== repeatPassword) {
      setErrorMessage("passwords don't match");
      return;
    }
    try {
      const {user} = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      console.log(userName);
      setShowLoading(true);
      await registerUserFb(user, userName);
      setShowLoading(false);
      dispatch(setCurrentUser(user));
      if (signInUrl === "signin") {
        history.push("/");
      }
    } catch (error) {
      console.log(error.message);
      setErrorMessage(error.message);
    }
  };

  return (
    <Box className="register">
      {showLoading && <Loading/>}
      <Box className="header2">
        <Typography variant="h2" sx={{fontSize: 35, mb: 3, color: 'black'}}>Sign Up</Typography>
      </Box>
      <form>
        <input onChange={(e) => setEmail(e.target.value)} type="email" placeholder="email"/>
        <input onChange={(e) => setUserName(e.target.value)} type="text" placeholder="Username"/>
        <input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password"/>
        <input onChange={(e) => setRepeatPassword(e.target.value)} type="password" placeholder="Repeat Password"/>
        <Button
          onClick={(e) => register(e)}
          type="submit"
          style={{width: "400px"}}
        >
          <Typography sx={{color: 'black'}}>Register</Typography>
        </Button>
      </form>
      <Box className="register__error">
        <Typography>{errorMessage}</Typography>
      </Box>
    </Box>
  );
};

export default Register;

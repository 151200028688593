import React from "react";

import BoxLayerLite from "../box-layer-lite/box-layer-lite.component";

import "./mini-menu.styles.scss";
import { ThemeProvider, useTheme } from "@mui/material/styles";
const MiniMenu = ({ children, setLayer }) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      
      <div className="miniMenu" style={{ color: theme.palette.text.primary, backgroundColor: theme.palette.action.active}} >
      <BoxLayerLite setLayer={setLayer}>{children}</BoxLayerLite>
        </div>
      
    </ThemeProvider>
  );
};

export default MiniMenu;

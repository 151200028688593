import React, {useState, useMemo} from "react";
import {useSelector} from "react-redux";
import {useActiveOrgData} from "../../../hooks/useActiveOrgData.hook";
import 'antd/dist/antd.css';
import {AnimatePresence} from "framer-motion";
import {Avatar} from 'antd';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faToiletPaperSlash,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";

import {
  assignMember,
  unAssign,
  updateUser,
  unAssignMember
} from "../../../firebase/firebase.utils";
import CAvatar from "../../retro/avatar/avatar.component";

// import Avatar from "../../retro/avatar/avatar.component";
import BoxLayerLite from "../../retro/box-layer-lite/box-layer-lite.component";
import Tooltip from "../../retro/tooltip/tooltip.component";

import "./assign-styles.scss";

const Assign = ({task}) => {
  const users = useSelector((state) => state.user.users);
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const orgData = useActiveOrgData();

  const [showMembers, setShowMembers] = useState(false);
  const [assignedUser, setAssignedUser] = useState([]);
  const [allMembers, setAllMembers] = useState([]);

  useMemo(() => {
    if (!users) return;
    if (orgData) {
      const {members} = orgData;
      let list = [];
      list = members.map((memberId) => {
        const memberFind = users.filter((item) => item.uid === memberId);
        return memberFind[0];
      });
      setAllMembers(list);
    }
  }, [orgData, users]);

  useMemo(() => {
    if (!users) return;
    const {assign} = task;
    const getAssignUser = users.filter((item) => Array.isArray(assign) && assign?.includes(item.uid));
    setAssignedUser(getAssignUser);
  }, [task, users]);

  const handleAssignMember = (e, userId) => {

    if (e.target.checked) {
      let findUser = users.filter((user) => user.uid === userId);
      let theUser = findUser[0];
      task.assign = Array.isArray(task.assign) ? [...task.assign, userId] : [].push(userId + '')
      theUser.assignedTasks.push(task);
      //za db.users

      updateUser(theUser.uid, theUser);
      //za db.tasks
      assignMember(orgId, jobId, task.id, userId);
    } else {
      let oldAssign = users.filter((user) => user.uid === userId);
      let oldUser = oldAssign[0];
      if (oldUser !== undefined) {
        oldUser.assignedTasks = oldUser.assignedTasks?.filter(
          (item) => item.id !== task.id
        );
        task.assign = Array.isArray(task.assign) ? task.assign?.filter(it => it !== userId) : []
        updateUser(oldUser.uid, oldUser);
        unAssignMember(orgId, jobId, task.id, userId);
      }
    }

    setShowMembers(false);

  };

  const handleUnAssignMember = () => {
    unAssign(orgId, jobId, task.id);
    setShowMembers(false);
  };
  return (
    <div className="assign">
      <div
        className="assign__assigned"
        onClick={() => setShowMembers(!showMembers)}
      >
        <Avatar.Group maxCount={1} maxStyle={{color: '#f56a00', backgroundColor: '#fde3cf'}}>
          {assignedUser?.length > 0 ? assignedUser.map(member => {
              return <Avatar src={member.imageUrl} key={member.id}/>
            })
            :
            <CAvatar src={""}/>
          }
        </Avatar.Group>
        {/* <Avatar src={assignedUser?.length > 0 ? assignedUser[0].imageUrl : ""} /> */}
        {assignedUser?.length > 0 ? (
          <Tooltip text={assignedUser[0].userName}/>
        ) : (
          <Tooltip text="Assign member"/>
        )}
      </div>
      <AnimatePresence>
        {showMembers && (
          <div className="assign__choose">
            <BoxLayerLite setLayer={setShowMembers}>
              {allMembers?.map((member) => {
                const {imageUrl, userName, uid} = member;
                return (
                  <div
                    className="assign__member"
                    key={uid}
                  >
                    <input checked={task?.assign?.includes(uid)} onClick={(e) => handleAssignMember(e, uid)}
                           type="checkbox" className="assign-checkbox"/>
                    <CAvatar src={imageUrl}/>
                    <p>{userName}</p>
                  </div>
                );
              })}
              <div
                className="assign__remove"
                onClick={() => handleUnAssignMember()}
              >
                <FontAwesomeIcon icon={faUserSlash}/>
              </div>
            </BoxLayerLite>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Assign;

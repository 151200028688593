import { combineReducers } from "redux";

import orgReducer from "./org/org.reducer";
import userReducer from "./user/user.reducer";
import historyReducer from "./history/history.reducer";
import taskReducer from "./tasks/tasks.reducer";
import filterReducer from "./filter/filter.reducer";
import { storeReducer } from "./store/reducer";

const rootReducer = combineReducers({
  org: orgReducer,
  user: userReducer,
  history: historyReducer,
  tasks: taskReducer,
  filter: filterReducer,
  store: storeReducer,
});

export default rootReducer;

import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from 'react-router-dom'
import {toggleTime} from "../../../redux/filter/filter.actions";

import "./time-filter.styles.scss";
import {ThemeProvider, useTheme } from "@mui/material/styles";
const TimeFilter = ({time}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
    <div className="timeFilter" style={{marginRight: '10px', color: theme.palette.text.primary}}>
      <div
        className={`timeFilter-today timeFilter-item ${
          time === 0 && "timeFilter__active"
            }`}
        
        onClick={() => dispatch(toggleTime(0))}
      >
        <p style={{color: theme.palette.text.primary}}>Today</p>
      </div>
      <div
        className={`timeFilter-tomorrow timeFilter-item ${
          time === 1 && "timeFilter__active"
            }`}
        
        onClick={() => dispatch(toggleTime(1))}
      >
        <p style={{color: theme.palette.text.primary}}>Tomorrow</p>
      </div>
      <div
        className={`timeFilter-week timeFilter-item ${
          time === 7 && "timeFilter__active"
            }`}
        
        onClick={() => dispatch(toggleTime(7))}
      >
        <p style={{color: theme.palette.text.primary}}>Week</p>
      </div>
      </div>
      </ThemeProvider>
  );
};

export default TimeFilter;

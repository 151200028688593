import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import RetroButton from "../retro/button/retro-button.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTasks} from "@fortawesome/free-solid-svg-icons";
import "./job-item.styles.scss";
import { useTheme } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { useHistory } from "react-router";
import { setJobId } from "../../redux/history/history.actions";
import {useDispatch} from "react-redux";
function JobItem({ data }) {
  const history = useHistory();
  const activeOrgId = useSelector((state) => state.history.orgId);
  const currentJobId = useSelector((state) => state.history.jobId);
  const theme = useTheme();
  const dispatch = useDispatch();
  return (
    <div className="jobItem" style={{color: theme.palette.text.primary }}>
      <Link to={`/s/${activeOrgId}/e/${data.jobId}/b`}>
      {/* <Button onClick={() => dispatch(setJobId(data.jobId))} sx={{justifyContent:"left"}}> */}
        <Typography style={{ color: theme.palette.text.primary, fontSize:16,fontWeight: 'bold', marginLeft: 5}}>{data.name}</Typography>
        </Link>
        {/* </Button> */}
    </div>
  );
}

export default JobItem;

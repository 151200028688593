import { Button } from 'ui-neumorphism';
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import "./mobile-menu.styles.scss";
import UserProfile from "../../components/user-profile/user-profile.component";
import {ThemeProvider, useTheme } from "@mui/material/styles";
import {
  faChevronLeft,
  faClipboard,
  faHome,
  faPaperPlane,

} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RetroButton from "../retro/button/retro-button.component";




import { useDispatch, useSelector } from "react-redux";
import { db } from "../../firebase/firebase.utils";
import { motion } from "framer-motion";

import Avatar from "../../components/retro/avatar/avatar.component";
import OrgFly from "../../components/org-fly/org-fly.component";

import { setOrgData, removeOrgData } from "../../redux/org/org.actions";
import { setLoading } from "../../redux/history/history.actions";
import { setOpen } from "../../redux/user/user.actions";


const MobileMenu = () => {
  const [homePage, setHomePage] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentUser = useSelector((state) => state.user.currentUser);
  const orgId = useSelector((state) => state.history.orgId);
  const open = useSelector((state) => state.user.currentUser.open);
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    if (location.pathname !== "/") {
      setHomePage(true);
    } else {
      setHomePage(false);
    }
  }, [location]);

  useEffect(() => {
    db.collection("org")
      .where("members", "array-contains", currentUser.uid)
      /* .orderBy("created", "asc") */
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let shots = [];
          snapShot.forEach((doc) => {
            shots.push(doc.data());
          });
          dispatch(setOrgData(shots));
        }
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 1000);
      });
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userDatat) => {
        const getOpen = userDatat.data().open;
        if (getOpen) {
          dispatch(setOpen(true));
        } else {
          dispatch(setOpen(false));
        }
      });
  }, []);

  return (
    <ThemeProvider theme={theme}>
    <div className="mobileMenu">
      <div className="mobilemenu" >
        <button className="backbtn" style={{backgroundColor: theme.palette.background.default,color: theme.palette.action.active}} onClick={() => history.goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button className="homebtn" style={{backgroundColor: theme.palette.background.default,color: theme.palette.action.active}}  onClick={() => history.push("/")}>
          <FontAwesomeIcon icon={faHome} />
        </button>
        {/* i did not find the path to survey and message */}
        <button className="surveybtn" style={{backgroundColor: theme.palette.background.default,color: theme.palette.action.active}}  onClick={() => history.push("/surveys")}>
          <FontAwesomeIcon icon={faClipboard} />
        </button>
        <button className="messagebtn" style={{backgroundColor: theme.palette.background.default,color: theme.palette.action.active}}  >
          {/* onClick={() => history.push("/s/:id/messaging")} */}
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
      </div>
      </div>
      </ThemeProvider>
  );
};

export default MobileMenu;

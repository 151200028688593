import React, {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {motion} from "framer-motion";
import BoxLayer from "../retro/box-layer/box-layer.component";
import RetroButton from "../retro/button/retro-button.component";
import Colors from "../colors/colors.component";
import MiniMenu from "../retro/mini-menu/mini-menu.component";
import {deleteStatusType, setTaskColor} from "../../firebase/firebase.utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFillDrip,
  faICursor,
  faTools,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./task-settings.styles.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { Button, Dialog, Grid, Typography } from "@mui/material";

const TaskSettings = ({status, inputNameRef}) => {
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const [showSettings, setShowSettings] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [color, setColor] = useState(null);
  const theme = useTheme();
  useEffect(() => {
    if (color === null) return;

    setTaskColor(orgId, jobId, status.name, color);
    setShowColors(false);
    setShowSettings(false);
  }, [color, orgId, jobId, status.name]);

  return (
    <div className="taskSettings">
      <div
        className="ts__settings"
        onClick={() => setShowSettings(!showSettings)}
      >
        <MoreHorizIcon style={{color: theme.palette.text.primary}} />
      </div>
      {showSettings && (
        <MiniMenu setLayer={setShowSettings}>
          <ul>
            <li onClick={() => setShowDelete(!showDelete)}>
              <div className="tooltip">Delete</div>
              <FontAwesomeIcon icon={faTrashAlt}/>
            </li>
            <li onClick={() => setShowColors(!showColors)}>
              <div className="tooltip">Colors</div>
              <FontAwesomeIcon icon={faFillDrip}/>
            </li>
            <li
              onClick={() => {
                inputNameRef.current.focus();
                setShowSettings(false);
              }}
            >
              <div className="tooltip" style={{color: theme.palette.text.main }}>Rename</div>
              <FontAwesomeIcon icon={faICursor}/>
            </li>
          </ul>
          {showColors && (
            <motion.div
              className="ts__changeColor"
              initial={{opacity: 0, y: -15}}
              animate={{opacity: 1, y: 1}}
            >
              <Colors returnColor={setColor}/>
            </motion.div>
          )}
        </MiniMenu>
      )}
       <Dialog onClose={() => setShowDelete(false)} open={showDelete}>
        <Grid margin={4}>
            <div className="ts__delete-msg">
              <h2>
                Delete status
                <span style={{color: status.fontColor}}> {status.name}</span>?
              </h2>
              <p>
                All task inside
                <i> {status.name} </i> status will be deleted
              </p>
            </div>
            <div className="ts__delete-btns"> 
            <Button variant="text" onClick={() => setShowDelete(false)}>
                Cancel
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={() =>
                  deleteStatusType(orgId, jobId, status.name)
                }
                style={{ marginLeft: 10 }}
              >
                <Typography sx={{ color: "white" }}>yes</Typography>
              </Button>
           
            </div>
        </Grid>
      </Dialog>
    </div>
  );
};

export default TaskSettings;

import React, {useState, useEffect} from "react";
import {motion} from "framer-motion";
import Login from "../../components/login/login.component";
import Register from "../../components/register/register.component.class";
import "./sing-in.styles.scss";
import buttonpattern from "../../img/parallax/test.jpg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "antd";

const SignIn = () => {
  const [showRegister, setShowRegister] = useState(false)
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 700;

  const image = {
    backgroundImage: `url(${buttonpattern})`,
    backgroundSize: 'cover',
  }

  return (
    <main>
      <section className="glass" style={image}>
        <div style={{overflow: "hidden", height: "100vh", width: "100%"}}>
          <div style={{
            width: "100%",
            transitionDuration: "500ms",
            transitionProperty: "margin-top",
            transitionTimingFunction: "ease",
            marginTop: showRegister ? "-100vh" : 0,
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
            <Login/>
            <Typography>
              Don't have account? <a onClick={() => setShowRegister(true)}>Join Now!</a>
            </Typography>
          </div>
          <div style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}>
            <Register/>
            <Typography>
              Already a member? <a onClick={() => setShowRegister(false)}>Login!</a>
            </Typography>
          </div>
        </div>
      </section>
    </main>
  );
}

export default SignIn;

import React, {useState, useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {auth, db, fieldValue, cloudFunctions} from "../../firebase/firebase.utils";
import {useSelector} from "react-redux";
import useCurrentMembers from "../../hooks/useCurrentMembers.hook";
import RetroButton from "../retro/button/retro-button.component";
import RetroInput from "../retro/input/input.component";
import Member from "./member/member.component";
import "./members.styles.scss";
import Checkbox from "antd/lib/checkbox/Checkbox";
import useCurrentSubAdmins from "../../hooks/useCurrentSubAdmins";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import {List, message, Typography} from "antd";
import {Chip, Avatar, Box, Button, Dialog, DialogTitle, Grid, ListItem, TextField} from "@mui/material";

const Members = ({activeOrgData, setLoading,showActions = true}) => {
  const history = useHistory();
  const activeOrgId = useSelector((state) => state.history.orgId);
  const [email, setEmail] = useState("");
  const [isAddAsAdmin, setIsAddAsAdmin] = useState(false);
  const [invite, setInvite] = useState(false);
  const [showSeats, setShowSeats] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [subAdmins, setSubAdmins] = useState([]);
  const inputRef = useRef();
  const memberPromise = useCurrentMembers();
  const subAdminsPromise = useCurrentSubAdmins();
  const [roomId, setroomId] = useState();
  const {currentUser = {}} = useSelector(state => ({currentUser: state.user.currentUser}))
  const theme = useTheme();

  useEffect(async() => { 
    db.collection("message").doc(activeOrgId).collection("rooms").onSnapshot((snapshot) => {
      if (snapshot.empty) {
        
        setroomId(activeOrgId);
      } else { 
        setroomId(snapshot.docs[0].id);
      }
      
      
    });
  },[]);

  useEffect(() => {
    if (!activeOrgData) {
      return
    }
    // console.log('activeOrgData', activeOrgData);
    memberPromise.then((data) => {
      setMemberData(data);
    });
  }, [activeOrgId, activeOrgData]);
  useEffect(() => {
    setSubAdmins(subAdminsPromise);
  }, []);



  const handleSubmit = (e) => {
    e.preventDefault();
    db.collection("users")
      .where("email", "==", email)
      .get()
      .then((doc) => {
        if (doc.empty) {
          alert("No user in database. User must be registered");
        } else {
          doc.forEach((data) => {
            db.collection("org")
              .doc(activeOrgId)
              .update({
                ...(isAddAsAdmin && {
                  subAdmins: fieldValue.arrayUnion(data.data().uid),
                }),
                members: fieldValue.arrayUnion(data.data().uid),
              })
              .then(() => {
                alert("user added");
                inputRef.current.value = "";
                setInvite(false);
                history.go(0);
                setIsAddAsAdmin(false);
              });
          });
        }
      });
  };

  const addUserAsAdmin = (uid) => {
    let alreadyAAdmin = (activeOrgData?.subAdmins || []).includes(uid);
    db.collection("org")
      .doc(activeOrgId)
      .update({
        subAdmins: alreadyAAdmin
          ? fieldValue.arrayRemove(uid)
          : fieldValue.arrayUnion(uid),
      })
      .then(() => {
        message.success(
          alreadyAAdmin ? "User removed as an admin!" : "User added as an admin"
        );
      });
  };

  const upgradePlan = () => {
    setLoading(true)
    const getStripePortalSession = cloudFunctions.httpsCallable("getStripePortalSession");
    getStripePortalSession(activeOrgData).then(res => {
      if (res.data.error) {
        setLoading(false);
        alert(res.data.message)
      } else {
        window.location.replace(res.data.session.url)
      }
    }).catch(err => {
      setLoading(false)
      console.log(err);
    })
  }

  const seatsDialog = () => (
    <Dialog  onClose={()=>setShowSeats(false)} open={showSeats}>
      <DialogTitle>Seats</DialogTitle>
      <List style={{minWidth: 300, maxHeight:600, overflowY:'scroll'}} sx={{ pt: 0 }}>
        {memberData.map((member) => (
          <ListItem button key={member.uid}>
            <Member
            key={member.uid}
            member={member}
            addUserAsAdmin={addUserAsAdmin}
            activeOrgData={activeOrgData}
            activeOrgId={activeOrgId}
            showUserName
          />
          </ListItem>
        ))}
      </List>
    </Dialog>
  )

  return (
    <ThemeProvider theme={theme}>
    <Grid container className="members" sx={{display: 'flex'}}>
      {memberData?.slice(0, 3).map((member) => {
        return (
          <Member
            key={member.uid}
            member={member}
            addUserAsAdmin={addUserAsAdmin}
            activeOrgData={activeOrgData}
            activeOrgId={activeOrgId}
            showActions={showActions}
          />
        );
      })}
      {memberData?.length > 3 && <Avatar>+ {memberData?.length - 3}</Avatar>}
      {invite && (
        <form onSubmit={handleSubmit}>
          <div
            className="members__invite"
            onChange={(e) => setEmail(e.target.value)}
          >
            <TextField
              rounded
              ref={inputRef}
              placeholder="email"
              type="email"
            />
            {((activeOrgData &&
                activeOrgData?.admin === auth.currentUser.uid) ||
              subAdmins.includes(auth?.currentUser?.uid)) && (
              <Checkbox
                checked={isAddAsAdmin}
                onChange={(e) => setIsAddAsAdmin(e.target.checked)}
                style={{marginLeft: 16}}
              >
                Add as admin
              </Checkbox>
            )}
          </div>
        </form>
      )}

      {(activeOrgData?.plan === "pro" && (activeOrgData.subscriptionQuantity + 1 > activeOrgData.members.length)) ?
        <Grid item xs={12} md={6} sx={{display: 'flex', alignItems: 'center'}}>
          {invite && (
            <>
              <Button sx={{ml: 1, color: 'black', borderColor: 'black', alignItems: 'center'}}
                      variant="contained" size="small" color="info"
                      onClick={() => setInvite(false)}>
                cancel
              </Button>
              <Button sx={{ml: 1, color: 'black', borderColor: 'black'}} variant="contained" size="small" color="info"
                      onClick={(e) => handleSubmit(e)}>
                Submit
              </Button>
            </>
          )}
          {!invite && (
            <Button sx={{
              ml: 2, color: theme.palette.text.main, borderColor: theme.palette.background.primary,backgroundColor: theme.palette.action.active}} variant="contained" size="small" color="info"
                    onClick={() => setInvite(true)} type="submit">
              Invite
            </Button>
          )}
          
        </Grid>
        :
        <Grid item xs={12} md={6} sx={{
          ml: 2,
          mr: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-evenly',
        }}>
          To add members, please upgrade your plan.
          <Button sx={{ml: 1, color: theme.palette.text.main, borderColor: theme.palette.background.primary,backgroundColor: theme.palette.action.active}} variant="contained"
                  onClick={() => upgradePlan()} type="submit">
            Upgrade
          </Button>
          
        </Grid>
      }

      <Grid sx={{mt: {xs: 2, xxs: 2, sm: 2, md: 0}, ml: 2, mr: 2,display: 'flex', alignItems: 'center', justifyContent:'flex-evenly', flex: 1}}>
          <Button sx={{
            
              borderColor: theme.palette.background.primary,
            color: theme.palette.text.main,
            backgroundColor: theme.palette.action.active
            }} onClick={()=>setShowSeats(true)} variant="contained">
              Seats
              
          </Button>

          <Chip
                sx={{mt: 1, marginRight: 1, ml: 1, mb:1, borderColor: theme.palette.text.primary, color:theme.palette.text.primary, backgroundColor: theme.palette.action.active}}
                label="Video and Chat"
                color="primary"
                variant="contained"
                // onClick={() => history.push(`/s/${activeOrgId}/videochat/public/${roomId}`)}
              />




      </Grid>

      {seatsDialog()}
      </Grid>
      </ThemeProvider>
  );
};

Members.defaultProps = {
  setLoading: () => {
    console.log("default");
  }
}
export default Members;

import React ,{useState, useEffect, useRef} from 'react'
import {useSelector} from "react-redux";
import Respond from '../../components/respond/respond.component';
import './message.style.scss'
import Respondinput from '../../components/respond/respondinput/respondinput.component';
import { db } from '../../firebase/firebase.utils';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import {Box,TabScrollButton,Avatar  }from '@mui/material';
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GifIcon from '@mui/icons-material/Gif';
const Message = () => {
    const currentUser = useSelector((state) => state.user.currentUser);
    const { userName } = currentUser;
    const bottomRef = useRef(null);
    const [messages, setMessage] = useState([])
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const activeOrgId = history.location.pathname.split("/")[2];
    const status = history.location.pathname.split("/")[4];
    const messageId = history.location.pathname.split("/")[5];
    const [currentRoomID, setcurrentRoomID] = useState([])
    const [currentRoomMembers, setcurrentRoomMembers] = useState([]);
    useEffect(() => {
        if (status=="private") {
            db.collection("message").doc(activeOrgId).collection("privatemessage").doc(messageId).collection("messaging").orderBy('timestamp', 'asc').onSnapshot((snapshot) => {
                setMessage(
                    snapshot.docs.map((doc) => doc.data())
                );
                setcurrentRoomID(messageId)
            });
           
        } else {
            db.collection("message").doc(activeOrgId).collection("rooms").doc(messageId).collection("members").onSnapshot((snapshot) => {
                setcurrentRoomMembers(
                    snapshot.docs.map((doc) =>doc.data().userid )
                )
            });
            if (currentRoomMembers.includes(currentUser.uid)) {
                db.collection("message").doc(activeOrgId).collection("rooms").doc(messageId).collection("messaging").orderBy('timestamp', 'asc').onSnapshot((snapshot) => {
                    setMessage(
                        snapshot.docs.map((doc) => doc.data())
                    );
                });
                setcurrentRoomID(messageId)
            }
        }
        
        
       
    }, []);
    if (messageId!=currentRoomID) {
       
        if (status=="private") {
            db.collection("message").doc(activeOrgId).collection("privatemessage").doc(messageId).collection("messaging").orderBy('timestamp', 'asc').onSnapshot((snapshot) => {
                setMessage(
                    snapshot.docs.map((doc) => doc.data())
                );
            });
           
        } else {
            db.collection("message").doc(activeOrgId).collection("rooms").doc(messageId).collection("members").onSnapshot((snapshot) => {
                setcurrentRoomMembers(
                    snapshot.docs.map((doc) =>doc.data().userid )
                )
            });
            if (currentRoomMembers.includes(currentUser.uid)) {
                db.collection("message").doc(activeOrgId).collection("rooms").doc(messageId).collection("messaging").orderBy('timestamp', 'asc').onSnapshot((snapshot) => {
                    setMessage(
                        snapshot.docs.map((doc) => doc.data())
                    );
                });
                setcurrentRoomID(messageId)
            }
        }
    }
    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'auto'});
    }, [messages]);
    
    return (
        <ThemeProvider theme={theme}>
         
        <Box sx={{ color: theme.palette.text.primary, height: "80vh"}}>
             
        <div className='message-container'>
           
                        <div className="message__headerLeft">
                        
                    <Typography className="message__name" sx={{ color: theme.palette.text.primary ,fontSize: 30}} >
                        
                    </Typography>
                   
                </div>
                {/* <Box className="message__headerRight" sx={{ color: theme.palette.text.primary }}>
                    
                    <Link to={`/s/:id/videocall`}>
                    <FontAwesomeIcon icon={faPlus} size="3x"  />
                    </Link>
                </Box> */}
           
        
         
            
                   
            <Box sx={{
                backgroundColor: theme.palette.background.lightwhite,
                display: "flex",
                flexDirection: "column", overflowY: "scroll", height: "100%"
            }}>
                    
                        {
                        messages.map((message) => (
                        <Respond
                            respond={message.respond}
                            username={message.username}
                            id={message.id}
                            imageUrl={message.imageUrl}
                            timestamp={message.timestamp}
                        />
                    ))
                    
                        }
                        <div ref={bottomRef} />
            </Box>
            
          
      
        
           
            <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", flexDirection: "column"}}>
                        <Respondinput />
                        
            
                    </Box>
                    
        
            
            </div>
        </Box>
    
        </ThemeProvider>
    );
};


export default Message
const orgActionTypes = {
  SET_ORG_DATA: "SET_ORG_DATA",
  REMOVE_ORG_DATA: "REMOVE_ORG_DATA",
  SET_JOB_DATA: "SET_JOB_DATA",
  SET_JOB_SURVEYS: "SET_JOB_SURVEYS",
  SET_JOB_TRAININGS: "SET_JOB_TRAININGS",
  REMOVE_ONE_ORG: "REMOVE_ONE_ORG",
  SET_MODULES: "SET_MODULES",
  SET_ACTIVE_MODULES: "SET_ACTIVE_MODULES",
  SET_STATUS_TYPE: "SET_STATUS_TYPE",
  LOG_OUT: "LOG_OUT",
  SET_OPEN: "SET_OPEN",
  SET_STATE: "SET_STATE",
  SET_CURRENT_JOB_DATA: 'SET_CURRENT_JOB_DATA'
};

export default orgActionTypes;

import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RetroButton from "../retro/button/retro-button.component";
import AttemptSurvey from "../survey-attempt/servey-attempt";
import * as SurveyPDF from "survey-pdf";
import { storage } from "../../firebase/firebase.utils";
import Resizer from "react-image-file-resizer";

function DoSurvey({
  orgId,
  surveyId,
  task,
  saveTaskSurveyPDF,
  buttonJSX = null,
  currentSurveyData = null,
}) {
  const [openSurvey, setOpenSurvey] = useState(false);
  const [loading, setLoading] = useState(false);
  const surveysData = useSelector((state) => state.org.surveysData);
  const currentUser = useSelector((state) => state.user.currentUser);

  let currentSurvey =
    currentSurveyData || surveysData.find((s) => s.id === surveyId);

  const onComplete = (surveyResult, pdfWidth, pdfHeight) => {
    setLoading(true);
    saveSurveyToPdf(surveyResult, pdfWidth, pdfHeight).finally((res) => {
      setLoading(false);
      setOpenSurvey(false);
    });
  };

  const saveSurveyToPdf = async (surveyModel, pdfWidth, pdfHeight) => {
    // compress files
    let surveyResults = surveyModel.data;
    let filePromises = [];
    await Promise.all(
      Object.entries(surveyResults).map(async ([question, questionData]) => {
        // check if it's a file
        if (Array.isArray(questionData) && questionData.length > 0) {
          await Promise.all(
            questionData.map(async (eachQuestionFile, fileIndex) => {
              if (eachQuestionFile.content && eachQuestionFile.type) {
                let fileInfo = eachQuestionFile.type.split("/");
                if (fileInfo && fileInfo[0] === "image") {
                  let fileBlob = await fetch(eachQuestionFile.content).then(
                    (res) => res.blob()
                  );

                  console.log("fileBlob", fileBlob);
                  filePromises.push(
                    resizeFile(
                      fileBlob,
                      question,
                      fileIndex,
                      fileInfo[1].toUpperCase()
                    ).then(
                      (res) =>
                        (surveyResults[res.questionKey][res.fileIndex].content =
                          res.content)
                    )
                  );
                }
              }
            })
          );
        }
      })
    );

    await Promise.all(filePromises);

    var options = {
      fontSize: 14,
      margins: {
        left: 10,
        right: 10,
        top: 10,
        bot: 10,
      },
      haveCommercialLicense: true,
      format: [pdfWidth, pdfHeight],
    };

    let jsonFileRef = storage.ref(`surveys/${orgId}-${surveyId}.json`);

    let surveyJSOnUrl = await jsonFileRef.getDownloadURL();

    let blogJSONFile = await new Promise((resolve, reject) => {
      // `url` is the download URL for 'images/stars.jpg'

      // This can be downloaded directly:
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = (event) => {
        let blob = xhr.response;
        resolve(blob);
      };
      xhr.open("GET", surveyJSOnUrl);
      xhr.send();
    });

    let surveyData = JSON.parse(await blogJSONFile.text());

    var surveyPDF = new SurveyPDF.SurveyPDF(surveyData, { ...options });

    surveyPDF.haveCommercialLicense = true;

    surveyPDF.data = surveyModel.data;

    console.log("surveyModel.data", surveyModel.data);

    let fileText = await surveyPDF.raw("blob");

    let fileName = `${currentUser?.userName}-${new Date().toDateString()}-${
      task?.content
    }`;

    let resultsWithSameName =
      task?.surveyResults?.filter((eachResult) =>
        eachResult.fileName.includes(fileName)
      ) || null;
    if (resultsWithSameName) {
      fileName = fileName + `(${resultsWithSameName.length})`;
    }

    const storageRef = await storage
      .ref(`/survey-results/${fileName}.pdf`)
      .put(fileText);

    const fileUrl = await storageRef.ref.getDownloadURL();

    saveTaskSurveyPDF(fileName, fileUrl);
  };

  const resizeFile = (file, questionKey, fileIndex, imageType) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        400,
        350,
        imageType,
        90,
        0,
        (uri) => {
          resolve({ content: uri, questionKey, fileIndex });
        },
        "base64",
        250,
        200
      );
    });

  return (
    <>
      {buttonJSX ? (
        <div onClick={() => setOpenSurvey(true)}>{buttonJSX}</div>
      ) : (
        <RetroButton onClick={() => setOpenSurvey(true)}>Do Survey</RetroButton>
      )}

      {openSurvey && (
        <Modal
          closeIcon={() => null}
          close
          footer={null}
          className="surveyjsmodal"
          visible={true}
        >
          {loading ? (
            "Saving..."
          ) : (
            <AttemptSurvey
              json={
                currentSurvey?.surveyData &&
                JSON.parse(currentSurvey?.surveyData)
              }
              onComplete={onComplete}
            />
          )}
        </Modal>
      )}
    </>
  );
}

export default DoSurvey;

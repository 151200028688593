import React, {Component} from "react";
import * as SurveyJSCreator from "survey-creator";
import "survey-creator/survey-creator.css";

SurveyJSCreator.StylesManager.applyTheme("modern");

class SurveyCreator extends Component {
  surveyCreator;

  componentDidMount() {
    let options = {showEmbededSurveyTab: false, haveCommercialLicense: true};
    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null, options);
    this.surveyCreator.saveSurveyFunc = this.saveMySurvey;
    if (this.props.currentSurvey) {
      this.surveyCreator.text = this.props.currentSurvey?.surveyData;
    }
    this.surveyCreator.render("surveyCreatorContainer");
  }

  render() {
    return (
      <div>
        <script type="text/html" id="custom-tab-survey-templates">
          {`<div id="test">TEST</div>`}
        </script>

        <div id="surveyCreatorContainer"/>
      </div>
    );
  }

  // Implement FIREBASE in saveMySurvey
  saveMySurvey = () => {
    // console.log(JSON.stringify(this.surveyCreator.text));
    this.props?.onSurveySave(this.surveyCreator.text);
  };
}

export default SurveyCreator;

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  db,
  saveTaskSurveyPDF,
  updateUser,
} from "../../firebase/firebase.utils";
import { setOrgData } from "../../redux/org/org.actions";
import { setOpen } from "../../redux/user/user.actions";
import {
  Box,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { Grid,Card } from "@mui/material";
import { useHistory } from "react-router-dom";
import "./survey-list.styles.scss";
import DoSurvey from "../../components/large-task/DoSurvey";
import { useTheme, ThemeProvider } from "@mui/material/styles"; 
const Surveys = () => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [surveysList, setSurveysList] = useState([]);
  const [surveysData, setSurveysData] = useState(null);
  const theme = useTheme();
  
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 700;

  useEffect(() => {
    db.collection("org")
      .where("members", "array-contains", currentUser.uid)
      /* .orderBy("created", "asc") */
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let shots = [];
          snapShot.forEach((doc) => {
            shots.push(doc.data());
          });
          dispatch(setOrgData(shots));
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userData) => {
        const getOpen = userData.data().open;
        if (getOpen) {
          dispatch(setOpen(true));
        } else {
          dispatch(setOpen(false));
        }

        let sortedTasks = userData
          .data()
          .assignedTasks.sort(
            (a, b) => a?.deadline?.toDate() - b?.deadline?.toDate()
          );
        setAssignedTasks(sortedTasks);
      });
  }, []);

  useEffect(() => {
    const loadSurveys = async () => {
      let surveysList = [];

      let orgIds = assignedTasks?.reduce((orgIds, task) => {
        if (!orgIds.includes(task?.fromOrgId)) orgIds.push(task?.fromOrgId);
        return orgIds;
      }, []);

      Promise.all(
        orgIds.map((orgId) =>
          db
            .collection("org")
            .doc(orgId)
            .collection("surveys")
            .get()
            .then((res) => res.docs)
        )
      )
        .then((orgsSurveys) => {
          let surveysData = orgsSurveys.reduce(
            (surveysData, eachOrgSurveys) => {
              eachOrgSurveys?.map((eachSurvey) => {
                surveysData[eachSurvey.id] = eachSurvey.data();
              });
              return surveysData;
            },
            {}
          );

          setSurveysData(surveysData);
        })
        .catch(console.log);

      await Promise.all(
        assignedTasks?.map(async (task) => {
          const { fromOrgId, fromJobId, id, assign } = task;

          const taskRef = db
            .collection("org")
            .doc(fromOrgId)
            .collection("jobs")
            .doc(fromJobId)
            .collection("tasks")
            .doc("tasks");

          const getTaskData = await taskRef.get();

          if (getTaskData.data() === undefined) {
            if (assign === null) {
              console.log("its null");
            }
            const getUserData = await db
              .collection("users")
              .doc(currentUser.uid)
              .get();
            const userData = getUserData.data();
            userData.assignedTasks = userData.assignedTasks.filter(
              (task) => task.id !== id
            );
            updateUser(currentUser.uid, userData);
            return;
          }

          const taskData = getTaskData.data().tasks;
          const theTask = taskData[id];

          if (theTask?.surveyId) {
            surveysList.push(theTask);
          }

          if (theTask === undefined) {
            if (assign) {
              if (Array.isArray(assign))
                await Promise.all(
                  assign?.map(async (assign) => {
                    const getUserData = await db
                      .collection("users")
                      .doc(assign)
                      .get();
                    const userData = getUserData.data();
                    userData.assignedTasks = userData.assignedTasks.filter(
                      (task) => task.id !== id
                    );
                    updateUser(assign, userData);
                  })
                );

              return;
            }
          }

          if (
            Array.isArray(theTask.assign) &&
            theTask.assign?.some(
              (it) => Array.isArray(assign) && assign.includes(it)
            )
          ) {
            //but are you a member ?
            if (Array.isArray(assign))
              await Promise.all(
                assign?.map(async (assign) => {
                  const getUserData = await db
                    .collection("users")
                    .doc(assign)
                    .get();
                  const userData = getUserData.data();
                  if (!userData) return;
                  db.collection("org")
                    .doc(fromOrgId)
                    .get()
                    .then((docOrgData) => {
                      if (docOrgData.exists) {
                        const orgData = docOrgData.data();
                        if (orgData.members.includes(userData.uid)) {
                          console.log("its ok ur a member");
                        } else {
                          console.log("your not even a member i see");
                          db.collection("users")
                            .doc(assign)
                            .get()
                            .then((user) => {
                              const userData = user.data();
                              userData.assignedTasks =
                                userData.assignedTasks.filter(
                                  (task) => task.id !== id
                                );
                              updateUser(assign, userData);
                            });
                        }
                      }
                    });
                  if (theTask.done === true) {
                    console.log("its not ok, have to delete task", id);
                    if (assign) {
                      const getUserData = await db
                        .collection("users")
                        .doc(assign)
                        .get();
                      const userData = getUserData.data();
                      console.log(userData);
                      userData.assignedTasks = userData.assignedTasks.filter(
                        (task) => task.id !== id
                      );
                      console.log(userData);
                      updateUser(assign, userData);
                    }
                  }
                })
              );
          } else {
            console.log("its not ok, have to delete task", id);
            if (Array.isArray(assign))
              await Promise.all(
                assign?.map(async (assign) => {
                  const getUserData = await db
                    .collection("users")
                    .doc(assign)
                    .get();
                  const userData = getUserData.data();
                  console.log(userData);
                  userData.assignedTasks = userData.assignedTasks.filter(
                    (task) => task.id !== id
                  );
                  console.log(userData);
                  updateUser(assign, userData);
                })
              );
          }
        })
      );
      setSurveysList(surveysList);
    };
    loadSurveys();
  }, [assignedTasks]);

  

  return (
    <ThemeProvider theme={theme}>
    <Container maxWidth="xl" sx={{ pb: 5,height: '100vh',pt:5 }}>
      <Stack spacing={3}>
        <Grid container>
          <Grid item xs={12} >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 2,
                mt: 2,
                alignItems: "center",
              }}
            >
              <Typography sx={{color: theme.palette.text.primary}} variant="h4">Surveys to complete</Typography>
            </Box>
            <div
              className="recentSurveys surveyList"
              style={{ overflowY: "scroll", height: "150px" }}
            >
              {surveysList?.length < 1 ? (
                <div className="recentSurveys__noSurveys">
                  <div className="recentSurveys__msg">
                    <p>No Surveys!</p>
                  </div>
                </div>
              ) : (
                surveysList?.map((data) => {
                  return (
                    <div
                      // style={{overflowY: 'scroll', height: '40px'}}
                      // onClick={() => {
                      //   setCurentSurvey(data);
                      //   setCreateSurvey(true);
                      // }}
                      
                      className="su__item"                        
                      key={data.id}
                     >
                      
                          <DoSurvey
                            saveTaskSurveyPDF={(fileName, pdfLink) =>
                              saveTaskSurveyPDF(
                                data?.fromOrgId,
                                data?.fromJobId,
                                fileName,
                                pdfLink,
                                data?.id
                              )
                            }
                            surveyId={data.surveyId}
                            task={data}
                            orgId={data?.fromOrgId}
                            
                            buttonJSX={
                              <>
                              <Card style={{ display: 'flex', alignItems: 'center', ml: 2, color: theme.palette.text.primary, backgroundColor: theme.palette.action.active, width: '20rem', height: '50px', justifyContent: 'space-evenly'}}>
                                {surveysData &&
                                  surveysData[data.surveyId]?.title}{", job-"}
                                ({data?.content})
                                </Card>
                              </>
                            }
                            currentSurveyData={surveysData[data.surveyId]}
                          />
         
                      
                    </div>
                  );
                })
              )}
            </div>
          </Grid>
        </Grid>
      </Stack>
      </Container>
      </ThemeProvider>
  );
};

export default Surveys;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import BoxLayer from "../../retro/box-layer/box-layer.component";
import RetroButton from "../../retro/button/retro-button.component";

import {
  cloudFunctions,
  deleteOrg,
  removeMember,
  updateUser,
} from "../../../firebase/firebase.utils";

import { removeOneOrg } from "../../../redux/org/org.actions";
import { Button, Dialog, DialogTitle, Grid, List, Typography } from "@mui/material";

const DeleteJob = ({ data, setLoading }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.user.currentUser);
  const orgId = useSelector((state) => state.history.orgId);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const currentUserUid = currentUser.uid;

  useEffect(() => {
    const { admin } = data;
    if (currentUserUid === admin) {
      setIsUserAdmin(true);
    } else {
      setIsUserAdmin(false);
    }
  }, [currentUserUid, data]);

  const handleDeleteOrg = () => {
    setLoading(true);
    const deleteOrganization =
      cloudFunctions.httpsCallable("deleteOrganization");
    deleteOrganization({ orgId: orgId })
      .then((res) => {
        if (res) {
          deleteOrg(orgId);
          dispatch(removeOneOrg(orgId));
          history.push("/");
        } else {
          alert("An error occured, please try again");
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        alert("An error occured, please try again");
      });
  };

  const handleLeaveOrg = () => {
    /*   let user = currentUser;
    let assignedArray = user.assignedTasks;
    let newUser = {};

    // check if got tasks
    const findFromThisOrg = assignedArray.filter(
      (item) => item.fromOrgId === orgId
    );
    console.log(findFromThisOrg);

    if (findFromThisOrg.length > 0) {
      alert(
        `you have ${findFromThisOrg.length} task assigned to you in this Org. Delete thouse tasks or unAssign your self`
      );
      return;
    }

    // remove assigned thigns
    if (assignedArray.length > 0) {
      let removed = assignedArray.filter(
        (item) => item.fromOrgId !== orgId
      );
      newUser = {
        ...user,
        assignedTasks: [...removed],
      };
    }
    // remove favorite things
    if (user.favoriteJobs.length > 0) {
      user.favoriteJobs = user.favoriteJobs.filter(
        (staion) => staion.fromOrgId !== orgId
      );
      newUser = {
        ...user,
        favoriteJobs: [...user.favoriteJobs],
      };
    }

    // update tasks

    updateUser(newUser.uid, newUser); */
    removeMember(orgId, currentUser.uid);
    dispatch(removeOneOrg(orgId));
    history.push("/");
  };

  return (
    <>
      {isUserAdmin ? (
        <li onClick={() => setShowAction(!showAction)}>
          <div className="tooltip">Delete</div>
          <FontAwesomeIcon icon={faTrashAlt} />
        </li>
      ) : (
        <li onClick={() => setShowAction(!showAction)}>
          <div className="tooltip">Leave</div>
          <FontAwesomeIcon icon={faSignOutAlt} />
        </li>
      )}
      <Dialog onClose={() => setShowAction(false)} open={showAction}>
        <Grid margin={4}>
        {!isUserAdmin ? (
          <>
            <h2>Leave {data.name} Org?</h2>
            <div className="dh__btns">
              <Button variant="text" onClick={() => setShowAction(false)}>
                Cancel
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={() => handleLeaveOrg()}
                style={{ marginLeft: 10 }}
              >
                <Typography sx={{ color: "white" }}>Leave</Typography>
              </Button>
            </div>
          </>
        ) : (
          <>
            <h2>Delete {data.name} Org?</h2>
            <div className="dh__btns">
              <Button variant="text" onClick={() => setShowAction(false)}>
                Cancel
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={() => handleDeleteOrg()}
                style={{ marginLeft: 10 }}
              >
                <Typography sx={{ color: "white" }}>Leave</Typography>
              </Button>
            </div>
          </>
        )}
        </Grid>
      </Dialog>
    </>
  );
};

export default DeleteJob;

import React, {useState, useEffect} from "react";
import {db, updateUser} from "../../firebase/firebase.utils";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import "./assigned-tasks.styles.scss";
import DaysLeft from "../modules/days-left/days-left.component";
import Priority from "../modules/priority/priority.component";
import CreatedBy from "../modules/created-by/created-by.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTasks} from "@fortawesome/free-solid-svg-icons";
import {Box, Card, CardContent, Chip, Grid, Typography} from "@mui/material";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";




const AssingedTasks = ({
  assignedTasks,
}) => {

  const currentUser = useSelector((state) => state.user.currentUser);
  const history = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    cardcontent: {
      "&:last-child": {
        paddingBottom: 10
      }
    }
  }));

  const classes = useStyles();
  // const priority = task.priority.filter(x => x.active)[0]
  //
  const priorityMap = {
    Urgent: 'error',
    High: 'warning',
    Normal: 'primary',
    Low: 'success'
  }

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', overflowY: 'scroll', height: '400px', color: theme.palette.text.primary, backgroundColor: theme.palette.background.white}}>
      {!assignedTasks?.length > 0 ? (
        <Box className="at__noTasks" >
          <FontAwesomeIcon icon={faTasks} size="4x" style={{marginTop: 20}}/>
          <Typography>All Tasks Done!</Typography>
        </Box>
      ) : (
        <Grid container  columnSpacing={3}>
          {assignedTasks.map((task) => {
            const {fromOrgId, fromJobId} = task;
            return (
              <Grid item xs={12} md={6} maxWidth={'100% !important'} sx={{display:"flex", flexWrap: 'wrap', justifyContent: "space-evenly"}} key={task.id}>
                <Box
                  sx={{cursor: 'pointer'}}
                  onClick={() => {
                    history.push(`/s/${fromOrgId}/e/${fromJobId}/b/`, {
                      task,
                    });
                  }}
                  key={task.id}
                  className="at__item"
                >
                  <Card className="task-card" sx={{color: theme.palette.text.primary,backgroundColor: theme.palette.background.white, mt:"10px"}}>
                    <CardContent className={classes.cardcontent}>
                      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color:theme.palette.text.primary}}>
                        <Box sx={{ display: 'flex' }}>
                          <Typography sx={{ mr: 3, color: theme.palette.text.primary }}> Due: </Typography>
                          
                          <DaysLeft  task={task}/>
                        </Box>
                        <Box>
                          <Priority task={task}/>
                        </Box>
                      </Box>
                      <Box sx={{mt: 1}}>
                        <h2 style={{marginBottom: 0,color: theme.palette.text.primary}}>{task.content}</h2>
                      </Box>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        marginTop: 2,
                      }}>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      )}
      </Box>
    </ThemeProvider>
  );
};

export default AssingedTasks;

import { TextArea, Button } from "ui-neumorphism";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { setTaskDescription } from "../../firebase/firebase.utils";
import RetroButton from "../retro/button/retro-button.component";

//import TeaxtArea from "../retro/text-area/text-area.component";

import "./task-description.styles.scss";
import { TextField } from "@mui/material";
import { useTheme} from "@mui/material/styles";
const TaskDescription = ({ description, taskId }) => {
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const [textDescription, setTextDescription] = useState("");
  const [opacity, setOpacity] = useState(0);
  const theme = useTheme();
  useEffect(() => {
    setTextDescription(description);
    setOpacity(0);
  }, [description]);

  const handleDescription = (e) => {
    e.preventDefault();
    setTaskDescription(orgId, jobId, taskId, textDescription);
  };

  return (
    <div className="taskDescription">
      <div className="taskDescription-text">
        <p style={{color:theme.palette.text.main}}>Description</p>
      </div>
      <div className="taskDescription-box">
        <form onSubmit={(e) => handleDescription(e)}>
          <TextField
            hiddenLabel
            id="filled-hidden-label-small"
            defaultValue="Small"
            variant="filled"
            value={textDescription}
            onChange={(e) => {
              console.log(e?.target?.value);
              setTextDescription(e?.target?.value);
              setOpacity(1);
            }}
            size="small"
            style={{width:'calc(100% - 75px)'}}
          />

          <div className="taskDescription-button">
            <Button style={{ opacity: opacity }}>Save</Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaskDescription;

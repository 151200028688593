import { useSelector } from "react-redux";

export const useActiveOrgData = () => {
  const orgData = useSelector((state) => state.org.orgData);
  const activeOrgId = useSelector((state) => state.history.orgId);
  if (!orgData) return;
  const data = orgData.filter((data) => data.orgId === activeOrgId);

  return data[0];
};

import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Views from "../../views/views.component";
import Modules from "../../modules/modules.component";
import JobInfo from "../../job-info/job-info.component";
import FavoriteStar from "../../favorite-star/favorite-star.component";
import Filter from "../../filter/filter-component";
import "./job-menu.styles.scss";
import FilterC from '../../filter/celine-filter'
import {Box, Grid} from "@mui/material";
import {Divider} from "@mui/material";
import {ThemeProvider, useTheme } from "@mui/material/styles";
const JobMenu = () => {
  const job = useSelector((state) => state.org.jobId);
  const currentJobData = useSelector((state) => state.org.currentJobData);
  const history = useHistory();
  const currentJobId = history.location.pathname.split("/")[4];
  const currentOrgId = history.location.pathname.split("/")[2];
  const view = history.location.pathname.split("/")[5];
  const [data, setData] = useState([]);
  const theme = useTheme();
  useEffect(() => {
    if (!job) return;
    const data = job.filter(
      (item) => item.job === currentJobId
    );
    setData(data[0]);
  }, [job, currentJobId]);
  console.log(currentJobData,currentJobId,currentOrgId);
  return (
    <ThemeProvider theme={theme}>
      <Box className="job" sx={{ color: theme.palette.text.primary, ml: 3}}>
        
      <JobInfo
        data={currentJobData}
        currentJobId={currentJobId}
        currentOrgId={currentOrgId}
      />
      <Grid container>
        <Grid item xs={12} md={6}>
          <Views view={view}/>
        </Grid>
          <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center', color: "red" }}>
          <Filter/>
        </Grid>
        <Grid item xs={12} md={3} sx={{display: 'flex', alignItems: 'center'}}>
          <FilterC/>
          <Modules
            currentJobId={currentJobId}
            currentOrgId={currentOrgId}
          />
        </Grid>
      </Grid>
      {/*<div className="sm__favorite">*/}
      {/*  <FavoriteStar data={data}/>*/}
      {/*</div>*/}
      <Divider sx={{marginTop: 1}}/>
      </Box>
      </ThemeProvider>
  );
};

export default JobMenu;

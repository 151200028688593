import React from "react";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

import "./views.styles.scss";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faColumns, faTasks, faBusinessTime, faCalendar} from "@fortawesome/free-solid-svg-icons";
import {Typography} from "@mui/material";
import {ThemeProvider, useTheme } from "@mui/material/styles";
const Views = ({view}) => {
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const history = useHistory();
  const currentJobId = history.location.pathname.split("/")[4];
  const currentOrg = history.location.pathname.split("/")[2];
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <div className="views">
      <Link to={`/s/${orgId}/e/${jobId}/b`}>
        <div
          className="views__board views__view"
          style={{
            background: view === "b" ? theme.palette.action.active : "",
            color: view === "b" ? theme.palette.text.main : "",
          }}
        >
          <Typography sx={{color:theme.palette.text.primary  }}>
            Board
          </Typography>
        </div>
      </Link>
      <Link to={`/s/${orgId}/e/${jobId}/l`}>
        <div
          className="views__list views__view"
          style={{
            background: view === "l" ? theme.palette.action.active : "",
            color: view === "l" ? "white" : "",
          }}
        >
          <p
            style={{
              color: view === "l" ? theme.palette.text.primary : theme.palette.text.primary,
            }}
          >
            List
          </p>
        </div>
      </Link>

      <Link to={`/s/${orgId}/e/${jobId}/t`}>
        <div
          className="views__list views__view"
          style={{
            background: view === "t" ? theme.palette.action.active : "",
            color: view === "t" ? "white" : "",
          }}
        >
          <p
            style={{
              color: view === "t" ? theme.palette.text.primary : theme.palette.text.primary,
            }}
          >
            Timeline
          </p>
        </div>
      </Link>

      <div
        className="views__list views__view"
        style={{
          background: view === "c" ? theme.palette.action.active : "",
          color: view === "c" ? "white" : "",
        }}
        onClick={() => {
          history.push(`/s/${currentOrg}/e/${currentJobId}/c`)
        }}
      >
        <p
          style={{
            color: view === "c" ? theme.palette.text.primary : theme.palette.text.primary,
          }}
        >
          Calendar
        </p>
      </div>

    </div>
    </ThemeProvider>
  );
};

export default Views;

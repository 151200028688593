import React, {useState, useRef} from "react";
import {useHistory} from "react-router";
import {
  changeDescriptionOfJob,
  changeNameOfJob,
  deleteJob,
} from "../../firebase/firebase.utils";
import MiniMenu from "../retro/mini-menu/mini-menu.component";
import Loading from "../retro/loading/loading.component";
import BoxLayer from "../retro/box-layer/box-layer.component";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faICursor, faTools, faTrash} from "@fortawesome/free-solid-svg-icons";
import "./job-info.styles.scss";
import RetroButton from "../retro/button/retro-button.component";
import PersonFilter from "../filter/person-filter/person-filter.component";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import {Box, Button, Dialog, Grid, Typography} from "@mui/material";

const JobInfo = ({data, currentOrgId, currentJobId}) => {
  
  const [inputName, setInputName] = useState("");
  const [inputDescription, setInputDescription] = useState("");
  const [showMiniMenu, setShowMiniMenu] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showDelete, setshowDelete] = useState(false);
  const history = useHistory();
  const inputRefName = useRef();

  const handleDescription = (e) => {
    e.preventDefault();
    changeDescriptionOfJob(
      currentOrgId,
      currentJobId,
      inputDescription
    );
  };
  const theme= useTheme();

  const handleName = (e) => {
    e.preventDefault();
    changeNameOfJob(currentOrgId, currentJobId, inputName);
  };

  const handleDeleteJob = async () => {
    try {
      await deleteJob(currentOrgId, currentJobId);
    } catch (error) {
      console.log(error.message);
    } finally {
      history.push("/");
      setShowLoading(false);
      setShowMiniMenu(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
   
   <Box className="sm__settings"  sx={{color:theme.palette.text.primary, mt: 7}}>
        <FontAwesomeIcon
          icon={faTools}
          size="10x"
          onClick={() => setShowMiniMenu(!showMiniMenu)}
        />
        {showMiniMenu && (
          <MiniMenu setLayer={setShowMiniMenu}>
            <ul>
              <li
                onClick={() => {
                  inputRefName.current.focus();
                  setShowMiniMenu(false);
                }}
              >
                <div className="tooltip">Rename</div>
                <FontAwesomeIcon icon={faICursor}/>
              </li>
              <li onClick={() => setshowDelete(true)}>
                <div className="tooltip">Delete</div>
                <FontAwesomeIcon icon={faTrash}/>
              </li>
            </ul>
          </MiniMenu>
        )}
      </Box>



    <Box className="jobInfo"  sx={{display: 'flex', justifyContent: 'space-between'}}>
      {/* <Box>
        {data}
      </Box> */}
      {showLoading && <Loading/>}
      {data && (
        <div className="si__title" style={{marginBottom: 15}}>
          <div className="si__name">
            <form onSubmit={(e) => handleName(e)}>
              <input
                placeholder={data?.name}
                ref={inputRefName}
                onChange={(e) => setInputName(e.target.value)}
              />
            </form>
          </div>
          <div className="si__description">
            <form onSubmit={(e) => handleDescription(e)}>
              <input
                placeholder={data?.description}
                onChange={(e) => setInputDescription(e.target.value)}
              />
            </form>
          </div>
        </div>
      )}
       
      
    

      <Dialog onClose={() => setshowDelete(false)} open={showDelete}>
        <Grid margin={4}>
        <h2>Delete job {data?.name} ?</h2>
          <div className="si__btns">
          <Button variant="text" onClick={() => setshowDelete(false)}>
                Cancel
              </Button>

              <Button
                variant="contained"
                size="small"
                onClick={() => handleDeleteJob()}
                style={{ marginLeft: 10 }}
              >
                <Typography sx={{ color: "white" }}>Delete</Typography>
              </Button>
          </div>
        </Grid>
      </Dialog>

      <Box className="filter__byPerson">
        <PersonFilter/>
      </Box>
    </Box>

    </ThemeProvider>
  );
};

export default JobInfo;

import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { updateDrag, updateUser } from "../../firebase/firebase.utils";
import { AnimatePresence } from "framer-motion";
import { setStatusType } from "../../redux/org/org.actions";

import StatusTypeList from "../../components/dnd/list/status-type-list/status-type-list.component";
import BoardNewStatus from "../../components/board-new-status/board-new-status.component";

import "./calendar.styles.scss";
import {
  Calendar as BigCalendar,
  momentLocalizer,
  Views,
} from "react-big-calendar";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useActiveOrgData } from "../../hooks/useActiveOrgData.hook";
import useCurrentMembers from "../../hooks/useCurrentMembers.hook";
import LargeTask from "../../components/large-task/large-task.component";
import BoxRight from "../../components/retro/box-right/box-right.component";
import { Dialog } from "@mui/material";
const localizer = momentLocalizer(moment);
let allViews = Object.keys(Views).map((k) => Views[k]);

const Calendar = ({ job }) => {
  const users = useSelector((state) => state.user.users);
  const dispatch = useDispatch();
  const [showLargeTask, setShowLargeTask] = useState(false);
  const [task, setShowTask] = useState(null);
  const [state, setState] = useState([]);
  const [bOpacity, setBOpacity] = useState(0);
  const memberPromise = useCurrentMembers();
  const [memberData, setMemberData] = useState([]);

  useEffect(() => {
    setState(job);
    dispatch(setStatusType(job.statusType));
    memberPromise.then((data) => {
      setMemberData(data);
    });
  }, [job]);

  let members =
    memberData?.map((member) => ({
      id: member?.uid,
      title: member?.userName,
    })) || [];
  // const groups = [{ id: 1, title: 'group 1' }, { id: 2, title: 'group 2' }]
  let tasks = job?.tasks
    ? Object.values(job?.tasks).map((task) => {
        return {
          id: task?.id,
          title: task?.content,
          start: task?.startdate?.toDate(),
          end: task?.deadline?.toDate(),
        };
      })
    : [];

  return (
    <div className="calendar-container">
      <BigCalendar
        events={tasks}
        views={allViews}
        step={60}
        showMultiDayTimes
        localizer={localizer}
        onSelectEvent={(event) => {
          setShowTask(
            Object.values(job?.tasks).find((task) => task.id === event.id)
          );
          setShowLargeTask(!showLargeTask);
        }}
      />
      <AnimatePresence>
        {task && showLargeTask && (
          <Dialog onClose={() => setShowLargeTask(false)} open={showLargeTask}>
            <LargeTask onClose={() => setShowLargeTask(false)} task={task} />
          </Dialog>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Calendar;

import moment from "moment";

const calculateWeekProgress = (assignedTasks) => {
  if (!assignedTasks || assignedTasks?.length < 1) return 0;

  let thisWeekTasks = assignedTasks?.reduce((thisWeekTasks, eachTask) => {
    let startdate = moment(new Date(eachTask?.deadline?.seconds * 1000));
    let deadline = moment(new Date(eachTask?.startdate?.seconds * 1000));
    if (
      moment(startdate).isSame(new Date(), "week") ||
      moment(deadline).isSame(new Date(), "week")
    ) {
      thisWeekTasks.push(eachTask);
    }

    return thisWeekTasks;
  }, []);

  let completedTasks = thisWeekTasks.filter((each) => each.done);

  if (completedTasks?.length < 1 || thisWeekTasks?.length < 1) return 0;
  return (completedTasks.length / thisWeekTasks?.length) * 100;
};

export default calculateWeekProgress;

import {
  Subtitle2,
} from 'ui-neumorphism';
import "./home.styles.scss";
import NoOrgData from "../../components/noOrgData/no-org-data.component";
import OrgData from "../../components/orgData/org-data.component";
import SectionBox from "../../components/section-box/section-box.component";
import FavoriteJobs from "../../components/favorite-jobs/favorite-jobs.component";
import AssignedTasks from "../../components/assigned-tasks/assigned-tasks.component";
import WelcomeHome from "../../components/welcome-home/welcome-home.component";
import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {db, updateUser} from "../../firebase/firebase.utils";
import {motion} from "framer-motion";
import Avatar from "../../components/retro/avatar/avatar.component";
import OrgFly from "../../components/org-fly/org-fly.component";
import {setOrgData, removeOrgData} from "../../redux/org/org.actions";
import {setLoading} from "../../redux/history/history.actions";
import {setOpen} from "../../redux/user/user.actions";
import UserProfile from "../../components/user-profile/user-profile.component";
import {Box, CircularProgress, Container, Stack, Typography,Card,Divider,CardMedia,CardContent,CardActions} from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Grid} from "@mui/material";
import ChartLine from '../../components/chart'
import Button from "@mui/material/Button";
import Loading from '../../components/retro/loading/loading.component';
import CreateOrg from "../../components/create-org/create-org.component";
import {useHistory} from "react-router-dom";
import orgActionTypes from '../../redux/org/org.types';
import DisabledOrg from '../../components/disabled-org/disabled-org.component';
import PropTypes from "prop-types";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import calculateWeekProgress from './calculateWeekProgress';
import  {
  circularProgressClasses,
  CircularProgressProps,
} from '@mui/material/CircularProgress';

function CircularProgressWithLabel(props) {
  return (
  
    <Box sx={{position: 'relative', display: 'inline-flex'}}>
      <CircularProgress variant="determinate" {...props}
      style={{background:'rgba(0,0,0,0.08)',borderRadius:'100%'}}
      sx={{
        animationDuration: '550ms',
        display: 'flex',
        alignItems: 'center', 
        justifyContent: 'center',

        left: 0,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
      }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary" sx={{fontSize: 50}}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
      </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};


const Home = () => {
  const orgData = useSelector((state) => state.org.orgData);
  const currentUser = useSelector((state) => state.user.currentUser);
  const orgId = useSelector((state) => state.history.orgId);
  const open = useSelector((state) => state.user.currentUser.open);
  const {userName} = currentUser;
  const [make, setMake] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDisabled, setOpenDisabled] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory()
  const [activeOrgData, setActiveOrgData] = useState({})
  const [width, setWidth] = useState(window.innerWidth);
  const theme = useTheme();
  const [assignedTasks, setAssignedTasks] = useState([]);
  const [userTasks, setAllUserTasks] = useState([]);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 700;

  useEffect(() => {
    db.collection("org")
      .where("members", "array-contains", currentUser.uid)
      /* .orderBy("created", "asc") */
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let shots = [];
          snapShot.forEach((doc) => {
            shots.push(doc.data());
          });
          dispatch(setOrgData(shots));
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }); 
  }, [currentUser]);



  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userData) => {
        const getOpen = userData.data().open;
        if (getOpen) {
          dispatch(setOpen(true));
        } else {
          dispatch(setOpen(false));
        }

        let sortedTasks = userData
            .data()
            .assignedTasks.sort(
              (a, b) => a?.deadline?.toDate() - b?.deadline?.toDate()
            );
          setAssignedTasks(sortedTasks);
      });
  }, []);


  useEffect(() => {
    const checkIfTaskHealthy = async () => {
      let allTasks = [];

      await Promise.all(assignedTasks?.map(async (task) => {
        const {fromOrgId, fromJobId, id, assign} = task;
       
        const taskRef = db
          .collection("org")
          .doc(fromOrgId)
          .collection("jobs")
          .doc(fromJobId)
          .collection("tasks")
          .doc("tasks");

        const getTaskData = await taskRef.get();
        if (getTaskData.data() === undefined) {
          if (assign === null) {
            console.log("its null");
          }
          const getUserData = await db
            .collection("users")
            .doc(currentUser.uid)
            .get();
          const userData = getUserData.data();
          userData.assignedTasks = userData.assignedTasks.filter(
            (task) => task.id !== id
          );
          updateUser(currentUser.uid, userData);
          return;
        }
        

        const taskData = getTaskData.data().tasks;
        const theTask = taskData[id];
        if(theTask){
          allTasks.push(theTask);
        }
        if (theTask === undefined) {
          if (assign) {
            if (Array.isArray(assign))
              await Promise.all(
                assign?.map(async (assign) => {
                  const getUserData = await db
                    .collection("users")
                    .doc(assign)
                    .get();
                  const userData = getUserData.data();
                  userData.assignedTasks = userData.assignedTasks.filter(
                    (task) => task.id !== id
                  );
                  updateUser(assign, userData);
                })
              );

            return;
          }
        }

        if (
          Array.isArray(theTask.assign) &&
          theTask.assign?.some(
            (it) => Array.isArray(assign) && assign.includes(it)
          )
        ) {
          //but are you a member ?
          if (Array.isArray(assign))
            await Promise.all(
              assign?.map(async (assign) => {
                const getUserData = await db
                  .collection("users")
                  .doc(assign)
                  .get();
                const userData = getUserData.data();
                if (!userData) return;
                db.collection("org")
                  .doc(fromOrgId)
                  .get()
                  .then((docOrgData) => {
                    if (docOrgData.exists) {
                      const orgData = docOrgData.data();
                      if (orgData.members.includes(userData.uid)) {
                        console.log("its ok ur a member");
                      } else {
                        console.log("your not even a member i see");
                        db.collection("users")
                          .doc(assign)
                          .get()
                          .then((user) => {
                            const userData = user.data(); 
                            userData.assignedTasks =
                              userData.assignedTasks.filter(
                                (task) => task.id !== id
                              );
                            updateUser(assign, userData);
                          });
                      }
                    }
                  });
                if (theTask.done === true) {
                  console.log("its not ok, have to delete task", id);
                  if (assign) {
                    const getUserData = await db
                      .collection("users")
                      .doc(assign)
                      .get();
                    const userData = getUserData.data();
                    userData.assignedTasks = userData.assignedTasks.filter(
                      (task) => task.id !== id
                    );
                    updateUser(assign, userData);
                  }
                }
              })
            );
        } else {
          // console.log("its not ok, have to delete task", id);
          if (Array.isArray(assign))
            await Promise.all(
              assign?.map(async (assign) => {
                const getUserData = await db
                  .collection("users")
                  .doc(assign)
                  .get();
                const userData = getUserData.data();
                console.log(userData);
                userData.assignedTasks = userData.assignedTasks.filter(
                  (task) => task.id !== id
                );
                console.log(userData);
                updateUser(assign, userData);
              })
            );
        }
       
      }));
      setAllUserTasks(allTasks);
    };
    checkIfTaskHealthy();
  }, [assignedTasks]);

  let weekProgress = useMemo(() => calculateWeekProgress(userTasks), [userTasks])
  

  return (
    
    <ThemeProvider theme={theme} >
    <Container maxWidth="xl" sx={{pb: 5,backgroundColor: theme.palette.background.light,pt:5}}>
      <Stack spacing={3}>
        <Grid container>
            <Grid item xs={12}  >
            <WelcomeHome/>
          </Grid>
        </Grid>
        <Grid container>
          {isMobile ?
            (<Grid item xs={12} md={6}>
              <Box sx={{height: 250, display: 'flex', justifyContent: 'center', mb: 4, mt: 3,color: theme.palette.action.active}}>
                <CircularProgressWithLabel value={weekProgress} size={250} color="inherit" sx={{color: theme.palette.action.active}}/>
              </Box>
            </Grid>) : null
          }
          {isMobile ?
            (<><Grid item xs={12} md={12} mb={3}>
              <Card variant='outlined' sx={{backgroundColor: theme.palette.background.white}}>
                <Typography sx={{mt: 1, ml: 3, fontSize: 25, fontWeight: 'bold',color: theme.palette.text.primary}}>Your Tasks</Typography>
                <Divider sx={{ mt:2, mb: 2, ml: 3, mr: 3}} />
                {assignedTasks?.length === 0 && (
                  <Typography sx={{mt: 3,color: theme.palette.text.primary}}>
                    You have no tasks listed! If unsure of work contact your supervisor
                  </Typography>
                )}
                <AssignedTasks assignedTasks={assignedTasks} style={{justifyContent: 'space-between', overflowY: 'scroll',}}/>
              </Card>
              </Grid>
              
              </>) : null}
              {!isMobile ?
            (<Grid container spacing={2}>
            <Grid item xs={6} md={6}  >
                <Card variant="outlined" sx={{ height: "40vh", backgroundColor: theme.palette.background.white}}>
              {!isMobile ? (<Grid container item p={3}  sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2,color: theme.palette.text.primary, width: "100%"}}>
                      <Typography sx={{ fontSize: 25, fontWeight: 'bold', color: theme.palette.text.primary }}>Your Organisations</Typography>
                      
                  <Button startIcon={<AddCircleOutlineIcon/>} sx={{color: theme.palette.text.primary}}
                        onClick={() => setMake(!make)} />
                  
                  {loading && <Loading/>}
                  {make && <CreateOrg setLayer={setMake}/>}
                  {openDisabled &&
                    <DisabledOrg setLayer={setOpenDisabled} setLoading={setLoading} activeOrgData={activeOrgData}/>}
                    </Box>
                    <Divider sx={{ mb: 3}} />
                <Box sx={{flexGrow: 1}}>
                  {orgData.length !== 0 ? <OrgData/> : <NoOrgData/>}
                </Box>
              </Grid>) : null}
            </Card>
              </Grid>
              <Grid item xs={6} md={6} >
            <Card variant="outlined" sx={{ height: "40vh",backgroundColor: theme.palette.background.white}}>
                <Grid container>
                {!isMobile ? (<Grid p={3} ml={2}  >
                <Box sx={{display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', width: '100%'}}>
                  <Typography  mb={2} sx={{fontSize: 25, fontWeight: 'bold', color: theme.palette.text.primary}}>Your Tasks</Typography>
                  <Divider sx={{ mb: 3}} />
                  {assignedTasks?.length === 0 && (
                    <Typography sx={{mt: 3,color: theme.palette.text.primary}}>
                      You have no tasks listed! If unsure of work contact your supervisor
                    </Typography>
                    )}
                  <Box style={{ color: theme.palette.text.primary}}>
                      <AssignedTasks assignedTasks={userTasks} style={{ justifyContent: 'space-between', overflowY: 'scroll'}} />
                    </Box>
                </Box>
                </Grid>) : null}
                </Grid>  
                </Card>
              </Grid>
              <Grid item xs={12} md={12}>
            
              <Grid container spacing={2}>
                  {!isMobile ?
                      (
                        
                          
                          <Grid item xs={6} md={6}>

                            
                            <Card variant="outlined"sx={{ height: "fit-content", backgroundColor: theme.palette.background.white, color: theme.palette.action.active, mt:"30px", justifyContent: 'space-between',display: 'flex', flexDirection: 'column', maxWidth: '100%'}}>
                            <Typography ml={3} mt={3} mb={2} sx={{fontSize: 25, fontWeight: 'bold', color: theme.palette.text.primary}}>Completed Tasks This Week</Typography>
                            <Divider sx={{ mt:1, mb: 2, ml: 3, mr: 3}} />
                                <Grid item xs={12} md={6} maxWidth={'100% !important'}>
                                  <Box sx={{height: 250, display: 'flex', justifyContent: 'center', mb: 4, mt: 3,color: theme.palette.action.active}}>
                                    <CircularProgressWithLabel value={weekProgress} size={250} color="inherit" sx={{color: theme.palette.action.active}}/>
                                  </Box>
                                </Grid>
                                  
                            </Card>
                      
                          </Grid>

                      ) : null
                    }
                 
                </Grid>
                </Grid>
            </Grid>
            ) : null}

            
           
            {isMobile ?
              (
                
                <Card variant='outlined' item xs={12} md={6} sx={{ justifyContent: 'space-between', flexDirection: 'flex-end', backgroundColor: theme.palette.background.white }}>
            
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, color: theme.palette.text.primary }}>
              <Typography sx={{mt: 1, ml: 3, fontSize: 25, fontWeight: 'bold',color: theme.palette.text.primary}}>Your Organisations</Typography>
              <Button startIcon={<AddCircleOutlineIcon/>} sx={{mt: 2, color: theme.palette.text.primary}}
                      onClick={() => setMake(!make)}/>
              {loading && <Loading/>}
              {make && <CreateOrg setLayer={setMake}/>}
              {openDisabled &&
                <DisabledOrg setLayer={setOpenDisabled} setLoading={setLoading} activeOrgData={activeOrgData}/>}
          
            </Box>
            <Divider sx={{ mt:2, mb: 2, ml: 3, mr: 3}} />
            <Box sx={{flexGrow: 1}}>
              {orgData.length !== 0 ? <OrgData/> : <NoOrgData/>}
            </Box>
          </Card> ) : null}
          </Grid>
       
      </Stack>
    </Container>
    </ThemeProvider>
    
  );
};

export default Home;

import React, {useState, useEffect, useCallback, useMemo} from "react";
import "./timeline.styles.scss";
import TimelineCalendar from 'react-calendar-timeline'
// make sure you include the timeline stylesheet or the timeline will not be styled
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import LargeTask from "../../components/large-task/large-task.component";
import {AnimatePresence} from "framer-motion";

import { Dialog } from "@mui/material";

const TasksTimeline = ({tasks, memberData, debouncedChangeHandler, job }) => {
  
  const [task, setShowTask] = useState(null);
  const [showLargeTask, setShowLargeTask] = useState(false);

  let minTime = moment().add(-30, 'days');
  let maxTime = moment(Math.max.apply(null, tasks?.map(task => task?.end_time.toDate()))).add(0, 'days');
  let props = {
    itemTouchSendsClick: false,
    maxZoom: moment.duration(2, 'months').asMilliseconds(),
    onTimeChange: function (visibleTimeStart, visibleTimeEnd, updateScrollCanvas) {

      if (visibleTimeStart < minTime && visibleTimeEnd > maxTime) {
        updateScrollCanvas(minTime, maxTime)
      } else if (visibleTimeStart < minTime) {
        updateScrollCanvas(minTime, minTime + (visibleTimeEnd - visibleTimeStart))
      } else if (visibleTimeEnd > maxTime) {
        updateScrollCanvas(maxTime - (visibleTimeEnd - visibleTimeStart), maxTime)
      } else {
        updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
      }
    }

  }

  let groups = tasks?.map(tasks => ({id: tasks?.id, title: tasks?.title})) || [];
  return (
    <div className="timeline-container">

      <TimelineCalendar
        groups={groups}
        // minResizeWidth={2000}
        onItemDoubleClick={(eventId) => {
          setShowTask(Object.values(job?.tasks).find(task => task.id === eventId.split(',')[0]));
          setShowLargeTask(!showLargeTask)
        }}
        items={tasks}
        fixedHeader={false}
        lineHeight={70}
        defaultTimeStart={moment()}
        defaultTimeEnd={moment().add(30, 'days')}
        onItemDrag={debouncedChangeHandler}
        {...props}
      />


      <AnimatePresence>
        {task && showLargeTask && (
          <Dialog onClose={() => setShowLargeTask(false)} open={showLargeTask}>
          <LargeTask onClose={() => setShowLargeTask(false)} task={task} />
        </Dialog>
         
        )}
      </AnimatePresence>
    </div>
  );
};

export default TasksTimeline;

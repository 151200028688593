import {Button} from 'ui-neumorphism';
import {Subtitle1, Subtitle2, Card, H4, H6, ListItem, ListItemGroup} from 'ui-neumorphism'
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import RetroButton from "../retro/button/retro-button.component";
import CreateOrg from "../create-org/create-org.component";
import "./org-data.styles.scss";
import orgActionTypes from '../../redux/org/org.types';
import DisabledOrg from '../disabled-org/disabled-org.component';
import Loading from '../retro/loading/loading.component';
import {Avatar, Box, Chip, Paper, Typography,Grid} from "@mui/material";
import {useActiveOrgData} from "../../hooks/useActiveOrgData.hook";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Item from 'antd/lib/list/Item';
import ButtonBase from '@material-ui/core/ButtonBase';
import { style } from '@mui/system';
function OrgData() {
  const orgData = useSelector((state) => state.org.orgData);
  const [create, setCreate] = useState(false);
  const [openDisabled, setOpenDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeOrgData, setActiveOrgData] = useState({})
  const dispatch = useDispatch();
  const history = useHistory()
  const OrgData = useActiveOrgData();
  const theme = useTheme();
  const gotToOrganisation = (to, data) => {
    if (data.disabled) {
      setActiveOrgData(data)
      setOpenDisabled(true)
      return;
    }
    dispatch({
      type: orgActionTypes.SET_STATE,
      payload: {
        currentOrganization: data
      }
    })
    history.push(to);
  }

  return (
    <ThemeProvider theme={theme}>
    <Box sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', overflowY: 'scroll', height: '400px', marginLeft: '2rem', marginRight: '2rem'}}>
      {orgData.map((data) => {
        const {orgId, name} = data
        return (
          // <Paper key={orgId}
          //        sx={{height: 50, mb: 1, cursor: 'pointer', opacity: 0.7, display: 'flex', alignItems: 'center', backgroundColor: theme.palette.background.light}}
          //        onClick={() => gotToOrganisation(`/s/${orgId}`, data)}>
          //   <Avatar sx={{alignItems: 'center', ml: 2}}>{name.charAt(0)}</Avatar>
          //   <Typography
          //     sx={{
          //       fontSize: 20,
          //       fontWight: 'bold',
          //       ml: 9,
          //       position: 'relative',
          //       color: theme.palette.text.primary,
          //     }}>
          //     {name}
          //   </Typography>
          // </Paper>
          <Box onClick={() => gotToOrganisation(`/s/${orgId}`, data)} style={{cursor: "pointer"}} pb={2}>
            <Card className="org-card" key={orgId} onClick={() => gotToOrganisation(`/s/${orgId}`, data)} pr={3}
              style={{ backgroundColor: theme.palette.background.light, display: "flex", alignItems: "center"}}>
            <Avatar sx={{ alignItems: 'center', ml: 2, color: theme.palette.text.main, backgroundColor: theme.palette.action.active}} variant="rounded">{name.charAt(0).toUpperCase()}</Avatar>
            <Typography
              sx={{
                fontSize: 20,
                fontWight: 'bold',
                ml: 9,
                position: 'relative',
                color: theme.palette.text.primary,
              }}>
              {name.substring(0,12)}
            </Typography>
           
            </Card>
            </Box>
       
        );
      })}
      </Box>
    </ThemeProvider>
  );
}

export default OrgData;

//import { Tooltip } from 'ui-neumorphism'
import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { setDeadlineDate } from "../../../firebase/firebase.utils";

import Tooltip from "../../retro/tooltip/tooltip.component";

import "./deadline.styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const Deadline = ({ task }) => {
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const [date, setDate] = useState();

  useMemo(() => {
    if (!task);
    if (task.deadline === null) return;
    let d = task.deadline.toDate();
    let day = d.getDate();
    let month = d.getMonth();
    setDate(`${day}.${month + 1}.`);
  }, [task]);

  const handleDate = (e) => {
    console.log(e.target.value);
    let dd = new Date(e.target.value);
    setDeadlineDate(orgId, jobId, dd, task.id);
  };

  return (
    <div className="deadline">
      <input type="datetime-local" value={moment(task?.deadline?.toDate()).format('YYYY-MM-DDThh:mm')} onChange={(e) => handleDate(e)} />
      <Tooltip
        text="Set deadline date" 
      >
      </Tooltip>
      {!date && <FontAwesomeIcon icon={faCalendarAlt} />}
      <p>{date}</p>
    </div>
  );
};

export default Deadline;

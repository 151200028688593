import React from "react";
import { motion } from "framer-motion";

import RetroButton from "../retro/button/retro-button.component";
import "ui-neumorphism/dist/index.css";

import "./disabled-org.styles.scss";
import { cloudFunctions } from "../../firebase/firebase.utils";
import { Button, Typography } from "@mui/material";

function DisabledOrg({ setLayer, setLoading, activeOrgData }) {
  const openPortal = () => {
    setLoading(true);
    const getStripePortalSession = cloudFunctions.httpsCallable(
      "getStripePortalSession"
    );
    getStripePortalSession(activeOrgData)
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          alert(res.data.message);
        } else {
          window.location.replace(res.data.session.url);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <motion.div className="disabledOrg">
      <motion.div
        className="cs__content"
        dragConstraints={{
          top: -10,
          left: -10,
          right: 10,
          bottom: 10,
        }}
      >
        <div className="dashboard3">
          <div className="cs__icon">
            Your organisation has been disabled because of an issue with your
            payment card
            <br />
            Please fix your issue, then you can manage your organisation.
          </div>
          <div className="cs__createBtn">
            <Button variant="text" onClick={() => setLayer(false)}>
              Close
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => openPortal()}
              style={{marginLeft:10}}
            >
              <Typography sx={{ color: "white" }}>Fix Payment</Typography>
            </Button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
}

export default DisabledOrg;

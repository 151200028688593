import React, { useEffect, useState, useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { AnimatePresence } from "framer-motion";
import {
  personFilterFunction,
  statusFilterFunction,
  timeFilterFunction,
  setTaskClassDone,
  autoUpdateTasksToUser,
  filterLogicFunction,
} from "../../task.util";
import "./task-board.styles.scss";
import LoadModule from "../../../modules/load-module.component.jsx/load-module.component";
import BoxRight from "../../../retro/box-right/box-right.component";
import LargeTask from "../../../large-task/large-task.component";
import CheckBox from "../../../retro/check-box/check-box.component";
import Subtasks from "../../subtasks/subtasks.component";
import { useHistory, useLocation } from "react-router";
import { Box, Chip, Dialog, Menu } from "@mui/material";
import "../../../../components/modules/priority/priority.styles.scss";
import Priority from "../../../modules/priority/priority.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Popconfirm } from "antd";
import { useTheme} from "@mui/material/styles";
const TaskBoard = ({ task, index, status, onlyShow = false, onTaskDelete}) => {
  const activeModules = useSelector((state) => state.org.activeModulesData);
  const users = useSelector((state) => state.user.users);
  const filter = useSelector((state) => state.filter);
  const [showLargeTask, setShowLargeTask] = useState(false);
  const [statusFilter, setStatusFilter] = useState(true);
  const [timeFilter, setTimeFilter] = useState(true);
  const [personFilter, setPersonFilter] = useState(true);
  const [taskClass, setTaskClass] = useState("");
  const [filterLogic, setFilterLogic] = useState(true);
  const [msgs, setMsgs] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  useEffect(() => {
    
    setStatusFilter(statusFilterFunction(task, filter));
    setTimeFilter(timeFilterFunction(task, filter));
    setPersonFilter(personFilterFunction(task, filter));
    
    if (task) {
      if (location.state?.task && location.state?.task?.id === task.id) {
        setShowLargeTask(true);
        history.replace({ state: { task: null } });
      }
    }
  }, [task, filter]);

  useEffect(() => {
    autoUpdateTasksToUser(users, task);
    setTaskClass(setTaskClassDone(task));
  }, [task]);

  useEffect(() => {
    setFilterLogic(filterLogicFunction(statusFilter, timeFilter, personFilter));
  }, [statusFilter, timeFilter, personFilter]);
  console.log(filterLogic,"Task");
  return (
    <Draggable draggableId={task.id} index={index}>
     
      {(provided, snapshot) => {
        const style = {
          ...provided.draggableProps.style,
          opacity: status.open ? "1" : "0",
          height: status.open ? "auto" : "10px",
        };
        
        return (
          <div
            {...provided.draggableProps}
            ref={provided.innerRef}
            className="task__logic"
            style={style}
          >
            
            {filterLogic && (
              <div
                className={`task ${taskClass}`}
                {...provided.dragHandleProps}
                style={{
                  border: showLargeTask && "1px solid #34b5e4",
                  background: showLargeTask && " rgba(52, 181, 228, 0.05)",
                }}
              >
                <div
                  className="task__expandPlace"
                  onClick={() => setShowLargeTask(!showLargeTask)}
                />
                <CheckBox task={task} />

                <Box
                  className="task__taskName"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  
                  <h3 style={{color: theme.palette.text.primary}}>{task.content}</h3>
                  <Priority task={task} />
                </Box>
                <Subtasks task={task} />
                <div className="task__modules">
                  {activeModules
                    ?.filter((module) => module.name != "Priority")
                    .map((module) => {
                      return (
                        <LoadModule 
                          module={module}
                          key={module.name}
                          task={task}
                          style="box"
                        />
                      );
                    })}
                </div>
                <Popconfirm
                      title={`Are you sure you want to delete this task?`}
                      onConfirm={() => onTaskDelete(task?.id,status?.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                    <FontAwesomeIcon className="task_delete_button" icon={faTrashAlt} size="1x"/>

                    </Popconfirm>
              </div>
            )}
            <Dialog
              onClose={() => setShowLargeTask(false)}
              open={showLargeTask}
            >
              <LargeTask
                onClose={() => setShowLargeTask(false)}
                task={task}
                msgs={msgs}
              />
            </Dialog>
          </div>
        );
      }}
    </Draggable>
  );
};

export default TaskBoard;

import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {db, getUserDataWithId} from "../../../firebase/firebase.utils";
import {
  clearUserFilter,
  setUserFilter,
} from "../../../redux/filter/filter.actions";
import Avatar from "../../retro/avatar/avatar.component";
import BoxLayerLite from "../../retro/box-layer-lite/box-layer-lite.component";
import {faUserSlash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./person-filter.styles.scss";
import {Box, Typography} from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";

const PersonFilter = () => {
  const orgId = useSelector((state) => state.history.orgId);
  const jobId = useSelector((state) => state.history.jobId);
  const currentUser = useSelector((state) => state.user.currentUser);
  const filter = useSelector((state) => state.filter);
  const [members, setMembers] = useState([]);
  const [showMembers, setShowMembers] = useState(false);
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const theme = useTheme();
  console.log(currentUser,"zxczx");
  useEffect(() => {
    const getMembers = async () => {
      if (!orgId) return;
      if (!jobId) return;
      const getOrgData = await db.collection("org").doc(orgId).get();
      const orgData = getOrgData.data();
      const { members } = orgData;
      
      let allMembers = [];
      await members.map((memberId) => {
        db.collection("users")
          .doc(memberId)
          .get()
          .then((memberData) => {
            allMembers.push(memberData.data());
          });
      });
      setMembers(allMembers);
      
    };
    getMembers();
  }, [orgId, jobId]);

  useEffect(async () => {
    if (filter.user) {
      const data = await getUserDataWithId(filter.user);
      setUserData(data);
    } else {
      setUserData(null);
    }
  }, [filter]);

  const handlePersonFilter = (id) => {
    dispatch(setUserFilter(id));
    setShowMembers(false);
  };

  const handleRemoveUser = () => {
    dispatch(clearUserFilter());
    setShowMembers(false);
  };

  return (
    <ThemeProvider theme={theme}> 
    <Box className="personFilter" sx={{mr: {md: 5}}}>
      <Box
        className="personFilter__select"
        onClick={() => setShowMembers(!showMembers)}
      >
        {userData ? (
          <Box className="personFilter__true">
            <Avatar src={userData.imageUrl}/>
            <Typography sx={{color: theme.palette.text.primary}}>{userData.userName}</Typography>
          </Box>
        ) : (
          <Box className="personFilter__false">
            <Avatar/>
            <Typography sx={{fontSize: 13,color: theme.palette.text.primary}}>Select user</Typography>
          </Box>
        )}
      </Box>
      <Box className="personFilter__pop">
        {showMembers && (
          <BoxLayerLite setLayer={setShowMembers}>
              {members?.map((member) => {
              return (
                <Box
                  className="personFilter__member"
                  key={member.uid}
                  onClick={() => handlePersonFilter(member.uid)}
                >
                  <Avatar src={member.imageUrl}/>
                  <Typography sx={{color: theme.palette.text.primary}}>{member.userName}</Typography>
                </Box>
              );
            })}
            <Box
              className="personFilter__remove"
              onClick={() => handleRemoveUser()}
            >
              <FontAwesomeIcon icon={faUserSlash}/>
            </Box>
          </BoxLayerLite>
        )}
      </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PersonFilter;

import orgActionTypes from "./org.types";

const INITIAL_STATE = {
  orgData: [],
  surveysData: [],
  trainingsData: [],
  jobData: null,
  moduleData: [],
  activeModulesData: [],
  statusType: null,
  currentJobData: null
};

const orgReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case orgActionTypes.SET_ORG_DATA:
      return {
        ...state,
        orgData: action.payload,
      };
    case orgActionTypes.REMOVE_ORG_DATA:
      return {
        ...state,
        orgData: action.payload,
      };
    case orgActionTypes.SET_JOB_DATA:
      return {
        ...state,
        jobData: action.payload,
      };
    case orgActionTypes.SET_CURRENT_JOB_DATA:
      return {
        ...state,
        currentJobData: action.payload,
      };
    case orgActionTypes.SET_JOB_SURVEYS:
      return {
        ...state,
        surveysData: action.payload,
      };
    case orgActionTypes.SET_JOB_TRAININGS:
      return {
        ...state,
        trainingsData: action.payload,
      };
    case orgActionTypes.REMOVE_ONE_ORG:
      console.log(state);
      let filtered = state.orgData.filter(
        (org) => org.orgId !== action.payload
      );
      return {
        ...state,
        orgData: filtered,
      };
    case orgActionTypes.SET_MODULES:
      return {
        ...state,
        moduleData: action.payload,
      };
    case orgActionTypes.SET_ACTIVE_MODULES:
      return {
        ...state,
        activeModulesData: action.payload,
      };
    case orgActionTypes.SET_STATUS_TYPE:
      return {
        ...state,
        statusType: action.payload,
      };
    case orgActionTypes.LOG_OUT:
      return {
        orgData: [],
        jobData: null,
        moduleData: [],
        activeModulesData: [],
        statusType: null,
      };
    case orgActionTypes.SET_STATE:
        return {
          ...state,
          ...action.payload
        }
    default:
      return state;
  }
};

export default orgReducer;

import React, { useState } from "react";

import CreateOrg from "../create-org/create-org.component";

import "./no-org-data.styles.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket } from "@fortawesome/free-solid-svg-icons";

function NoOrgData() {
  const [create, setCreate] = useState(false);
  return (
    <>
      <div className="noOrgData">
        <FontAwesomeIcon icon={faRocket} size="4x" />
        <p>
          No Org Found, {/* */}
          <span className="nsd__span" onClick={() => setCreate(!create)}>
            <b>Create New Org!</b>
          </span>
        </p>
        <div className="nsd__createBtn"></div>
      </div>
      {create && <CreateOrg setLayer={setCreate} />}
    </>
  );
}

export default NoOrgData;

// import {
//   IconButton,
// } from "ui-neumorphism";
import React, {useState, useEffect,createContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useLocation} from "react-router-dom";
import {db} from "../../firebase/firebase.utils";
import {setIdsNull} from "../../redux/history/history.actions";
import {setOpen} from "../../redux/user/user.actions";
import {setOrgData} from "../../redux/org/org.actions";
import {setLoading} from "../../redux/history/history.actions";
import UserProfile from "../../components/user-profile/user-profile.component";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AssignmentIcon from '@mui/icons-material/Assignment';
import HomeIcon from '@mui/icons-material/Home';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SendIcon from '@mui/icons-material/Send';
import { useActiveOrgData } from "../../hooks/useActiveOrgData.hook";
import { NavigateNext, NavigateBefore } from "@mui/icons-material";
import Button from "@mui/material/Button";
import {
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { auth, updateUser, storage } from "../../firebase/firebase.utils";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import NestedJobComponent from "../../components/nested-job/nested-job.component";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { positions } from '@mui/system';
import {setJobId, setOrgId} from "../../redux/history/history.actions";
export const ThemeContext = createContext(null);

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, isMobile }) => ({
    flexGrow: 1,
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: isMobile ? `-${drawerWidth}px` : 0,
    }),
  })
);

export default function Dashboard(props) {
  const currentUser = useSelector((state) => state.user.currentUser);
  const orgId = useSelector((state) => state.history.orgId);
  const open = useSelector((state) => state.user.currentUser.open);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentUserUid = useSelector((state) => state.user.currentUser.uid);
  const jobId = useSelector((state) => state.history.jobId);
  const activeOrgData = useActiveOrgData();
  const [x, setX] = useState(0);
  const history = useHistory();
  const orgData = useSelector((state) => state.org.orgData);
  const path = location.pathname;
  const [createNewOrg, setCreateNewOrg] = useState(false);
  const [showArrow, setShowArrow] = useState(false);
  const [newUser, setNewUser] = useState({
    userName: currentUser.userName,
    imageUrl: currentUser.imageUrl,
  });
  const [openNested, setOpenNested] = React.useState(false);
  const [abre, setAbre] = React.useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  
  const [mode, setMode] = useState(localStorage.getItem('theme') || 'light'); 
  localStorage.setItem('theme', mode);
  const toggleTheme = () => {
    setMode((curr) => (curr === "light" ? "dark" : "light"));
  };
  // useEffect(() => {
  //   localStorage.setItem('theme', theme);
  //   toggleTheme = theme;
  //   }, [theme]);

  // const save = (theme) => {
  //   localStorage.setItem('theme', theme);
  // } 



  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
                // palette values for light mode
                text: {
                main: "#fff",
                dark:"#000000"
                
                },
                action: {
                  active: "#7C5CFF",
                  hover: "#7C5CFF",
                  task: "#FAF9F7",
                },
                background: {
                  light: "#F7F7F7",
                  main: "#1B1340",
                  white: "#FFFFFF",
                  grey: "#d9d9d9",
                  darkgrey: "#bcbcbc",
                  lightwhite: "#F5F5F5",
                  greywhite:"#FFFFFF"
                },
              }
            : {
                // palette values for dark mode
                text: {
                main: "#fff",
                dark:"#fff"
                },
                action: {
                  active: "#7C5CFF",
                  hover: "#1C1B24",
                  task: "#16141C",
                },
                background: {
                  light: "#1C1B24",
                  main: "#16141C",
                  white: "#2A2B2D",
                  grey: "#d9d9d9",
                  darkgrey: "#bcbcbc",
                  lightwhite: "#1F1F1F",
                  greywhite:"#292929"
                },
              }),
        },
      }),
    [mode]
  );
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 700;

  const [openDrawer, setOpenDrawer] = React.useState(isMobile ? false : true);

  const handleClick = () => {
    setOpenNested(!openNested);
  };

  const handleNested = (name) => {
    if (name === abre) {
      setAbre(null);
    } else {
      setAbre(name);
    }
  };

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    db.collection("org")
      .where("members", "array-contains", currentUser.uid)
      /* .orderBy("created", "asc") */
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let shots = [];
          snapShot.forEach((doc) => {
            shots.push(doc.data());
          });
          dispatch(setOrgData(shots));
        }
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 1000);
      });
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userDatat) => {
        const getOpen = userDatat.data().open;
        if (getOpen) {
          dispatch(setOpen(true));
        } else {
          dispatch(setOpen(false));
        }
      });
  }, []);

  useEffect(() => {
    if (!orgId) return;

    async function fetchData() {
      const docRef = await db.collection("org").doc(orgId).get();
      const orgData = docRef.data();
      if (!orgData) return;
      if (!orgData.members.includes(currentUserUid)) {
        alert("u have no acces");
        history.push("/");
      }
    }

    fetchData();
  }, [orgId, jobId]);

  useEffect(() => {}, [currentUser]);

  useEffect(() => {
    if (open) {
      setX(0);
    } else {
      setX("-220px");
    }
  }, [open]);

  function handleLogout() {
    auth.signOut();
  }

  return (
    <>
      <ThemeProvider theme={theme}>
      <Box sx={{ color: theme.palette.text.primary, backgroundColor: theme.palette.background.light, width: "100%",
          height: isMobile ? "100%" : "100vh" }}>   
      <Toolbar sx={{position:"absolute",zIndex:100}}>
            {!openDrawer && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(openDrawer && { display: 'none' }) }}
              >
                <MenuIcon />
              </IconButton>)}
      </Toolbar>

      <Box sx={{ display: 'flex' }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              zIndex: isMobile ? 3000 : 1000,
              background: theme.palette.background.main,
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={openDrawer}

        >
          <DrawerHeader>

            <IconButton onClick={handleDrawerClose}>
              <Box sx={{ color: theme.palette.text.main }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </Box>
            </IconButton>

          </DrawerHeader>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            <UserProfile currentUser={currentUser} />
          </Box>
          <Box><Typography sx={{ fontSize: 20, textAlign: 'center', color: theme.palette.text.main }}>{currentUser.userName}</Typography></Box>
          <List
            sx={{ width: '100%', maxWidth: 360, flexGrow: 1, mt: 5 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            <ListItemButton
              
              component={Link}
              to="/"
              onClick={() => {
                dispatch(setIdsNull());
                dispatch(setOpen(true));
              } }
            >
              <ListItemIcon>
                <HomeIcon sx={{ color: theme.palette.text.main }} />
              </ListItemIcon>
              <ListItemText sx={{ color: theme.palette.text.main}} primary="Home" />
            </ListItemButton>
            <ListItemButton onClick={handleClick}>
              <ListItemIcon>
                <ApartmentIcon sx={{ color: theme.palette.text.main }} />
              </ListItemIcon>
              <ListItemText sx={{ color: theme.palette.text.main }} primary="Organizations" />
              <Box sx={{ color: theme.palette.text.main }}>
                {openNested ? <ExpandMore /> : <NavigateBefore />}
              </Box>

            </ListItemButton>
            <Collapse in={openNested} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {orgData.map((data) => {
                  return (
                    <Box key={data.orgId}>
                      <Link to={`/s/${data.orgId}`}>
                      <ListItemButton sx={{ pl: 4, color: theme.palette.text.main }} onClick={() => { handleNested(data.orgId); dispatch(setOrgId(data.orgId)) }}>
                          <ListItemText primary={data.name.substring(0,15)} />
                          {data.orgId === abre ? <ExpandMore /> : <NavigateBefore />}
                        </ListItemButton>
                      </Link>
                      <Collapse
                        in={data.orgId === abre}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          <NestedJobComponent activeOrgId={data.orgId} />
                        </List>
                      </Collapse>
                    </Box>
                  );
                })}
              </List>
            </Collapse>
            <Link to="/surveys">
            <ListItemButton>
              <ListItemIcon>
                <AssignmentIcon sx={{ color: theme.palette.text.main }} />
              </ListItemIcon>
              <ListItemText sx={{ color: theme.palette.text.main }} primary="Survey" />
            </ListItemButton>
            </Link>
            
            {/* <ListItemButton
              component={Link}
              to="/s/:id/messaging"
              onClick={() => {
                dispatch(setIdsNull());
                dispatch(setOpen(true));
              } }
            >
              <ListItemIcon>
                <SendIcon sx={{ color: theme.palette.text.main }} />
              </ListItemIcon>
              <ListItemText sx={{ color: theme.palette.text.main }} primary="Messaging" />
            </ListItemButton> */}
          </List>
              <Divider sx={{ mb: 3}} />
              <Box sx={{ color: theme.palette.text.main,  ml:8,mb:2}}> 
              {mode} mode
              <IconButton
                sx={{ ml: 1 }}
                onClick={toggleTheme}
                color="inherit"
              >
                {theme.palette.mode === "light" ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
              </Box>
          

          <Button variant='outlined' size="small"
            style={{
              marginBottom: 20,
              marginLeft: 15,
              marginRight: 15,
              backgroundColor: theme.palette.action.active,
              borderColor: theme.palette.action.active,
              borderRadius: 8,
            }}>
            <Typography sx={{ color: theme.palette.text.main }}>News & Updates</Typography>
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleLogout()}
            style={{
              marginBottom: 20,
              marginLeft: 15,
              marginRight: 15,
              backgroundColor: theme.palette.action.active,
              borderColor: theme.palette.action.active,
              borderRadius: 8,
            }}
          >
            <Typography sx={{ color: theme.palette.text.main }}>Log Out</Typography>
          </Button>
        </Drawer>
        <Main open={openDrawer} isMobile={isMobile}>
          {props.children}
        </Main>
      </Box>
        </Box>
        </ThemeProvider>
       </> 

  );
}

import Button from "@mui/material/Button";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import "./modules.styles.scss";
import BoxLayer from "../retro/box-layer/box-layer.component";
import ModuleList from "./module-list/module-list.component";
import {Typography} from "@mui/material";
import {ThemeProvider, useTheme } from "@mui/material/styles";
const Modules = ({currentJobId, currentOrgId}) => {
  const moduleData = useSelector((state) => state.org.moduleData);
  const [moduleWindow, setModuleWindow] = useState(false);
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
    <div>
      <div>
        <Button
          sx={{borderColor: '#8c8c8c', backgroundColor: theme.palette.action.active}}
          variant="contained"
          mode="flat2"
          color="info"
          onClick={() => setModuleWindow(!moduleWindow)}
        >
          <Typography variant="body3" sx={{color: theme.palette.text.primary }}>Modules</Typography>
        </Button>
      </div>
      {moduleWindow && (
        <div className="modules__win">
          <BoxLayer setLayer={setModuleWindow}>
            {moduleData?.map((module) => {
              return (
                <ModuleList
                  key={module.name}
                  module={module}
                  modules={moduleData}
                  currentJobId={currentJobId}
                  currentOrgId={currentOrgId}
                />
              );
            })}
          </BoxLayer>
        </div>
      )}
      </div>
      </ThemeProvider>
  );
};

export default Modules;

const UserActionTypes = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CURRENT_USER_NULL: "SET_CURRENT_USER_NULL",
  SET_USERS: "SET_USERS",
  SET_FAVORITE_JOB: "SET_FAVORITE_JOB",
  REMOVE_ONE_JOB: "REMOVE_ONE_JOB",
  ADD_ONE_JOB: "ADD_ONE_JOB",
  ADD_TO_FAVORITE: "ADD_TO_FAVORITE",
  SET_OPEN: "SET_OPEN",
};

export default UserActionTypes;

import React ,{useState, useEffect, useRef} from 'react'
import { useSelector } from "react-redux";
import { io } from 'socket.io-client';
import Webcam from "react-webcam";
import "./videocall.style.scss"
import Videoroom from '../../components/videoroom/videoroom.component';

function Videocall() {
  const currentUser = useSelector((state) => state.user.currentUser);
  const { userName } = currentUser;
  const [messages, setMessage] = useState([])
  const [showPage, setShowPage] = useState(false);
  const [MeetingName, setMeetingName] = useState(currentUser.userName);
  const [roomId, setroomId] = useState("1");
  const [activeUsers, setActiveUsers] = useState([]);
  const socket = io("http://6534-58-84-206-98.ngrok.io");
  const joinroom = () => {
    setShowPage(true);
    socket.emit("join-room",{roomId: roomId, MeetingName:MeetingName })
  }
  
  useEffect(() => {
    
    console.log("hello world!");
    joinroom();
    socket.on('connection', () => console.log('connected'));
    socket.on('all-users', users => {
      console.log('activeUsers');
      console.log(users);
      users = users.filter(user => user.MeetingName != MeetingName);
      setActiveUsers(users);
    });
    
}, []);
  return (
    <div className="container">

      <div>
        
            {showPage ? (
              <div>
              <Webcam
                    audio={true}
                    height={1080}
                    screenshotFormat="image/jpeg"
                    width={720}>
                  
            </Webcam>
              
              {activeUsers.map((activeUser,index) =>
                <Videoroom key={index}
                MeetingName={activeUser.MeetingName}
              />)}
              </div>
          

            
            ): (
              <div className="userProfile__page">
              <div className="userProfile__heading">
                <h2>Meeting</h2>
              </div>
              <div className="userProfile__form">
              <div>
              <input
                  value={MeetingName}
                  placeholder={currentUser.userName}
                  onChange={(e) => setMeetingName(e.target.value)}
                  ></input>                
              </div>
              <div>
              <input
                  value={roomId}
                  placeholder="room id"
                  onChange={(e) => setroomId(e.target.value)}
                  ></input>           
              </div>
              <div>
              <button type="submit" >
                  Start Meeting
                  </button>
             </div> 
            
              
              
                    
              </div>
            </div>
            )
          
          }
          
          </div>
         
       
    </div>
  )
}

export default Videocall
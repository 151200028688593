import {Button} from "ui-neumorphism";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";

import RetroButton from "../retro/button/retro-button.component";
import CreateJob from "../create-job/create-job.component";
import firebase from "firebase/app";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faPlusSquare,
  faIcons,
  faCheckCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "./trainings-list.styles.scss";
import {message, Modal, Popconfirm} from "antd";
import SurveyCreator from "../survey-creator/servey-creator";
import {
  addSurveyToJob,
  addTrainingToJob,
  auth,
  db,
} from "../../firebase/firebase.utils";
import {
  setJobSurveys,
  setJobTrainings,
} from "../../redux/org/org.actions";
import AttemptSurvey from "../survey-attempt/servey-attempt";
import {Box, Chip, Typography} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useTheme, ThemeProvider } from "@mui/material/styles";
function TrainingsList({activeOrgData, isAdmin}) {
  const orgId = useSelector((state) => state.history.orgId);
  const trainingsData = useSelector((state) => state.org.trainingsData);
  const [recentJob, setRecentJob] = useState(null);
  const [createSurvey, setCreateSurvey] = useState(false);
  const [currentSurvey, setCurentSurvey] = useState(null);
  const [openSurvey, setOpenSurvey] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!orgId) {
      return
    }
    db.collection("org")
      .doc(orgId)
      .collection("trainings")
      .onSnapshot((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({...doc.data(), id: doc.id});
        });
        dispatch(setJobTrainings(docs));
      });
  }, [orgId]);

  const onSurveySave = (result) => {
    // edit survey
    if (currentSurvey) {
      let title = JSON.parse(result)?.title;

      if (!title) {
        message.error("Please add title!");
        return;
      }

      db.collection("org")
        .doc(orgId)
        .collection("trainings")
        .doc(currentSurvey.id)
        .update({
          surveyData: result,
          title,
        })
        .then((res) => {
          setCreateSurvey(false);
          setCurentSurvey(null);
          message.success("Survey Saved!");
        });
      return;
    }

    if (!(result.length > 0)) return;

    let title = JSON.parse(result)?.title;

    if (!title) {
      message.error("Please add title!");
      return;
    }

    addTrainingToJob(title, result, orgId).then((res) => {
      setCreateSurvey(false);
      message.success("Training Added!");
    });
  };

  const onComplete = async () => {
    setLoading(true);
    db.collection("org")
      .doc(orgId)
      .collection("trainings")
      .doc(currentSurvey?.id)
      .update({
        attemptedBy: firebase.firestore.FieldValue.arrayUnion(
          auth.currentUser.uid
        ),
      })
      .finally(() => {
        setLoading(false);
        setCurentSurvey(null);
        setOpenSurvey(false);
      });
  };

  const onDeleteTraining = (trainingId) => {
    db.collection("org")
      .doc(orgId)
      .collection("trainings")
      .doc(trainingId)
      .delete()
      .then(() => message.success("Training deleted!"));
  };

  return (
    <ThemeProvider theme={theme} >
    <div className="recentTrainings">
      <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2}}>
        <Typography variant="h5" sx={{color:theme.palette.text.primary}}>Trainings</Typography>
        <Chip
          sx={{marginRight: 1, color: theme.palette.text.primary, borderColor: theme.palette.text.primary,backgroundColor: theme.palette.action.active}}
          label="Add Training"
          color="primary"
          variant="contained"
          icon={<AddIcon sx={{fontSize: 15}}/>}
          mode="create"
          onClick={() => setCreateSurvey(true)}
        />
      </Box>
      <div className="trainingList" style={{overflowY: 'scroll', height: '150px'}}>
        {trainingsData?.length < 1 ? (
          <div className="recentTrainings__noTrainings">
            <div className="recentTrainings__msg">
              <Typography sx={{color:theme.palette.text.primary}}>No Tranings!</Typography>
            </div>
          </div>
        ) : (
          trainingsData?.map((data) => {
            return (
              <div
                onClick={() => {
                  setCurentSurvey(data);
                  if (isAdmin) setCreateSurvey(true);
                  else setOpenSurvey(true);
                }}
                className="tr__item"
                key={data.id}
              >
                {/* <Link to={`/s/${activeOrgData.orgId}/e/${data.jobId}/b`}> */}
                <Box sx={{backgroundColor:theme.palette.background.main}}>
                <button style={{backgroundColor: theme.palette.action.task}}>
                  <div className="survey-btn-inner-container" style={{color: theme.palette.text.primary}}>
                    {data.title}
                    {isAdmin && (
                      <div onClick={(e) => e.stopPropagation()}>
                        <Popconfirm
                          title="Are you sure to delete this training?"
                          onConfirm={() => onDeleteTraining(data.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <FontAwesomeIcon icon={faTrash}/>
                        </Popconfirm>
                      </div>
                    )}
                    {!isAdmin && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="4x"
                        color={
                          data?.attemptedBy &&
                          data?.attemptedBy?.includes(auth.currentUser.uid)
                            ? "green"
                            : "grey"
                        }
                      />
                    )}
                  </div>
                  </button>
                  </Box>
                {/* </Link> */}
              </div>
            );
          })
        )}
      </div>
      {createSurvey && (
        <Modal
          onCancel={() => {
            setCreateSurvey(false);
            setCurentSurvey(null);
          }}
          footer={null}
          className="surveyjsmodal"
          visible={true}
        >
          <SurveyCreator
            currentSurvey={currentSurvey}
            onSurveySave={onSurveySave}
          />
        </Modal>
      )}

      {openSurvey && (
        <Modal
          closeIcon={() => null}
          close
          footer={null}
          className="trainingjsmodal"
          visible={true}
        >
          {loading ? (
            "Saving..."
          ) : (
            <AttemptSurvey
              json={
                currentSurvey?.surveyData &&
                JSON.parse(currentSurvey?.surveyData)
              }
              onComplete={onComplete}
            />
          )}
        </Modal>
      )}
    </div>
    </ThemeProvider>
  );
}

export default TrainingsList;

import React, {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router";
import UserProfile from "../../components/user-profile/user-profile.component";
import {
  faBars,
  faChevronLeft,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RetroButton from "../retro/button/retro-button.component";
import {useDispatch, useSelector} from "react-redux";
import {db} from "../../firebase/firebase.utils";
import {motion} from "framer-motion";
import Avatar from "../../components/retro/avatar/avatar.component";
import OrgFly from "../../components/org-fly/org-fly.component";
import {setOrgData, removeOrgData} from "../../redux/org/org.actions";
import {setLoading} from "../../redux/history/history.actions";
import {setOpen} from "../../redux/user/user.actions";
import "./welcome-home.styles.scss";
import {Typography} from "antd";
import {Box} from "@mui/material";
import {Grid} from "@mui/material";
import { useTheme, ThemeProvider } from "@mui/material/styles";

const WelcomeHome = (props) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const {userName, assignedTasks} = currentUser;
  const [timeOfDay, setTimeOfDay] = useState(null);
  const [homePage, setHomePage] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const orgId = useSelector((state) => state.history.orgId);
  const open = useSelector((state) => state.user.currentUser.open);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const theme = useTheme();
  const [weekOfDay, setWeekOfDay] = useState(null);
  const [dateOfDay, setDateOfDay] = useState(null);
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setHomePage(true);
    } else {
      setHomePage(false);
    }
  }, [location]);

  useEffect(() => {
    db.collection("org")
      .where("members", "array-contains", currentUser.uid)
      /* .orderBy("created", "asc") */
      .onSnapshot((snapShot) => {
        if (!snapShot.empty) {
          let shots = [];
          snapShot.forEach((doc) => {
            shots.push(doc.data());
          });
          dispatch(setOrgData(shots));
        }
        setTimeout(() => {
          dispatch(setLoading(false));
        }, 1000);
      });
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    db.collection("users")
      .doc(currentUser.uid)
      .onSnapshot((userDatat) => {
        const getOpen = userDatat.data().open;
        if (getOpen) {
          dispatch(setOpen(true));
        } else {
          dispatch(setOpen(false));
        }
      });
  }, []);

  /* const name = userName.split(" ")[0]; */

  const name = userName;
  let time = new Date();

  useEffect(() => {
    let h = time.getHours();
    if (h >= 0 && h <= 12) {
      setTimeOfDay("Good morning");
    }
    if (h > 12 && h <= 18) {
      setTimeOfDay("Good afternoon");
    }
    if (h > 18 && h <= 24) {
      setTimeOfDay("Good evening");
    }
  }, [time]);



  useEffect(() => {
    let week = time.getDay();
    let month = time.getMonth();
    let date = time.getDate();
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const weekNames = ["Monday", "Tuesday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
   
    setWeekOfDay(weekNames[week] + ", " + monthNames[month] + " " + date);
       
  }
    
  , [time]);

  

  return (
    <ThemeProvider theme={theme}>
       
       <Typography className="welcomeText" style={{ color: theme.palette.text.primary}}> {weekOfDay} </Typography>
        <Typography className="welcomeText" style={{ color: theme.palette.text.primary }}> {timeOfDay}, {name}! </Typography>
      
      
       
     
      
    </ThemeProvider>
  );
};

export default WelcomeHome;

import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import "./dock-header-styles.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFillDrip,
  faICursor,
  faTools,
  faCreditCard
} from "@fortawesome/free-solid-svg-icons";
import MiniMenu from "../retro/mini-menu/mini-menu.component";
import DeleteJob from "./delete-org/delete-org.component";
import Colors from "../colors/colors.component";
import {
  changeDescriptionOfOrg,
  cloudFunctions,
  renameOrg,
  updateColorOfOrg,
} from "../../firebase/firebase.utils";
import {Box, Grid} from "@mui/material";
import {Divider} from "antd";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
const DockHeader = ({activeOrgData, setLoading}) => {
  const orgId = useSelector((state) => state.history.orgId);
  const [inputDesc, setInputDesc] = useState("");
  const [inputName, setInputName] = useState("");
  const [showMiniMenu, setShowMiniMenu] = useState(false);
  const [showColors, setShowColors] = useState(false);
  const [color, setColor] = useState("");

  const inputRefDesc = useRef();
  const inputRefName = useRef();
  const theme= useTheme();
  useEffect(() => {
    if (!color) return;
    updateColorOfOrg(orgId, color);
    setShowColors(false);
    setShowMiniMenu(false);
  }, [color, orgId]);

  const handleSubmitDesc = (e) => {
    e.preventDefault();
    console.log(activeOrgData.uid, inputDesc);
    changeDescriptionOfOrg(activeOrgData.orgId, inputDesc);
    inputRefDesc.current.blur();
  };

  const handleSubmitName = (e) => {
    e.preventDefault();
    console.log(activeOrgData.uid, inputDesc);
    renameOrg(activeOrgData.orgId, inputName);
    inputRefName.current.blur();
  };

  const openPortal = () => {
    setLoading(true)
    const getStripePortalSession = cloudFunctions.httpsCallable("getStripePortalSession");
    getStripePortalSession(activeOrgData).then(res => {
      if (res.data.error) {
        setLoading(false);
        alert(res.data.message)
      } else {
        window.location.replace(res.data.session.url)
      }
    }).catch(err => {
      setLoading(false)
      console.log(err);
    })
  }

  return (
    <ThemeProvider theme={theme}>
    <Box>
      <Box className="dh__header">
        <Grid container className="dh__text">
          <Grid item xs={12} md={12} className="dh__name">
            <Box className="dh__settings" sx={{ml: 0}}>
              <Box className="dh__settings-icon" sx={{color:theme.palette.text.primary}}>
                <FontAwesomeIcon
                  icon={faTools}
                  onClick={() => setShowMiniMenu(!showMiniMenu)}
                />
              </Box>
              {showMiniMenu && (
                <MiniMenu setLayer={setShowMiniMenu}>
                  <ul>
                    <li
                      onClick={() => {
                        inputRefName.current.focus();
                        setShowMiniMenu(false);
                      }}
                    >
                      <Box className="tooltip">Rename</Box>
                      <FontAwesomeIcon icon={faICursor} fontSize="1x"/>
                    </li>
                    <DeleteJob data={activeOrgData} setLoading={setLoading}/>
                    {activeOrgData.plan === "pro" &&
                      <li onClick={() => openPortal()}>
                        <Box className="tooltip">Manage Subscription</Box>
                        <FontAwesomeIcon icon={faCreditCard} fontSize="1x"/>
                      </li>
                    }
                    <li onClick={() => setShowColors(!showColors)}>
                      <Box className="tooltip">colors</Box>
                      <FontAwesomeIcon icon={faFillDrip} fontSize="1x"/>
                    </li>
                    {showColors && <Colors returnColor={setColor}/>}
                  </ul>
                </MiniMenu>
              )}
              </Box>
            <Box sx={{color:theme.palette.text.primary}}>  
            <form onSubmit={(e) => handleSubmitName(e)}>
              <input
                style={{fontSize: 30, marginLeft: -10}}
                ref={inputRefName}
                placeholder={activeOrgData?.name}
                onChange={(e) => setInputName(e.target.value)}
              />
                </form>
            </Box>
          </Grid>
          <Grid item xs={12} md={12} className="dh__description">
            <form onSubmit={(e) => handleSubmitDesc(e)}>
              <input
                style={{marginLeft: -10}}
                ref={inputRefDesc}
                placeholder={activeOrgData?.description}
                onChange={(e) => setInputDesc(e.target.value)}
              />
            </form>
          </Grid>
        </Grid>
      </Box>
      <Divider/>
    </Box>
    </ThemeProvider>
  );
};

export default DockHeader;

import React ,{useState, useEffect, useRef} from 'react'
import {useSelector} from "react-redux";
import './respond.style.scss';
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import Avatar from '../retro/avatar/avatar.component';
function Respond({ respond, username, id, imageUrl, timestamp }) {
    const theme = useTheme();
    const currentUser = useSelector((state) => state.user.currentUser);
   
    function compare(id, userid) { 
        return userid === id
    }
    const owner = compare(id, currentUser.uid);
    return (
        <ThemeProvider theme={theme}>
            {owner?
              (<Box className="messageright" m={2} sx={{ color: theme.palette.text.primary }}>
              {/* <img src={currentUser.imageUrl} alt="user avatar" /> */}
                  <Avatar src={imageUrl} alt="user avatar" />
                  {/* <Avatar sx={{ bgcolor: "red", mr:1}}>{ username.charAt(0).toUpperCase() }</Avatar> */}
                  <div className="message-info-right">
                  
                  <Typography sx={{ color: theme.palette.text.primary}}>
                          {username.charAt(0).toUpperCase()+username.slice(1)} {new Date(timestamp?.toDate()).toString().substring(0,24)}
                  </Typography>
                  <Typography sx={{ color: theme.palette.text.primary,m:2}}>{respond}</Typography>
              </div>
              
          </Box>) : (<Box className="message" m={2} sx={{ color: theme.palette.text.primary}}>
              {/* <img src={currentUser.imageUrl} alt="user avatar" /> */}
                  <Avatar src={imageUrl} alt="user avatar" />
                  {/* <Avatar sx={{ bgcolor: "red", mr:1}}>{ username.charAt(0).toUpperCase() }</Avatar> */}
                  <div className="message-info-left">
                  
                  <Typography sx={{ color: theme.palette.text.primary}}>
                          {username.charAt(0).toUpperCase()+username.slice(1)} {new Date(timestamp?.toDate()).toString().substring(0,24)}
                  </Typography>
                  <Typography sx={{ color: theme.palette.text.primary,m:2}}>{respond}</Typography>
              </div>
              
          </Box>)
            }
            
         </ThemeProvider>
    );
};


export default Respond
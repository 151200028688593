import React from "react";

import "./input.styles.scss";
import { ThemeProvider, useTheme } from "@mui/material/styles";


const RetroInput = React.forwardRef((props, ref) => (
  <input className="retroInput" ref={ref} {...props} />
));
export default RetroInput;
